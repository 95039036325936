<template>
	
	<div id="user-layout" class="flex-1 relative bg-background font-Baloo" style="flex-grow: 3;" @click="toggleOff()">
		
		<pop-up></pop-up>

		<story v-if="storiesStatus"></story>

		<loader></loader>
		
		<sidebar></sidebar>

		<router-view></router-view>

	</div>
	
</template>
<script>

	import Loader from '../components/Loader.vue';
	import Sidebar from '../components/Sidebar.vue';
	import PopUp from '../components/MessagePopup.vue';
	import Story from '../components/Story.vue';

	export default {
		methods: {
			toggleOff: function () {
				this.$store.dispatch('setCharachterStatus', 0)
				this.$store.dispatch('setCharachter', {class:'', message: ''})
				this.$store.dispatch( 'setPopUpStatus', 0 );
			}
		},
		computed: {
			storiesStatus() {
				return this.$store.getters.getStoryStatus
			}
		},
		components: {
			Loader,
			Sidebar,
			PopUp,
			Story,
		}
	}

</script>