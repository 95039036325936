<template>
	<div class="mobile:mt-20 laptop:pl-25r my-10 px-10" style="min-height: 917px;">>
		
		<div class="flex flex-col lg:flex-row ">				

			<div class="mobile:w-full mobile:w-full laptop:w-1/3 desktop:ml-20 desktop:mr-10 laptop:ml-3 laptop:mr-3 mb-10 mobile:mb-0">
				
				<div class="relative">
					<div class="rounded-2xl bg-white pt-8 pb-5 px-8 text-white w-full mb-10 inline-block">
						<div class="relative text-center w-full block h-12">
								
							<div class="bg-gray-300 cursor h-12 hover:bg-gray-400 inline-block leading-10 rounded-full text-1xl text-gray-600 w-12 float-left" @click="getTimeSelectedDate('prev')">
								<i class="fas fa-arrow-left" style="line-height: 2.5;"></i>
							</div>							

							<div class="bg-gray-300 cursor h-12 hover:bg-gray-400 inline-block leading-10 rounded-full text-1xl text-gray-600 w-12 float-right" v-show="dateSelectedNotToday" 
							@click="getTimeSelectedDate('next')">
								<i class="fas fa-arrow-right" style="line-height: 2.5;"></i>
							</div>

						</div>
						<div class="relative mt-8 mb-5 text-center">
							<h1 class=" text-3xl font-semibold capitalize text-r-dark-blue mb-3">{{selectedDateTimes.date | dateFormat}}</h1> 							
	
							<div class="w-full mx-auto">	
								
								<div class="cursor w-16 h-16 border-4 hover:bg-green-500 rounded-full border-green-500 text-green-500 hover:text-white active:bg-green-600 inline-block mb-5 text-1xl" @click.prevent.stop="increment">
									<span class="block pt-4">
										<i class="fas fa-plus"></i>
									</span>
								</div>
								<div class="relative text-center">
									<div class="h-40 w-40 border-8 border-yellow-500 inline-block rounded-full"></div>
									<div class="centered">
										<span class="block text-5xl h-12 font-semibold capitalize text-r-dark-blue">{{selectedDateTimes.amount + entry}}</span>
										<span class="block text-2xl font-semibold capitalize text-r-dark-blue">Minutes</span>
									</div>
								</div>
								<div class="cursor w-16 h-16 border-4 hover:bg-red-500 rounded-full border-red-500 text-red-500 hover:text-white active:bg-red-600 inline-block mt-5 text-1xl" @click.prevent.stop="decrement">
									<span class="block pt-4">
										<i class="fas fa-minus"></i>
									</span>
								</div>

							</div>	
						</div>

					</div>				

					<div class="rounded-2xl bg-white p-8 mb-10 text-white">
						<div class="relative cursor">
							<div class="absolute text-center -mt-16 -ml-16 h-34 w-34 rounded-full pt-1 px-2" v-if="user.gender == 'boy'">
								<img src="/assets/svg/character/emoji-1.svg" class="image-svg h-full" v-if="totalTime > 0" alt="">
								<img src="/assets/svg/character/emoji-5.svg" class="image-svg h-full" v-if="totalTime == 0" alt="">
							</div>							
							<div class="absolute text-center -mt-16 -ml-16 h-34 w-34 rounded-full pt-1 px-2" v-if="user.gender == 'girl'">
								<img src="/assets/svg/character/girl_emoji-1.svg" class="image-svg h-full" v-if="totalTime > 0" alt="">
								<img src="/assets/svg/character/girl_emoji-5.svg" class="image-svg h-full" v-if="totalTime == 0" alt="">
							</div>
						</div>
						<div class="relative mt-20 pt-5" v-if="totalTime > 0">
							
							<h1 class="text-3xl font-semibold capitalize leading-none text-r-dark-blue mobile:text-2xl laptop:text-2xl">You learnt a whopping total of:</h1>
							<h1 class="text-5xl font-semibold capitalize text-scooter font-bold mt-1 h-12 mobile:text-3xl laptop:text-3xl">{{totalTime}} minutes!</h1>

						</div>						
						<div class="relative mt-20 pt-5" v-if="totalTime == 0">
							
							<h1 class="text-3xl font-semibold capitalize leading-none text-r-dark-blue laptop:text-2xl">{{translations.msgs.time_page_no_time}}</h1>

						</div>
					</div>					

				</div>
			</div>			

			<div class="mobile:w-full desktop:w-1/2 laptop:ml-10 laptop:mr-0 desktop:mr-20">
				<div class="relative">
					<div class="rounded-t-2xl bg-white h-full p-6 md:p-10 text-white flex justify-between pb-10">
						<div class="side-speech-bubble bg-coral relative rounded-1xl w-3/6 inline-block h-fit">
							<p class="p-4 text-center text-2xl font-medium">{{page.message}}</p>
						</div> 
						<div class="h-21r inline-block">
							<img v-if="user.gender == 'boy'" src="/assets/svg/character/char1.svg" class="image-svg h-full">
							<img v-if="user.gender == 'girl'" src="/assets/svg/character/girl_char1.svg" class="image-svg h-full">
						</div>
						</div>
					<!-- </div> -->
					</div>
					<div class="rounded-b-2xl bg-white p-6 md:p-10">
						<h1 class=" text-3xl font-bold overflow-ellipsis capitalize leading-none text-r-dark-blue laptop:text-2xl">
							How much MBPD will I earn?
						</h1>						
						<h1 class="capitalize font-semibold leading-none pb-2 text-4xl text-scooter">
							{{page.mbpd_time_amount}} MINUTES   =   {{page.mbpd}} MBPD
						</h1>						

						<h1 class=" text-3xl font-bold overflow-ellipsis capitalize leading-none text-r-dark-blue laptop:text-2xl">
							{{translations.msgs.consecutive_days}}
						</h1>						
						<h1 class="capitalize font-semibold leading-none pb-2 text-4xl text-scooter">
							{{consecutiveDays}} DAYS IN A ROW
						</h1>
					</div>
				</div>
			</div>			

		</div>		


	</div>
</template>
<script>

  	import _ from 'lodash';
	import Hebcal from 'hebcal';
	import moment from 'moment'

	export default {
		data() {
			return {
		
				icon: 'default.png',
				url: '/assets/images/avatars/',
				default_image: 'default.png',
				entry: 0,
				decrement_amount: 0,
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			logo: function () {
				return this.$store.getters.getLogos.main_logo;
			},
			
			translations() {
				return this.$store.getters.getTranslations
			},

			user() {
				return this.$store.getters.getUser
			},

			selectedDateTimes() {
				return this.$store.getters.getSelectedDateTimes
			},			

			totalTime() {
				return this.$store.getters.getTotalTimes
			},			

			consecutiveDays() {
				return this.$store.getters.getConsecutiveDays
			},

			page() {
				return this.$store.getters.getTimePage
			},

			settings() {
				return this.$store.getters.getSettings
			},

			dateSelectedNotToday() {
				if(moment(this.selectedDateTimes.date, 'YYYY, MM, DD').format("YYYY, MM, DD") !== moment().format("YYYY, MM, DD"))
				{
					return true
				}
				return false
			}

		},

		methods: {		

			emojiSmily: function () {
				return require('../../svg/character/emoji-1.svg')
			},

			route: function (route) {
				this.$router.push({name:route})
			},

			submit: _.debounce(function (e) {
		  		
		  		var data = {
		  			date: this.selectedDateTimes.date,
		  			amount: this.entry,
		  		}
		  		this.handleCommit('submitTimeEntry', data)

		    }, 300),				

		    deduct: _.debounce(function (e) {
		  		
		  		var data = {
		  			date: this.selectedDateTimes.date,
		  			amount: this.decrement_amount,
		  		}
		  		this.handleCommit('deductTimeEntry', data)

		    }, 300),			

			handleCommit: function(action, data) {
				this.$store.dispatch( action, data ).then(response => {

					this.entry = 0
					this.decrement_amount = 0
					this.$store.dispatch( 'setPopUp', 
					{
						header:response.header, 
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'wink',
					});
					this.$store.dispatch('setPopUpStatus', 1);

		        }).catch(response => {

					this.$store.dispatch( 'setPopUp', 
					{
						header:response.header, 
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'danger',

					});
					this.$store.dispatch('setPopUpStatus', 1);

		        });
			},

			seasonInactiveCheck: function () {
				if(this.settings.season.active == false)
		  		{
		  			this.$store.dispatch( 'setPopUp', 
					{
						header:this.translations.error, 
						data:{
							message:this.settings.season.status,
						},
						model: 'defualt',
						type:'message',
						emoji:'danger',

					});
					this.$store.dispatch('setPopUpStatus', 1);
					return true
		  		}
			},

		    increment: function (e) {

		  		if(this.seasonInactiveCheck()){return}

		  		if(this.selectedDateTimes.amount < 15){
		  			this.entry = 15 - this.selectedDateTimes.amount
		  			return this.submit()
		  		}

		  		this.entry += 1
		  		return this.submit()

		    },			

			decrement: function () {

		  		if(this.seasonInactiveCheck()){return}

				if(this.selectedDateTimes.amount == 0){return}
				this.decrement_amount += 1

		  		return this.deduct()
			},

			getTimeSelectedDate: function (direction) {

				switch(direction){

		            case 'prev':
						var date = moment(this.selectedDateTimes.date, 'YYYY, MM, DD').subtract(1, 'days').format('YYYY, MM, DD')
		            break;            
		            case 'next':
						var date = moment(this.selectedDateTimes.date, 'YYYY, MM, DD').add(1, 'days').format('YYYY, MM, DD')
		            break;
		          }

      			this.$store.dispatch( 'getTimePage', date);
			}

		},

		filters: {
			dateFormat: function (value) {
				var date = moment(value, 'YYYY, MM, DD').format('MMMM DD, YYYY')
				return new Hebcal.HDate(new Date(date)).toString('h');
			},
		},

		beforeMount() {
      		this.$store.dispatch( 'getAppData' );
      		this.$store.dispatch( 'getTranslations' );
      		this.$store.dispatch( 'getTimePage' );
		},

		mounted() {
			// setTimeout( () => {
			//   	this.$store.dispatch( 'setCharachterStatus', 1)
			//   	var msg = (this.translations.msgs.token_register)
			// 	this.$store.dispatch( 'setCharachter', {class:'show',message:msg})
			// },300);	
		},

	}
</script>