/*
  Adds the promise polyfill for IE 11
*/
require('es6-promise').polyfill();

/*
    Imports Vue and Vuex
*/
import Vue from 'vue'
import Vuex from 'vuex'

/*
    Initializes Vuex on Vue.
*/
Vue.use( Vuex )

/*
  Import our data modules.
*/
import { user } from './modules/user.js'
import { loading } from './modules/loading.js'
import { app } from './modules/app.js'
import { settings } from './modules/settings.js'
import { contact } from './modules/contact.js'
import { landing } from './modules/landing.js'
import { popup } from './modules/popup.js'
import { charachter } from './modules/charachter.js'
import { mesechtos } from './modules/mesechtos.js'
import { mesechta } from './modules/mesechta.js'
import { perek } from './modules/perek.js'
import { mishna } from './modules/mishna.js'
import { tanya } from './modules/tanya.js'
import { time } from './modules/time.js'
import { lines } from './modules/lines.js'
import { transactions } from './modules/transactions.js'
import { products } from './modules/products.js'
import { cart } from './modules/cart.js'
import { stories } from './modules/stories.js'
import { transfer } from './modules/transfer.js'
import { live_banner } from './modules/live_banner.js'


/*
  Exports our data store.
*/
export default new Vuex.Store({

    modules: {
    	user,
      loading,
      app,
      settings,
      contact,
      landing,
      popup,
      charachter,
      mesechtos,
      mesechta,
      perek,
      mishna,
      tanya,
      time,
      lines,
      transactions,
      products,
      cart,
      stories,
      transfer,
      live_banner,
    },
    
});