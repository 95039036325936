<template>
	<div class="h-screen flex flex-col">

		<transition name="fade">
		<div v-if="dataLoading" class="fixed w-full h-full flex justify-center items-center bg-overlay-white z-1000">
			<div>
				<img :src="'/assets/svg/data-loader.svg'" alt="Loading..">
			</div>
		</div>
		</transition>

		<character></character>

		<header class="bg-gradient-orange h-full">
			<div class="container mx-auto flex justify-center h-56 items-center pb-10 md:pb-0">
				<!-- <img class="h-16 md:h-24 lg:h-32" :src="'/assets/images/logo/'+logo" alt="logo"> -->
			</div>
		</header>
		<div class="absolute h-screen w-full flex flex-col px-5">
			<!-- <div class="w-1/2 h-full absolute"> -->
				 <!-- <div v-html="character()" class="-mt-10 image-svg h-full absolute" style="right: 0; height: calc(100% + 50px);"></div> -->
			<!-- </div> -->

			<div class="px-5 pt-5 pb-3 md:p-10 md:pb-6 rounded-xl m-auto bg-white text-center">
				<div class="inline-block md:mb-8" v-if="step == 1">
					<img @click="route('landing')" class="h-16 md:h-24 lg:h-32" :src="'/assets/images/logo/'+logo" alt="logo">
				</div>
				<div class="image-uploader-container h-24 lg:h-40 w-24 lg:w-40 inline-block" v-if="step == 'complete_register'">
	        	<input style="display: none;" ref="image" v-on:change="handleFileUpload()" type="file" data-name="avatar" accept=".jpg, .jpeg, .gif, .png, .svg">
				<figure v-on:click="fileInput" class="mt-0">
		            <img class="rounded-full image" ref="avatar-image" id="avatar-image"  
		            :src="url+icon" 
	          		@error="imageLoadError">
		        </figure>
		        </div>	

				<div class="flex flex-col justify-center items-center mt-5 font-Alef">

					<div v-if="step == 1">
					<div class="w-full mb-5">
						<input class="w-full h-12 md:h-16 text-base md:text-1xl text-r-dark-blue px-4 rounded-xl md:rounded-xl bg-white border-r-dark-orange focus:border-r-dark-blue border-2 md:border shadow-c-xl-2 outline-none" 
						:class="{'border-danger' : error && token == ''}"
						v-model="token"
						type="text" placeholder="Registration Token">
					</div>					
					<div class="w-full mb-12">
						<input class="w-full h-12 md:h-16 text-base md:text-1xl text-r-dark-blue px-4 rounded-xl md:rounded-xl bg-white border-r-dark-orange focus:border-r-dark-blue border-2 md:border shadow-c-xl-2 outline-none" 
						:class="{'border-danger' : error && family == ''}"
						v-model="family"
						v-on:keyup.enter="lookup"
						type="text" placeholder="Last Name">
					</div>
					<button class="rounded-full bg-r-dark-orange hover:bg-r-lighter-orange active:bg-r-darker-orange w-32 px-4 md:w-56 h-12 md:h-16 shadow-c-xl-3 hover:shadow-dense-2 outline-none text-white text-base md:text-xl font-bold mb-3"
					@click="lookup">Lookup Account</button>
					</div> 


					<div v-if="step == 'complete_register'">
					<div class="w-full mb-5">
						<input class="w-full h-12 md:h-16 text-base md:text-1xl text-r-dark-blue px-4 rounded-xl md:rounded-xl bg-white border-r-dark-orange focus:border-r-dark-blue border-2 md:border shadow-c-xl-2 outline-none" 
						:class="{'border-danger' : error && username == ''}"
						v-model="username"
						type="text" placeholder="Username">
					</div>					
					<div class="w-full mb-12">
						<input class="w-full h-12 md:h-16 text-base md:text-1xl text-r-dark-blue px-4 rounded-xl md:rounded-xl bg-white border-r-dark-orange focus:border-r-dark-blue border-2 md:border shadow-c-xl-2 outline-none" 
						:class="{'border-danger' : error && password == ''}"
						v-model="password"
						v-on:keyup.enter="register"
						type="password" placeholder="Password">
					</div>
					<button class="rounded-full bg-r-dark-orange hover:bg-r-lighter-orange active:bg-r-darker-orange w-32 px-4 md:w-56 h-12 md:h-16 shadow-c-xl-3 hover:shadow-dense-2 outline-none text-white text-base md:text-xl font-bold mb-3"
					@click="register">Complete Registration</button>
					</div>					

					<div v-if="step == 'congrats'">
						<div class="w-11/12 md:w-56 h-64 bg-white rounded-xl m-5 inline-block text-center p-4 relative">
							<div class="inline-block h-16 w-16 rounded-xl bg-r-blue mt-3 overflow-hidden">
								<img :src="url+user.image" @error="imageLoadError" class="h-full w-full" alt="">
							</div>
							<h2 class="font-bold text-xl text-r-dark-blue capitalize mt-4 text-center text-ellipsis w-full">{{user.name}} {{user.family}}</h2>
							<h3 class="font-bold text-base text-gray-600 capitalize mt-1">{{user.username}}</h3>
							
							<button class="rounded-full bg-r-dark-orange hover:bg-r-lighter-orange active:bg-r-darker-orange w-32 px-4 h-12  shadow-c-xl-3 hover:shadow-dense-2 outline-none text-white text-base md:text-xl font-bold mt-8"
							@click="route('landing')">Home</button>		

						</div>	
					</div> 

				</div>
			</div>
		</div>		

	</div>
</template>
<script>

	import character from '../components/Character.vue'

	export default {
		data() {
			return {
				token: '',
				family: '',
				username: '',
				password: '',
				error: false,
				step: 1,
				icon: 'default.png',
				url: '/assets/images/avatars/',
				default_image: 'default.png',
				user: {},
				gender: null
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			logo: function () {
				return this.$store.getters.getLogos.main_logo;
			},
			
			dataLoading() {
				return this.$store.getters.getLoadingStatus;
			},

			translations() {
				return this.$store.getters.getTranslations
			},

			userLoginStatus() {
				return this.$store.getters.getTokenLoadingStatus;
			},
		},

		methods: {

			//check if image is broken
	  		imageLoadError: function () {
	  			event.target.src = this.url+this.default_image;

	  		},	

	  		//handle image 
	  		handleFileUpload(){
	  			this.url = ""
	  		    this.file = this.$refs.image.files[0];	
	  		    this.icon = URL.createObjectURL(this.file); 
	  		},

	  		fileInput: function () {
	  			this.$refs.image.click()
	  		},

			character: function () {
				return require('../../svg/character/char2.svg')
				// return require('../../svg/character/char1.svg')
			},
			lookup: function () {
				if(this.token == "" || !this.token || this.family == "" || !this.family){
					this.$store.dispatch( 'setCharachterStatus', 1)
					this.$store.dispatch( 'setCharachter', {class:'show',message:this.translations.msgs.missing_info})
					return
				}
				this.$store.dispatch( 'registrationLookup' , {family:this.family,token:this.token}).then(response => {
					this.gender = response.gender
					this.$store.dispatch( 'setCharachterStatus', 1)
					this.$store.dispatch( 'setCharachter', {class:'show',message:response.message,gender:response.gender})
					this.step = 'complete_register'
				}).catch(response => {
					this.$store.dispatch( 'setCharachterStatus', 1)
					this.$store.dispatch( 'setCharachter', {class:'show',message:response.message})
					this.step = 1
				})
			},
			login: function () {
				if(!this.username || !this.password){return this.error = true}
				this.$store.dispatch( 'userLogin' , {username:this.username,password:this.password});
			},

			register: function () {
				if(this.username == "" || !this.username || this.password == "" || !this.password){
					this.$store.dispatch( 'setCharachterStatus', 1)
					this.$store.dispatch( 'setCharachter', {class:'show',message:this.translations.msgs.missing_info,gender:this.gender})
					return
				}
				this.$store.dispatch( 'completeRegistration' , 
				{
					username: this.username,
					password: this.password,
					family: this.family,
					token: this.token,
					file: this.file
				})
				.then(response => {
					this.url = '/assets/images/avatars/'
					this.user = response.user
					this.$store.dispatch( 'setCharachterStatus', 1)
					this.$store.dispatch( 'setCharachter', {class:'show',message:response.message,gender:this.gender})
					this.step = 'congrats'
				}).catch(response => {
					this.$store.dispatch( 'setCharachterStatus', 1)
					this.$store.dispatch( 'setCharachter', {class:'show',message:response.message,gender:this.gender})
					this.step = 'complete_register'
				})
			},

			route: function (route) {
				this.$router.push({name:route})
			},

		},

		components: {
			character,
		},	

		beforeMount() {
      		this.$store.dispatch( 'getAppData' );
      		this.$store.dispatch( 'getTranslations' );
		},

		mounted() {
			setTimeout( () => {
			  	this.$store.dispatch( 'setCharachterStatus', 1)
			  	var msg = (this.translations.msgs.token_register)
				this.$store.dispatch( 'setCharachter', {class:'show',message:msg})
			},300);	
		},

		watch: {

		    userLoginStatus: {
		      // immediate: true,
		        deep: true,
		        handler(newValue, oldValue) {
		          if(newValue === 2){
		          	this.$router.push({name: 'home'})
		          }
		        }
		    },
		     
		},
	}
</script>