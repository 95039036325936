import TransactionsAPI from '../api/transactions.js';

export const transactions = {

    state: {

       transactions: {
       	meta:{
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
            }
        },
       transactions_data: {
       	spent: 0,
       	earned: 0,
       },
       transactions_loading: 0,

    },

    getters: {

    	getTransactions(state) {
            return state.transactions;
        },

        getTransactionsData(state) {
            return state.transactions_data;
        },

        getTransactionsLoadingStatus(state) {
            return state.transactions_loading;
        },

    },

    actions: {

        //get data for transaction page
        getTransactions( { commit }, data){

            commit( 'setTransactionsLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                TransactionsAPI.getTransactions(data)
                .then( function( response ){
                    commit( 'setTransactions', response.data );
                    commit( 'setTransactionsData', response.data.balance_data );
                    commit( 'setTransactionsLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setTransactionsLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },


    },

    mutations: {

	    setTransactions( state, transactions ){
            state.transactions = transactions;
	    },

	    setTransactionsData( state, data ){
            state.transactions_data = data;
	    },

	    setTransactionsLoadStatus( state, status ){
      		state.transactions_loading = status;
	    },


    },
}
