<template>
	<!-- header -->
	<div class="h-20 w-full flex justify-between items-center">
		<div class="rounded-full bg-flush-orange h-12 items-center px-4 capitalize text-1xl flex cursor-pointer" @click.stop="back">back</div>
		<div class="rounded-full bg-flush-orange h-12 items-center px-4 capitalize text-1xl flex cursor-pointer" @click.stop="routeToCart">
			<i class="fas fa-shopping-cart"></i> <span class="ml-3 pt-1">{{cart}}</span>
		</div>
	</div>
	<!-- end header -->
</template>
<script>
	export default {
		computed: {
			cart: function () {
				if(this.$store.getters.getCart.length > 0){
					return this.$store.getters.getCart.length
				}
				return 0
			}
		},

		methods: {
			back: function () {
				setTimeout( () => {
				  	window.scrollTo(0,0);
				},300);
				this.$router.go(-1)
			},	

			routeToCart: function () {
				if(this.$route.name == 'cart') {return}
				this.$router.push({name:'cart'})
			}
		}
	}
</script>