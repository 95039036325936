/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	   

    //get list of categorized and sorted products
    getProducts: function(data){
        return axios.get(APP_CONFIG.API_URL+`/products?page=${data.Page}&&${data.Query}`); 
    },       

    //get list of store categories
    getStoreCategories: function(){
        return axios.get(APP_CONFIG.API_URL+`/store-categories`); 
    },     

    //get list of categorized and sorted trending products
    getTrending: function(data){
        return axios.get(APP_CONFIG.API_URL+`/trending?${data}`); 
    },        

    //get product
    getProduct: function(data){
        return axios.get(APP_CONFIG.API_URL+`/product/${data}`); 
    },      

}