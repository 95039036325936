<template>
    <ul class="pagination flex justify-center">
    <li 
    :class="{
      'border-flush-orange' : color == 'orange',
      'border-scooter' : color == 'blue'
      }"
    class="pagination-prev page-item" v-if="pagination.current_page > 1">
        <a class="page-link" 
        :class="{'text-flush-orange' : color == 'orange', 'text-scooter' : color == 'blue'}" 
        href="javascript:void(0)" aria-label="Previous" v-on:click.prevent="changePage(pagination.current_page - 1)">
            <span aria-hidden="true">Prev</span>
            </a>
        </li>
    <li v-for="page in pagesNumber" class="page-item" :class="{
      'active': page == pagination.current_page, 
      'bg-scooter': page == pagination.current_page && color == 'blue', 
      'bg-flush-orange': page == pagination.current_page && color == 'orange', 
      'border-flush-orange hover:border-flush-orange hover:color-flush-orange' : color == 'orange',
      'border-scooter hover:border-scooter hover:color-scooter' : color == 'blue'
      }">
        <a class="page-link" 
        :class="{'text-flush-orange' : color == 'orange', 'text-scooter' : color == 'blue'}" 
        href="javascript:void(0)" v-on:click.prevent="changePage(page)">{{ page }}</a>
        </li>
    <li 
      :class="{
      'border-flush-orange' : color == 'orange',
      'border-scooter' : color == 'blue'
      }"
    class="pagination-next page-item" v-if="pagination.current_page < pagination.last_page">
        <a class="page-link" 
        :class="{'text-flush-orange' : color == 'orange', 'text-scooter' : color == 'blue'}" 
        href="javascript:void(0)" aria-label="Next" v-on:click.prevent="changePage(pagination.current_page + 1)">
            <span aria-hidden="true">Next</span>
            </a>
        </li>
    </ul>
</template>
<script>
  export default{
      props: {
      pagination: {
          type: Object,
          required: true
      },
      offset: {
          type: Number,
          default: 4
      },      
      color: {
          type: String,
          default: 'blue'
      }
    },
    computed: {
      pagesNumber() {
        if (!this.pagination.to) {
          return [];
        }
        let from = this.pagination.current_page - this.offset;
        if (from < 1) {
          from = 1;
        }
        let to = from + (this.offset * 2);
        if (to >= this.pagination.last_page) {
          to = this.pagination.last_page;
        }
        let pagesArray = [];
        for (let page = from; page <= to; page++) {
          pagesArray.push(page);
        }
          return pagesArray;
      },
      class() {
          switch(this.color){
            case 'blue':
              return 'blue'
            break;            
            case 'orange':
              return 'orange'
            break;
          }
      }
    },
    methods : {
      changePage(page) {
        this.pagination.current_page = page;
        this.$emit('paginate');
      }
    }
  }
</script>
