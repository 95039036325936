<template>
	<div>
		<div class="keypad-container">
					
			<button @click="printVal('1')" class="btn-keypad">1</button>
			<button @click="printVal('2')" class="btn-keypad">2</button>
			<button @click="printVal('3')" class="btn-keypad">3</button>
			<button @click="printVal('4')" class="btn-keypad">4</button>
			<button @click="printVal('5')" class="btn-keypad">5</button>
			<button @click="printVal('6')" class="btn-keypad">6</button>
			<button @click="printVal('7')" class="btn-keypad">7</button>
			<button @click="printVal('8')" class="btn-keypad">8</button>
			<button @click="printVal('9')" class="btn-keypad">9</button>
			<button @click="printVal('.')" class="btn-keypad">.</button>
			<button @click="printVal('0')" id="btn-zero" class="btn-keypad">0</button>
			<button @click="printVal('del')" id="btn-del" class="btn-keypad">
			<
			<!-- <img :src="'assets/images/svg/backspace.svg'"> -->
			</button>

		</div>
	</div>
</template>
<script>
	export default {
		data () {
			return {
				amount: 0,
			}
		},
		methods: {
			printVal: function (val){

				

	  			if(this.amount != '0'){

		  			if(val == 'del'){

		  				var amount = this.amount
		  				var length = amount.replace(/\s+/g, '').length

		  				if (length > 1) {
		  					this.amount = amount.slice(0,-1);
		  					this.$emit('keyed', this.amount)
		  					return
		  				}
		  				this.amount = 0;
	  					this.$emit('keyed', this.amount)
		  				return
		  			}

		  			
	  				this.amount = this.amount + val
  					this.$emit('keyed', this.amount)
  					return 

	  			}

	  			if(val == 'del'){return}
	  			this.amount = val
				this.$emit('keyed', this.amount)

	  		},
		}
	}
</script>
<style lang="scss">


	.keypad-container{
	    height: 375px;
		width: 350px;
		display: inline-grid;
  		grid-template: 1fr 1fr 1fr 1fr/ 1fr 1fr 1fr;
  		grid-gap: 0 30px;;
		max-height: 640px;
		max-width: 480px;
		// padding: 17px;
		justify-items: center;

	     @media only screen and (max-width: 550px){
	        // width: 250px !important;
	        padding: 0;
	        grid-gap: 0;
	        justify-items: center;
	        width: 290px !important;
            max-height: 288px;
            max-width: 100%;
	    }    	     


	    @media only screen  and (min-width: 520px) and (max-width:1660px){
            max-width: 257px;
            grid-gap: 0 10px;
	    }    

		.btn-keypad{
			border-radius: 100%;
			font-size: 50px;
			color: #3b486b;
		    font-family: "Montserrat", sans-serif;
	        background: none;
		    outline: none;
		    border: none;
	        border-bottom: 3px solid #eae1e1;
	        height: 75px;
    		width: 75px;
		    font-size: 43px;

			    &:hover{
		    	    background: rgba(0,0,0,0.04);
			    }

			    &:active{
		    	    background-color: #e0e0e0;
			    }

			    @media only screen and (max-width: 550px){
				    height: 75px;
		    		width: 75px;
	    		    font-size: 43px;
    		        height: 58px;
				    width: 58px;
				    font-size: 27px;
			    }
		}



		#btn-zero{
		    grid-column: 2;
		}

		#btn-del{
			img{
				width: 35px;
			}
		}
	}

</style>