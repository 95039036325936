<template>
	
	<div id="user-layout" class="flex-1 relative bg-background font-Baloo" style="flex-grow: 3;" @click="toggleOff()">
		
		<pop-up></pop-up>

		<loader></loader>
		
		<router-view></router-view>

	</div>
	
</template>
<script>

	import Loader from '../components/Loader.vue';
	import PopUp from '../components/MessagePopup.vue';
	import character from '../components/Character.vue'

	export default {

		methods: {
			toggleOff: function () {
				this.$store.dispatch('setCharachterStatus', 0)
				this.$store.dispatch('setCharachter', {class:'', message: ''})
			}
		},
		components: {
			Loader,
			PopUp,
		},	

	}

</script>