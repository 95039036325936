<template>
	<div id="basic-layout" @click="toggleOff()">
		<router-view></router-view>
	</div>
</template>
<script>
	export default {
		methods: {
			toggleOff: function () {
				this.$store.dispatch('setCharachterStatus', 0)
				this.$store.dispatch('setCharachter', {class:'', message: ''})
			}
		}
	}
</script>
