import ProductsAPI from '../api/products.js';

export const products = {

    state: {

        products: {
       	meta:{
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
            }
        },
        categories: {},
        trending: {},
        products_loading: 0,
        product:{},
        product_loading:0,
    },

    getters: {

    	getProducts(state) {
            return state.products;
        },

        getStoreCategories(state) {
            return state.categories;
        },

        getTrending(state) {
            return state.trending;
        },

        getProductsLoadingStatus(state) {
            return state.products_loading;
        },

        getProduct(state) {
            return state.product;
        },

        getProductLoadingStatus(state) {
            return state.product_loading;
        },

    },

    actions: {

        //get store products paginated and sorted
        getProducts( { commit }, data){

            commit( 'setProductsLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                ProductsAPI.getProducts(data)
                .then( function( response ){
                    commit( 'setProducts', response.data );
                    commit( 'setProductsLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setProductsLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },

        //get store categories
        getStoreCategories( { commit }){

            commit( 'setProductsLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                ProductsAPI.getStoreCategories()
                .then( function( response ){
                    commit( 'setCategories', response.data );
                    commit( 'setProductsLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setProductsLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },

        //get store products paginated and sorted
        getTrending( { commit }, data){

            commit( 'setProductsLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                ProductsAPI.getTrending(data)
                .then( function( response ){
                    commit( 'setTrending', response.data );
                    commit( 'setProductsLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setProductsLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },

        //get selected product
        getProduct( { commit }, data){

            commit( 'setProductLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                ProductsAPI.getProduct(data)
                .then( function( response ){
                    commit( 'setProduct', response.data.data );
                    commit( 'setProductLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setProductLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },

        resetProducts({commit}){
            commit( 'setProductLoadStatus', 1 );
            commit('resetProducts')
            commit( 'setProductLoadStatus', 2 );
        }


    },

    mutations: {

	    setProducts( state, products ){
            state.products = products;
	    },

        setCategories( state, categories ){
            state.categories = categories;
        },

        setTrending( state, trending ){
            state.trending = trending;
        },

	    setProductsLoadStatus( state, status ){
      		state.products_loading = status;
	    },

	    setProduct( state, product ){
            state.product = product;
	    },

	    setProductLoadStatus( state, status ){
      		state.product_loading = status;
	    },

        resetProducts(state) {
            state.products = {meta:{total: 0,per_page: 10,from: 1,to: 0}}
            state.trending = {}
        }


    },
}
