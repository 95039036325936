import TimesAPI from '../api/times.js';

export const time = {

    state: {

       time: {},
       selected_date_time: {date:'',amount:0},
       total_time: 0,
       consecutive_days: 0,
       time_loading: 0,
       page: {message:'',mbpd_time_amount:'',mbpd:''}

    },

    getters: {

    	getTimes(state) {
            return state.time;
        },

        getTimePage(state) {
            return state.page;
        },

        getSelectedDateTimes(state) {
            return state.selected_date_time;
        },

        getTotalTimes(state) {
            return state.total_time;
        },

        getConsecutiveDays(state) {
            return state.consecutive_days;
        },

        getTimesLoadingStatus(state) {
            return state.time_loading;
        },

    },

    actions: {

        //get data for time page
        getTimePage( { commit }, data){

            commit( 'setTimesLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                TimesAPI.getTimePage(data)
                .then( function( response ){
                    commit( 'setTotalTime', response.data.total_time );
                    commit( 'updateTimeTotal', response.data.total_time );
                    commit( 'setSelectedDateTime', response.data.selected_date_time );
                    commit( 'setConsecutiveDats', response.data.consecutive_days );
                    commit( 'setTimePage', response.data.page );
                    commit( 'setTimesLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setTimesLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },

        //submit time entry
        submitTimeEntry( { commit }, data){

            commit( 'setTimesLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                TimesAPI.submitTimeEntry(data)
                .then( function( response ){
                    commit( 'setTotalTime', response.data.total_time );
                    commit( 'updateTimeTotal', response.data.total_time );
                    commit( 'setSelectedDateTime', response.data.selected_date_time );
                    commit( 'setConsecutiveDats', response.data.consecutive_days );
                    commit( 'setTimesLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setTimesLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },

        //deduct time entry
        deductTimeEntry( { commit }, data){

            commit( 'setTimesLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                TimesAPI.deductTimeEntry(data)
                .then( function( response ){
                    commit( 'setTotalTime', response.data.total_time );
                    commit( 'updateTimeTotal', response.data.total_time );
                    commit( 'setSelectedDateTime', response.data.selected_date_time );
                    commit( 'setConsecutiveDats', response.data.consecutive_days );
                    commit( 'setTimesLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setTimesLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },


    },

    mutations: {

	    setTimes( state, time ){
            state.time = time;
	    },

        setTimePage( state, page ){
            state.page = page;
        },

        setTotalTime( state, time ){
            state.total_time = time;
        },

        setConsecutiveDats( state, days ){
            state.consecutive_days = days;
        },

        setSelectedDateTime( state, time ){
            state.selected_date_time = time;
        },

	    setTimesLoadStatus( state, status ){
      		state.time_loading = status;
	    },


    },
}
