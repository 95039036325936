export const loading = {

    state: {
    	loading_status: 0,
        loading_page_state: 0,
    },

    getters: {

    	getLoadingStatus(state, getters) {
            if(
                getters.getUserLoadingStatus == 1 ||
                getters.getTokenLoadingStatus == 1 ||
                getters.getLogoutLoadingStatus == 1 ||
                getters.getTopicsLoadingStatus == 1 ||
                getters.getCategoriesLoadingStatus == 1 ||
                getters.getCategoryLoadingStatus == 1 ||
                getters.getStoreLoadingStatus == 1 ||
                getters.getCartLoadingStatus == 1 ||
                getters.getSettingsLoadingStatus == 1 ||
                getters.getMesechtosLoadingStatus == 1 ||
                getters.getMesechtaLoadingStatus == 1 ||
                getters.getMishnaLoadingStatus == 1 ||
                getters.getPerekLoadingStatus == 1 ||
                getters.getTanyaLoadingStatus == 1 ||
                getters.getLinesLoadingStatus == 1 ||
                getters.getTimesLoadingStatus == 1 ||
                getters.getTransactionsLoadingStatus == 1 ||
                getters.getProductsLoadingStatus == 1 ||
                getters.getProductLoadingStatus == 1 ||
                getters.getCartLoadingStatus == 1 ||
                getters.getTransferLoadingStatus == 1 ||
                getters.getContactLoadingStatus == 1 ||
                getters.getMissionLoadingStatus == 1 

            )
            {
                return 1
            }

        },    	

        getPageLoadingStatus(state) {
            return state.loading_page_state;
        },        

    },
    
    actions: {           

        setLoadingStatus( { commit }, data){

            commit( 'setLoadingStatus', data );

        },          

        setPageLoadingStatus( { commit }, data){

            commit( 'setPageLoadingStatus', data );

        },        


    },

    mutations: {

	    setLoadingStatus( state, status ){
      		state.loading_status = status;
	    },

	    setPageLoadingStatus( state, status ){
      		state.loading_page_state = status;
	    },     

    },
}