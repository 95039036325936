import UserAPI from '../api/user.js';
import router from '../routes.js'

export const user = {

    state: {
    	// user: {name: 'user', family: 'name', status: 'status', points_needed_to_next_status: 0, points: 0, points_spent: 0,},
        user: JSON.parse(localStorage.getItem('user')) || {name: 'user', family: 'name', lines: 0, time: 0, mbpd: 0, orders: 0},
    	user_shipping_address: {address:null,city:null,state:null,zip:null},
        user_loading: 0,
        access_token: localStorage.getItem('access_token') || null,
        access_token_loading: 0,
        logout_load_status: 0,
    },

    getters: {

    	getUser(state) {
            return state.user;
        },

        getUserLoadingStatus(state) {
            return state.user_loading;
        },

        getUserShippingAddress(state) {
            return state.user_shipping_address
        },

        getLogoutLoadingStatus(state) {
            return state.logout_load_status;
        },

        getToken(state) {
            return state.access_token
        },

        getTokenLoadingStatus(state) {
            return state.access_token_loading
        },

        getLoggedIn(state) {

            if(!state.access_token || state.access_token == 'null' || state.access_token == 'undefined'){
                return false
            }
            return true
        },

    },
    actions: {

        //lookup user with token
        registrationLookup({commit, dispatch}, data) {

            commit( 'setUserLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                UserAPI.registrationLookup( data )
                .then( function( response ){
                    commit( 'setUserLoadStatus', 2 );
                    resolve(response.data)

                })
                .catch( function(error){
                    commit( 'setUserLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },

        //complete user registration
        completeRegistration({commit, dispatch}, data) {

            commit( 'setUserLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                UserAPI.completeRegistration( data )
                .then( function( response ){
                    commit( 'setUserLoadStatus', 2 );
                    resolve(response.data)

                })
                .catch( function(error){
                    commit( 'setUserLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },

        //user login action - retreive token
        userLogin({commit, dispatch}, data) {
             commit( 'setTokenLoadStatus', 1 );

             return new Promise((resolve, reject) => {

                 UserAPI.userLogin( data )
                 .then( function( response ){
                     commit( 'setToken',  response.data.access_token );
                     dispatch( 'getUser' );
                     commit( 'setTokenLoadStatus', 2 );
                     resolve(response.data)

                 })
                 .catch( function(error){
                     commit( 'setToken',  null );
                     commit( 'setTokenLoadStatus', 3 );
                     reject(error.response.data)
                 });

             })


        },

        //user logout - remove token
        userLogout({commit}) {

            commit( 'setLogoutLoadStatus', 1 );

             UserAPI.userLogout()
             .then( function( response ){
                 commit( 'logout' );
                 router.push({ name: 'login' });
                 commit( 'setLogoutLoadStatus', 2 );
                 // commit( 'setToken',  response.data.access_token );
             })
             .catch( function(){
                 // commit( 'setTokenLoadStatus', 3 );
                 commit( 'logout' );
                 router.push({ name: 'login' });
                 commit( 'setLogoutLoadStatus', 3 );
             });
        },
        async frontAndBackendLogout({commit}) {
           await UserAPI.userLogout();
           commit('logout')
        },

        //logout - remove token
        localLogout({commit}) {
             commit( 'logout' );
             router.push({ name: 'login' });
        },

        //get user data
		getUser( { commit } ){

            commit( 'setUserLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                UserAPI.getUserData()
                .then( function( response ){
                    commit( 'setUser', response.data.data );
                    commit( 'setUserLoadStatus', 2 );
                    resolve(response.data)

                })
                .catch( function(error){
                    commit( 'setUserLoadStatus', 3 );
                    reject(error.response)
                });

            })
		},

        //get shipping address
        getShippingAddress( { commit, dispatch} ){

            commit( 'setUserLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                UserAPI.getShippingAddress()
                .then( function( response ){
                    commit( 'setUserShippingAddress', response.data.data );
                    commit( 'setUserLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setUserLoadStatus', 3 );
                    reject(error.response.data);
                });

            })

        },

        //set mbpd tag
        setMbpdTag( { commit }, data){

            commit( 'setUserLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                UserAPI.setMbpdTag(data)
                .then( function( response ){
                    commit( 'setUser', response.data.user );
                    commit( 'setUserLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setUserLoadStatus', 3 );
                    reject(error.response.data)
                });

            })
        },


    },

    mutations: {

	    setUserLoadStatus( state, status ){
      		state.user_loading = status;
	    },

	    setUser( state, user ){
            localStorage.setItem('user', JSON.stringify(user));
      		state.user = user;
	    },

        setToken( state, token) {
            state.access_token = token;
            localStorage.setItem('access_token', token)
        },

        setTokenLoadStatus( state, status) {
            state.access_token_loading = status;
        },

        setLogoutLoadStatus( state, status ){
            state.logout_load_status = status;
        },

        logout( state, ) {
            state.access_token = null;
            state.cart = [];
            state.user = {name: 'user', family: 'name', points: 0, points_spent: 0,};
            localStorage.removeItem('access_token')
            localStorage.removeItem('cart')
            localStorage.removeItem('user')
            localStorage.removeItem('products')
        },

        updateLinesTotal(state, data) {
            state.user.lines = data
        },

        updateTimeTotal(state, data) {
            state.user.time = data
        },

        setUserShippingAddress(state, data) {
            state.user_shipping_address = data
        }

    },
}
