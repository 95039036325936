<style scoped="true">
	.model-pop-up-container{
	    position: fixed;
	    top: -500px;
	    background: white;
	    z-index: 1500;
	    left: 50%;
	    -webkit-transform: translateX(-50%);
	    transform: translateX(-50%);
	    opacity: 0;
	    pointer-events: none;
	    -webkit-transition: all .3s ease-in-out;
	    transition: all .3s ease-in-out;
    }	
    .model-pop-up-container.model-pop-up-card-visible{
		-webkit-transform: translate(-50%, 550px);
	    transform: translate(-50%, 550px);
	    opacity: 1;
	    pointer-events: all;
    }
</style>
<template>

<div>

<div class="model-pop-up" v-bind:class="{'overflow-visible' : popupStatus}" @click="toggleOff()"></div>    

	<div class="model-pop-up-container relative rounded-xl mx-auto w-10/12 md:w-auto rounded-2xl" @click.stop v-bind:class="{'model-pop-up-card-visible' : popupStatus}">
		
		<!-- <popup-message-card></popup-message-card> -->
		<!-- message card -->
		<div class="model-pop-up-card rounded-2xl" v-on:click.stop v-if="popup.type == 'message'">

		  <div class="card card-small px-5 py-2 rounded-2xl">

		    <div class="card-header pb-0 relative">
		    	<div class="absolute">
					<img :src="'/assets/svg/character/'+emoji" class="h-40 w-40 -mt-20 -ml-16" :class="{'flip-horizontal' : user.gender == 'girl'}" alt="">
		    	</div>
				<h1 class="font-bold text-xl text-r-dark-blue mb-4 ml-10 uppercase">{{popup.header}}</h1>  	
		  	</div>

			<div>
				<div class="my-2 mx-auto px-5 md:px-2 max-w-35">
					<h6 class="mb-0 text-r-dark-blue font-medium text-1xl md:text-1xl text-center">{{popup.data.message}}</h6>
				</div>
			</div>

		  	<div class="card-footer flex justify-end pt-0">    	          	
		    	<div class="text-right">   		
		    		<button class="rounded-xl bg-r-blue hover:bg-r-lighter-blue active:bg-r-darker-blue w-32 md:w-40 h-12 hover:shadow-dense-blue-2 outline-none text-white text-lg md:text-l font-bold cursor"
		    		@click.prevent="ok">OK</button>        		
		    	</div>      	
		    </div>

		  </div>  

		</div>
		<!-- end message card -->	

		<!-- prompt action card -->
		<div class="model-pop-up-card" v-on:click.stop v-if="popup.type == 'action'">

		  <div class="card card-small px-5 py-2">

		    <div class="card-header pb-0 relative">
		    	<div class="absolute">
					<img :src="'/assets/svg/character/'+emoji" class="h-40 w-40 -mt-20 -ml-16" alt="">
		    	</div>
				<h1 class="font-bold text-xl text-r-dark-blue mb-4 ml-10 uppercase">{{popup.header}}</h1>  	
		  	</div>

			<div>
				<div class="my-2 mx-auto px-5 min-w-20">
					<h6 class="mb-0 font-bold text-r-dark-blue text-xl md:text-1xl text-center">{{popup.data.message}}</h6>
				</div>

				<!-- parents pin input -->
				<div v-if="popup.model == 'parents_pin'" class="my-2 mt-3 mx-auto px-5">
					<input class="relative dropdown-link cursor w-full h-16 text-base md:text-1xl text-center text-r-dark-blue rounded-xl md:rounded-xl bg-gray-200 mb-5 w-full block outline-none px-3 font-medium" :class="{'border-danger border-2' : popup.error && popup.data.pin == ''}" type="password" placeholder="Parents Pin" v-model="popup.data.pin"> 
				</div>
				<!-- end parents pin input -->					

				<!-- mbpd tag input -->
				<div v-if="popup.model == 'mbpd_tag'" class="my-2 mt-3 mx-auto px-5">
					<input class="relative dropdown-link cursor w-full h-16 text-base md:text-1xl text-center text-r-dark-blue rounded-xl md:rounded-xl bg-gray-200 mb-5 w-full block outline-none px-3 font-medium" :class="{'border-danger border-2' : popup.error && popup.data.mbpd_tag == ''}" type="text" placeholder="MBPD Tag" v-model="popup.data.mbpd_tag"> 
				</div>
				<!-- end mbpd tag input -->				

				<!-- chazara prompt pin input -->
				<div v-if="popup.model == 'prompt_chazara'" class="my-2 mb-5 mt-5 flex justify-between">  			    	
			    	<div class="mr-4 w-1/2">   		
			    		<button class="pt-1 rounded-xl w-full h-12 outline-none bg-white border-danger border-2 hover:border-red-700 active:border-red-400 hover:dense-red-2 text-danger hover:text-red-700 active:text-red-400 text-lg md:text-l font-medium cursor"
			    		@click.prevent="submit({chazara:true})">Yes</button>        		
			    	</div>  
			    	<div class="w-1/2">   		
			    		<button class="pt-1 rounded-xl w-full h-12 outline-none bg-r-blue hover:bg-r-lighter-blue active:bg-r-darker-blue hover:shadow-dense-blue-2 text-white text-white text-lg md:text-l font-semibold cursor"
			    		@click.prevent="submit({chazara:false})">No</button>        		
			    	</div>
				</div>
				<!-- end chazara prompt pin input -->				

				<!-- item added to cart -->
				<div v-if="popup.model == 'added_to_cart'" class="my-2 mb-5 mt-5 flex justify-between">  			    	
			    	{{popup}}
				</div>
				<!-- end item added to cart -->

			</div>

		  	<div class="card-footer flex justify-end pt-0" v-if="!popup.without_buttons">    	          	
		    	<div class="text-right" >   		
		    		<button class="rounded-xl bg-r-blue hover:bg-r-lighter-blue active:bg-r-darker-blue w-32 md:w-40 h-12 hover:shadow-dense-blue-2 outline-none text-white text-lg md:text-l font-bold cursor"
		    		@click.prevent="confirm(popup.type, popup.data, popup.model)">
		    		<span v-if="!popup.button_text">OK</span>
		    		<span v-if="popup.button_text">{{popup.button_text}}</span>
		    		</button>        		
		    	</div>      	
		    </div>

		  </div>  

		</div>
		<!-- end prompt action card -->	

	</div>  

</div>  

</template>
<script>
	
	import PopupMessageCard from './PopupMessageCard.vue'

	export default {

		computed: {
			popupStatus() {
				return this.$store.getters.getPopUpStatus;
			},			

			popup() {
				return this.$store.getters.getPopUp;
			},				

	      	translations() {
	          return this.$store.getters.getTranslations
	        }, 

	        user() {
	        	return this.$store.getters.getUser
	        }, 

	        uploadProgress() {
	          return this.$store.getters.getProgress
	        },

	        emoji() {
    	        switch(this.popup.emoji){
		          case 'prompt':
		          if(this.user.gender == 'boy')
		          {
		            return 'emoji-2.svg'
		          }
		          if(this.user.gender == 'girl')
		          {
		          	return 'girl_emoji-2.svg'
		          }
		          break;   		          
		          case 'danger':
		          if(this.user.gender == 'boy')
		          {
		            return 'emoji-3.svg'
		          }
		          if(this.user.gender == 'girl')
		          {
		          	return 'girl_emoji-3.svg'
		          }
		          break; 		          
		          case 'caution':
		          if(this.user.gender == 'boy')
		          {
		            return 'emoji-6.svg'
		          }
		          if(this.user.gender == 'girl')
		          {
		          	return 'girl_emoji-6.svg'
		          }
		          break;  		          
		          case 'wonder':
		          if(this.user.gender == 'boy')
		          {
		            return 'emoji-5.svg'
		          }
		          if(this.user.gender == 'girl')
		          {
		          	return 'girl_emoji-5.svg'
		          }
		          break;  		          
		          case 'wink':
		          if(this.user.gender == 'boy')
		          {
		            return 'emoji-1.svg'
		          }
		          if(this.user.gender == 'girl')
		          {
		          	return 'girl_emoji-1.svg'
		          }
		          break;            
		        }
	        	return 'emoji-2.svg'
	        }
		},


		methods: {
			confirm: function (type, data, model) {
				this.$root.$emit('confirm', {type:type,data:data,model:model})
			},

			submit: function (val) {
				this.$root.$emit('submit', val)
			},			

			ok: function () {
	        	this.$store.dispatch( 'setPopUpStatus', 0 );
			},

			update: function (e) {
				this.$root.$emit('update', e)
			},
			toggleOff: function () {
	        	this.$store.dispatch( 'setPopUpStatus', 0 );
			}

		},

		components: {
			PopupMessageCard
		},

		watch: {

		    uploadProgress: {
		      immediate: true,
		        deep: true,
		        handler(newValue, oldValue) {
		          if(newValue === 100){
		          	this.cancel()
		          }
		        }
		    },
		     
		},
	}
</script>