import TransferAPI from '../api/transfers.js';

export const transfer = {

    state: {

       transfers: {
            meta:{
                total: 0,
                per_page: 10,
                from: 1,
                to: 0,
            }
       },
       transfers_data: {
           sent: 0,
           received: 0,
       },
       transfer_loading: 0,

    },

    getters: {

    	getTransfers(state) {
            return state.transfers;
        },


        getTransfersData(state) {
            return state.transfers_data;
        },


        getTransferLoadingStatus(state) {
            return state.transfer_loading;
        },

    },

    actions: {

        //get data for transfers page
        getTransfers( { commit }, data){

            commit( 'setTransferLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                TransferAPI.getTransfers(data)
                .then( function( response ){
                    commit( 'setTransfers', response.data );
                    commit( 'setTransfersData', response.data.balance_data );
                    commit( 'setTransferLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setTransferLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },



        //send transfer
        transfer( { commit }, data){

            commit( 'setTransferLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                TransferAPI.transfer(data)
                .then( function( response ){
                    commit( 'setTransferLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setTransferLoadStatus', 3 );
                    reject(error.response.data)
                });

            })
        },




    },

    mutations: {

	    setTransfers( state, transfers ){
            state.transfers = transfers;
	    },

        setTransfersData( state, data ){
            state.transfers_data = data;
        },

	    setTransferLoadStatus( state, status ){
      		state.transfer_loading = status;
	    },



    },
}
