<style>
.form-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 2.2rem;
    width: 2.2rem;
    color: #7ADBE8;
    background-color: #fff;
    border-color: #7ADBE8;
    border-width: 3px;
}
.form-checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
tbody > tr > td {
	vertical-align: middle;
}
</style>
<template>
	<div class="mobile:mt-20 laptop:pl-25r my-10 px-10" @click="dropdownOff()" style="min-height: 917px;">>
		
		<div class="flex flex-col lg:flex-row ">				

			<div class="laptop:w-1/3 desktop:ml-12 desktop:mr-10 laptop:ml-3 laptop:mr-3">
				
				<div class="relative">

					<div class="rounded-2xl bg-white p-8 mb-10 text-white">
						<div class="relative cursor">
							<div class="absolute text-center -mt-16 -ml-16 h-34 w-34 rounded-full pt-1 px-2" v-if="user.gender == 'boy'">
								<img src="/assets/svg/character/emoji-1.svg" class="image-svg h-full" v-if="totalLines > 0" alt="">
								<img src="/assets/svg/character/emoji-5.svg" class="image-svg h-full" v-if="totalLines == 0" alt="">
							</div>							
							<div class="absolute text-center -mt-16 -ml-16 h-34 w-34 rounded-full pt-1 px-2" v-if="user.gender == 'girl'">
								<img src="/assets/svg/character/girl_emoji-1.svg" class="image-svg h-full" v-if="totalLines > 0" alt="">
								<img src="/assets/svg/character/girl_emoji-5.svg" class="image-svg h-full" v-if="totalLines == 0" alt="">
							</div>
						</div>
						<div class="relative mt-20 pt-5" v-if="totalLines > 0">
							
							<h1 class="text-3xl font-semibold capitalize leading-none text-r-dark-blue mobile:text-2xl laptop:text-2xl">You learnt a whopping total of:</h1>

						</div>							
						<div class="relative mt-20 pt-5" v-if="totalLines == 0">
							
							<h1 class="text-3xl font-semibold capitalize leading-none text-r-dark-blue mobile:text-2xl laptop:text-2xl">{{translations.msgs.lines_page_no_lines}}</h1>

						</div>						
						<div class="w-full mx-auto">	
								
							<div class="relative text-center">
								<img src="/assets/svg/circle.svg" class="h-56 w-56 inline-block" alt="">
								<div class="centered">
									<span class="block mobile:text-5xl mobile:pt-2 text-6xl h-16 font-semibold capitalize text-r-dark-blue">{{totalLines}}</span>
									<span class="block mobile:text-2xl text-3xl font-semibold capitalize text-r-dark-blue">Lines</span>
								</div>
							</div>
						</div>
					</div>	

					<div class="rounded-2xl bg-white p-8 mb-10 text-white">
						<div class="relative cursor">
							<div class="absolute text-center h-12 w-12 bg-gray-300 rounded-full pt-1 px-2">
								<img src="/assets/svg/icons/lines-gray.svg" class="h-10 w-10">
							</div>
						</div>
						<div class="relative mt-20">
							<h1 class="text-1xl md:text-3xl font-semibold uppercase text-r-dark-blue mb-5 leading-none">lines untill end of perek</h1>

							<div class="relative w-full rounded-full h-12 bg-gray-200 overflow-hidden">
								<div class="absolute h-12 w-100 rounded-l-full" 
								:class="{'bg-gradient-blue-80' : user.gender == 'boy', 'bg-gradient-pink-80' : user.gender == 'girl'}" 
								:style="{'width': perekPercent + '%'}"></div>
								<div class="w-full flex justify-between px-10 text-1xl font-medium leading-10 pt-2">
									<span class="z-10"> {{page.perek_length - page.till_end_of_perek}} </span>
									<span class="z-10 text-r-dark-blue">{{page.till_end_of_perek}}</span>									
								</div>

							</div>
						</div>
					</div>					


				</div>
			</div>			

			<div class="mobile:w-full desktop:w-3/4 laptop:ml-10 laptop:mr-0 laptop:w-3/4 desktop:mr-12">
				<div class="relative">

					<div class="rounded-2xl bg-white h-full p-8 md:px-10 text-white mb-10">

						<div class="relative cursor">
							<div class="absolute text-center h-12 w-12 bg-gray-200 rounded-full pt-1 px-2">
								<img src="/assets/svg/icons/lines-gray.svg" class="h-10 w-10">
							</div>
						</div>
						<h1 class="ml-16 text-1xl md:text-3xl font-semibold uppercase text-r-dark-blue mb-5">line entry</h1>
						<h1 class="font-semibold laptop:text-2xl leading-none mobile:text-2xl text-3xl text-r-dark-blue ml-2 mt-10" v-if="page.current_perek">You are currently up to line 
						{{page.booklet_line}} <span v-if="page.current_booklet_type == 'mishnayos'">of mishna {{page.current_mishna}} </span>in perek {{page.current_perek}} of {{page.perek_in}}</h1>
						<div class="relative mt-5">

								<label class="block mobile:pt-3 text-1xl md:text-3xl text-scooter h-10 mb-1 font-medium ml-2 capitalize">Booklet:</label>
								<div class="relative dropdown-link cursor w-full h-16 text-base md:text-xl text-r-dark-blue rounded-xl md:rounded-xl bg-gray-200 mb-5" @click.stop="setDropdown('bookletDropdown')">
									<span v-if="!booklet" class="text-gray-500 capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">select a booklet..</span>									
									<span v-if="booklet" class="text-r-dark-blue capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">{{booklet}}</span>
									<div class="w-full mt-16 bg-white rounded-xl absolute dropdown shadow-md overflow-hidden z-20" 
									:class="{ show: dropdown == 'bookletDropdown' }">
										<ul class="max-h-25 overflow-scroll overflow-x-hidden">
											<li v-for="(booklet, index) in booklets " class="p-3 pt-4 hover:bg-gray-300 capitalize font-semibold text-r-dark-blue" @click.stop="selectBooklet(booklet)">
												<span class="pl-4">{{booklet}}</span>
											</li>
										</ul>
									</div>
								</div>		
						
						<!-- mishnayos dropdowns -->
						<div v-if="booklet == 'mishnayos'">

								<label class="block mobile:pt-3 text-1xl md:text-3xl text-scooter h-10 mb-1 font-medium ml-2 capitalize">mesechta:</label>
								<div class="relative dropdown-link cursor w-full h-16 text-base md:text-xl text-r-dark-blue rounded-xl md:rounded-xl bg-gray-200 mb-5" @click.stop="setDropdown('mesechtaDropdown')">
									<span v-if="!mesechta.mesechta" class="text-gray-500 capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">select a mesechta..</span>									
									<span v-if="mesechta.mesechta" class="text-r-dark-blue capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">{{mesechta.mesechta}}</span>
									<div class="w-full mt-16 bg-white rounded-xl absolute dropdown shadow-md overflow-hidden z-20" 
									:class="{ show: dropdown == 'mesechtaDropdown' }">
										<ul class="max-h-25 overflow-scroll overflow-x-hidden">
											<li v-for="(mesechta, index) in mesechtos " class="p-3 pt-4 hover:bg-gray-300 capitalize font-semibold text-r-dark-blue" @click.stop="selectMesechta(mesechta.id)">
												<span class="pl-3">{{mesechta.shas_id}}</span>
												<span class="pl-4">{{mesechta.mesechta}}</span>
											</li>
										</ul>
									</div>
								</div>

								

							<div class="flex flex-col sm:flex-row">

								<div class="sm:mr-3 sm:w-1/2">
									<label class="block mobile:pt-3 text-1xl md:text-3xl text-scooter h-10 mb-1 font-medium ml-2 capitalize">Perek:</label>
									<div class="relative dropdown-link cursor w-full h-16 text-base md:text-xl text-r-dark-blue rounded-xl md:rounded-xl bg-gray-200 mb-5" @click.stop="setDropdown('perekDropdown')">
										<span v-if="!perek.perek" class="text-gray-500 capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">select a perek..</span>									
										<span v-if="perek.perek" class="text-r-dark-blue capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">{{perek.perek}}</span>
										<div class="w-full mt-16 bg-white rounded-xl absolute dropdown shadow-md overflow-hidden z-20" 
										:class="{ show: dropdown == 'perekDropdown' }">
											<ul class="max-h-25 overflow-scroll overflow-x-hidden">
												<li v-for="(perek, index) in prokim " class="p-3 hover:bg-gray-300 capitalize font-semibold text-r-dark-blue" @click.stop="selectPerek(perek.id)">
													<span class="pl-4">{{perek.perek}}</span>
												</li>
											</ul>
										</div>
									</div>
								</div>						

								<div class="sm:mr-3 sm:w-1/2">
									<label class="block mobile:pt-3 text-1xl md:text-3xl text-scooter h-10 mb-1 font-medium ml-2 capitalize">Mishna:</label>
									<div class="relative dropdown-link cursor w-full h-16 text-base md:text-xl text-r-dark-blue rounded-xl md:rounded-xl bg-gray-200 mb-5" @click.stop="setDropdown('mishnaDropdown')">

								<!-- 		<span v-if="!mishna.mishna" class="text-gray-500 capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">select a mishna..</span>									
										<span v-if="mishna.mishna" class="text-r-dark-blue capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">{{mishna.mishna}}</span> -->
							
										<span v-if="mishnayosSelected == 0" class="text-gray-500 capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">select a mishna..</span>									
										<span v-if="mishnayosSelected.length > 0" class="text-r-dark-blue capitalize font-semibold text-xl md:text-2xl centered w-full pl-5 text-left" style="direction: rtl;">{{mishnayosSelectedFromTo}}</span>

					
										<div class="w-full mt-16 bg-white rounded-xl absolute dropdown shadow-md overflow-hidden z-20" 
										:class="{ show: dropdown == 'mishnaDropdown' }">
											<ul class="max-h-25 overflow-scroll overflow-x-hidden">
												<!-- <li v-for="(mishna, index) in mishnayos " class="p-3 hover:bg-gray-300 capitalize font-semibold text-r-dark-blue"> -->
												<li v-for="(mishna, index) in mishnayos " class="p-3 hover:bg-gray-300 capitalize font-semibold text-r-dark-blue" @click.stop="selectMishna(mishna.id)">
													<div class="flex">
														<label class="inline-flex items-center">
													        <input type="checkbox" class="form-checkbox rounded-xl text-indigo-600 outline-none" 
													        v-model="mishna.checked">
													    </label>
														<span class="pl-4">{{mishna.mishna}}</span>
												    </div>
												</li>
											</ul>
										</div>
									</div>
								</div>	
						
							</div>
						
						</div>
						<!-- end mishnayos dropdowns -->

						<!-- tanya dropdowns -->
						<div v-if="booklet == 'tanya'">

								<label class="block mobile:pt-3 text-1xl md:text-3xl text-scooter h-10 mb-1 font-medium ml-2 capitalize">Perek:</label>
								<div class="relative dropdown-link cursor w-full h-16 text-base md:text-xl text-r-dark-blue rounded-xl md:rounded-xl bg-gray-200 mb-5" @click.stop="setDropdown('tanyaPerekDropdown')">
									<span v-if="!tanyaPerek.perek" class="text-gray-500 capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">select a perek..</span>									
									<span v-if="tanyaPerek.perek" class="text-r-dark-blue capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">{{tanyaPerek.perek}}</span>
									<div class="w-full mt-16 bg-white rounded-xl absolute dropdown shadow-md overflow-hidden z-20" 
									:class="{ show: dropdown == 'tanyaPerekDropdown' }">
										<ul class="max-h-25 overflow-scroll overflow-x-hidden">
											<li v-for="(perek, index) in tanyaProkim " class="p-3 hover:bg-gray-300 capitalize font-semibold text-r-dark-blue" @click.stop="selectTanyaPerek(perek.id)">
												<span class="pl-4">{{perek.perek}}</span>
											</li>
										</ul>
									</div>
								</div>
						
						</div>
						<!-- end tanya dropdowns -->

						</div>

						<div class="flex flex-col sm:flex-row" v-if="(booklet == 'mishnayos' && mishnayosSelected.length == 1) || booklet == 'tanya'">

							<div class="sm:mr-3 sm:w-1/2">
								<label class="block mobile:pt-3 text-1xl md:text-3xl text-scooter h-10 mb-1 font-medium ml-2 capitalize">From:</label>
								<div class="w-full h-16 text-base md:text-xl text-r-dark-blue rounded-xl md:rounded-xl bg-gray-200  mb-5 relative" @click.stop="setDropdown('from')">
									<span v-if="!from" class="text-gray-500 capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">from line..</span>									
									<span v-if="from" class="text-r-dark-blue capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">
									{{from.booklet_line}}</span>
									<div class="w-full mt-16 bg-white rounded-xl absolute dropdown shadow-md overflow-hidden z-20" 
									:class="{ show: dropdown == 'from' }">
										<ul class="max-h-10 overflow-scroll overflow-x-hidden">
											<li v-for="(line, index) in lines " class="p-3 hover:bg-gray-300 capitalize font-semibold text-r-dark-blue" @click.stop="selectFromLine(line)">
												<span class="pl-4">{{line.booklet_line}}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>						

							<div class="sm:mr-3 sm:w-1/2">
								<label class="block mobile:pt-3 text-1xl md:text-3xl text-scooter h-10 mb-1 font-medium ml-2 capitalize">to:</label>
								<div class="w-full h-16 text-base md:text-xl text-r-dark-blue rounded-xl md:rounded-xl bg-gray-200  mb-5 relative" @click.stop="setDropdown('to')">
									<span v-if="!to" class="text-gray-500 capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">to line..</span>									
									<span v-if="to" class="text-r-dark-blue capitalize font-semibold text-xl md:text-2xl centered w-full pl-5">
									{{to.booklet_line}}</span>
									<div class="w-full mt-16 bg-white rounded-xl absolute dropdown shadow-md overflow-hidden z-20" 
									:class="{ show: dropdown == 'to' }">
										<ul class="max-h-10 overflow-scroll overflow-x-hidden">
											<li v-for="(line, index) in toLines " class="p-3 hover:bg-gray-300 capitalize font-semibold text-r-dark-blue" @click.stop="selectToLine(line)">
												<span class="pl-4">{{line.booklet_line}}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>	
						</div>

					</div>
				</div>

					<button class="w-full mobile:h-12 h-16 rounded-full font-medium uppercase mobile:pt-2 pt-3 outline-none mobile:text-xl text-2xl gradient-button" 
					:class="{'btn-bg-gradient-blue-270' : user.gender == 'boy', 'btn-bg-gradient-pink-270' : user.gender == 'girl'}" @click.stop="promptPin()">submit</button>

				</div>
			</div>	

			<div class="bg-white rounded-xl desktop:mx-12 rounded-2xl p-8 mt-10 mb-20 lg:mt-0">
				<div class="relative cursor flex justify-between flex-wrap">
					<div class="absolute text-center h-12 w-12 bg-gray-300 rounded-full pt-1 px-2">
						<img src="/assets/svg/icons/lines-gray.svg" class="h-10 w-10">
					</div>
					<h1 class="ml-16 text-1xl md:text-3xl font-semibold uppercase text-r-dark-blue mb-5">{{translations.line_history_header}}</h1>

					<div class="w-48 inline-block right-0 flex items-center">
						<label class="block mobile:pt-3 mr-2 text-xl md:text-1xl text-scooter h-10 mb-1 font-medium ml-2 capitalize">Lines:</label>
						<div class="w-full h-12 text-base md:text-xl text-r-dark-blue rounded-xl md:rounded-xl bg-gray-200  mb-5 relative" @click.stop="setDropdown('history')">
							<span v-if="!history" class="text-gray-500 capitalize font-semibold text-base centered w-full px-auto text-center">Filter</span>									
							<span v-if="history" class="text-r-dark-blue capitalize font-semibold text-base centered w-full px-auto text-center">
							{{history}}</span>
							<div class="w-full mt-16 bg-white rounded-xl absolute dropdown shadow-md overflow-hidden z-20" 
							:class="{ show: dropdown == 'history' }">
								<ul class="max-h-10 overflow-scroll overflow-x-hidden">
									<li v-for="(type, index) in history_type " class="hover:bg-gray-300 capitalize font-semibold text-r-dark-blue" @click.stop="selectHistoryView(type)">
										<span class="p-3 block text-base">{{type}}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>

				</div>
				
				<div class="relative mt-5" ref="chazara">

					<div class="relative w-full" v-if="history == 'Not Chazered'">
						
						<div class="w-auto mb-3 h-10 float-right" style="min-width: 20px">
							<button v-if="chazeredChecked.length > 0" class="px-3 w-full h-full rounded-full font-medium uppercase pt-1 outline-none text-sm text-white gradient-button btn-bg-gradient-blue-270" @click.stop="markChazered()">Mark as Chazered</button>
					    </div>
						
						<div class="w-full overflow-x-scroll">
							<table class="table mb-0 w-full text-r-dark-blue">
								<thead class="bg-light">
									<tr>
						                <th scope="col" class="border-0 text-left capitalize text-1xl font-medium" width="1%"></th>
						                <th scope="col" class="border-0 text-left capitalize text-1xl font-medium" width="1%">mesechta</th>
						                <th scope="col" class="border-0 text-left capitalize text-1xl font-medium" width="1%">perek</th>
						                <th scope="col" class="border-0 text-left capitalize text-1xl font-medium" width="1%">mishna</th>
						                <th scope="col" class="border-0 text-left capitalize text-1xl font-medium" width="10%">Line</th>
									</tr>
								</thead>
								<tbody>
									<tr class="text-xl" v-for="(line, index) in page.not_chazered" :key="index">
										<td class="py-3">
										<div class="flex">
											<label class="inline-flex items-center">
										        <input type="checkbox" class="form-checkbox rounded-xl text-indigo-600 outline-none" 
										        v-model="line.checked">
										    </label>
										    </div>
										</td>
										<td>
											<span v-if="line.mesechta">{{line.mesechta}}</span>
											<span v-else>Tanya</span>
										</td>
										<td>
											<span v-if="line.perek">{{line.perek}}</span>
											<span v-if="line.tanya_perek">{{line.tanya_perek}}</span>
										</td>
										<td>
											<span v-if="line.mishna">{{line.mishna}}</span>
										</td>
										<td>
											<div>{{line.booklet_line}}</div>
										</td>								
									</tr>
								</tbody>
							</table>
						</div>

					</div>
				</div>

				<div class="relative w-full" v-if="history == 'Chazered'">
						
						<div class="w-auto mb-3 h-10 float-right" style="min-width: 20px">
							<!-- <button v-if="chazeredChecked.length > 0" class="px-3 w-full h-full rounded-full font-medium uppercase pt-1 outline-none text-sm text-white gradient-button btn-bg-gradient-blue-270" @click="markChazered()">Mark as Chazered</button> -->
					    </div>
						
						<div class="w-full overflow-x-scroll">
							<table class="table mb-0 w-full text-r-dark-blue">
								<thead class="bg-light">
									<tr>
						                <th scope="col" class="border-0 text-left capitalize text-1xl font-medium" width="1%"></th>
						                <th scope="col" class="border-0 text-left capitalize text-1xl font-medium" width="1%">mesechta</th>
						                <th scope="col" class="border-0 text-left capitalize text-1xl font-medium" width="1%">perek</th>
						                <th scope="col" class="border-0 text-left capitalize text-1xl font-medium" width="1%">mishna</th>
						                <th scope="col" class="border-0 text-left capitalize text-1xl font-medium" width="10%">Line</th>
									</tr>
								</thead>
								<tbody>
									<tr class="text-xl" v-for="(line, index) in page.chazered" :key="index">
										<td class="py-3">
										<div class="flex">
											<label class="inline-flex items-center">
										        <input type="checkbox" class="form-checkbox rounded-xl text-indigo-600 outline-none" 
										        v-model="line.checked">
										    </label>
										    </div>
										</td>
										<td>
											<span v-if="line.mesechta">{{line.mesechta}}</span>
											<span v-else>Tanya</span>
										</td>
										<td>
											<span v-if="line.perek">{{line.perek}}</span>
											<span v-if="line.tanya_perek">{{line.tanya_perek}}</span>
										</td>
										<td>
											<span v-if="line.mishna">{{line.mishna}}</span>
										</td>
										<td>
											<div>{{line.booklet_line}}</div>
										</td>								
									</tr>
								</tbody>
							</table>
						</div>

					</div>
				</div>

			</div>		

		</div>		


	</div>
</template>
<script>

	export default {
		data() {
			return {
		
				icon: 'default.png',
				url: '/assets/images/avatars/',
				default_image: 'default.png',
				dropdown: false,
				booklet: null,
				booklets: ['mishnayos','tanya'],
				from: null,
				to: null,
				chazara_lines: null,
				history: 'Not Chazered',
				history_type: ['Not Chazered', 'Chazered'],
				parents_pin: null,
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			logo: function () {
				return this.$store.getters.getLogos.main_logo;
			},
			
			translations() {
				return this.$store.getters.getTranslations
			},

			user() {
				return this.$store.getters.getUser
			},

			mesechtos() {
				return this.$store.getters.getMesechtos
			},

			mesechta() {
				return this.$store.getters.getMesechta
			},

			prokim() {
				return this.$store.getters.getMesechta.prokim
			},

			perek() {
				return this.$store.getters.getPerek
			},

			mishnayos() {
				return this.$store.getters.getPerek.mishnayos
			},

			mishna() {
				return this.$store.getters.getMishna
			},

			lines() {
				if(this.booklet == 'mishnayos'){
					return this.$store.getters.getMishna.lines
				}				
				if(this.booklet == 'tanya'){
					return this.tanyaPerek.lines
				}
			},

			toLines() {
				if(!this.from){
					return this.lines
				}
				return this.lines.filter(function(line){
					if(line.id >= this.from.id){
						return line
					}
				},{from: this.from})
			},

			tanyaProkim() {
				return this.$store.getters.getTanyaProkim
			},

			tanyaPerek() {
				return this.$store.getters.getTanyaPerek
			},

			selectedDateLines() {
				return this.$store.getters.getSelectedDateLines
			},

			totalLines () {
				return this.$store.getters.getTotalLines
			},

			page() {
				return this.$store.getters.getLinesPage
			},

			perekPercent() {
				return 100 - parseInt( Math.round( ( this.page.till_end_of_perek / this.page.perek_length ) * 100 ) )
			},

			chazeredChecked() {
				return this.page.not_chazered.filter(function(line){
					return line.checked
				})
			},

			// get object of mishnayos selected if multiple prokim selected
			mishnayosSelected() {
				if(this.mishnayos.length){
					return this.mishnayos.filter(mishna => mishna.checked)
				}
				return 0
			},

			// get from - to mishnayos selected // string
			mishnayosSelectedFromTo() {
				if(this.mishnayosSelected.length > 1){
					
					var selected = ''

					for(var i in this.mishnayosSelected){
						selected += this.mishnayosSelected[i].mishna+','
					}

					return selected
					// return this.mishnayosSelected[0].mishna+' - '+this.mishnayosSelected[this.mishnayosSelected.length-1].mishna
				}
				if(this.mishnayosSelected.length == 1){
					return this.mishnayosSelected[0].mishna
				}
			}

		},

		methods: {

			route: function (route) {
				this.$router.push({name:route})
			},

			setDropdown: function (dropdown) {
				if(this.dropdown == dropdown){
					return this.dropdownOff()
				}
				this.dropdown = dropdown
			},			

			dropdownOff: function () {
				this.dropdown = false
			},

			selectBooklet: function (booklet) {

				// if needs chazara scroll to chazara tab
				if(this.page.remaining_till_chazara == 0){

					this.selectHistoryView('Not Chazered')

					var element = this.$refs['chazara'];
				    var top = element.offsetTop;

				    window.scrollTo(100, top);

				    return
				}

				this.booklet = booklet
				this.dropdown = false
				this.$store.dispatch('resetPerek')
				this.$store.dispatch('resetMishna')
				this.resetToFrom()

    	        switch(booklet){
		          case 'mishnayos':
		            return this.$store.dispatch( 'getMesechtos' )
		          break; 		          
		          case 'tanya':
		            return this.$store.dispatch( 'getTanyaProkim' )
		          break;            
		        }
			},

			selectTanyaPerek: function (perek) {
				this.dropdown = false
				this.$store.dispatch('getPerekTanya', perek).then(response => {
					this.resetToFrom()
		        })		
			},

			selectMesechta: function (mesechta) {
				this.dropdown = false			
				this.$store.dispatch( 'getMesechta', mesechta).then(response => {
					this.$store.dispatch('resetPerek')
					this.$store.dispatch('resetMishna')
					this.resetToFrom()
		        })
			},			

			selectPerek: function (perek) {
				this.dropdown = false
				this.$store.dispatch('getPerek', perek).then(response => {
					this.$store.dispatch('resetMishna')
					this.resetToFrom()
		        })
			},			

			selectMishna: function (mishna) {
				this.dropdown = false
				// return console.log(this.mishnayosSelected.length)
				this.$store.dispatch('getMishna', mishna).then(response => {
					this.resetToFrom()
		        })	
								
			},			

			selectFromLine: function (line) {
				this.dropdown = false
				this.from = line
				this.to = null
			},			

			selectToLine: function (line) {
				this.dropdown = false
				this.to = line 
				
				this.promptPin()
			},			

			selectHistoryView: function (view) {
				this.dropdown = false
				this.history = view
			},

			resetToFrom: function () {
				this.from = null
				this.to = null
			},

			validation: function (data) {
				if(this.mishnayosSelected.length <= 1 && (!this.from || !this.to)){
					this.$store.dispatch( 'setPopUp', 
						{
							header:'missing data', 
							data:{
								message:this.translations.msgs.missing_info,
							},
							model: 'default',
							type:'message',
							emoji:'caution',
						});
					this.$store.dispatch('setPopUpStatus', 1);
					return false
				}
				if(data.pin == ''){
					this.$store.dispatch( 'setPopUp', 
					{
						header:'parents pin', 
						data:{
							message:'Please enter your parents pin',
							pin:''
						},
						model: 'parents_pin',
						type:'action',
						emoji:'danger',
						error:true,
					});
					return false
				}	
				return true
			},

			// mark unchazered lines as chazereed
			markChazered: function () {
				if(this.chazeredChecked.length == 0){return}

				var selected = this.page.not_chazered.map(function(line){
					if(line.checked){
						return line.id
					}
					return null
				})

				this.chazara_lines = selected.filter(function(line){
					return line
				})

				this.from = 'chazara'
				this.to = 'chazara'

				this.promptPin()

			},


			promptPin: function () {
				if(this.mishnayosSelected.length <= 1 && (!this.from || !this.to) || (this.mishnayosSelected == 0 && this.from !== 'chazara' && this.booklet !== 'tanya')){
					this.$store.dispatch( 'setPopUp', 
						{
							header:'missing data', 
							data:{
								message:this.translations.msgs.missing_info,
							},
							model: 'default',
							type:'message',
							emoji:'caution',
						});
					return this.$store.dispatch('setPopUpStatus', 1);
				}
				this.$store.dispatch( 'setPopUp', 
					{
						header:'parents pin', 
						data:{
							message:'Please enter your parents pin',
							pin:''
						},
						model: 'parents_pin',
						type:'action',
						emoji:'prompt',
					});
				this.$store.dispatch('setPopUpStatus', 1);
			},


			confirmParentsPin: function (data) {
				if(this.validation(data)){
					this.parents_pin = data.pin
					this.submit()
				}
			},

			submit: function () {

				var data = {
					parents_pin: this.parents_pin,
					chazara: false,
				}

				if(this.chazara_lines){
					data.chazara = true
					data.from = this.from
					data.to = this.to
					data.lines = this.chazara_lines
				}

				// if user is submitting new lines
				if(!this.chazara_lines){
					// check if miltiple mishnayos were selected
					if(this.mishnayosSelected.length > 1)
					{
						data.mishnayos = this.mishnayosSelected
					}
					// if only one mishna was selected set lines from to 
					else{
						data.from = this.from.id
						data.to = this.to.id						
					}
					// set booklet type 
					data.type = this.booklet
					data.lines = null
				}

				this.$store.dispatch( 'submitLineEntry', data).then(response => {
					
      				this.$store.dispatch( 'getLinePage' );
					this.$store.dispatch( 'setPopUp', 
					{
						header:response.header, 
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'wink',
						error:false,
					});
					this.$store.dispatch('setPopUpStatus', 1);

					this.booklet = null
					this.chazara_lines = null
					this.$store.dispatch('resetPerek')
					this.$store.dispatch('resetTanya')
					this.$store.dispatch('resetTanyaPerek')
					this.$store.dispatch('resetMishna')
					this.resetToFrom()

		        }).catch(response => {
					
					this.$store.dispatch( 'setPopUp', 
					{
						header:response.header, 
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'danger',

					});
					this.$store.dispatch('setPopUpStatus', 1);

		        });	
			},

		},

		beforeMount() {
      		this.$store.dispatch( 'getAppData' );
      		this.$store.dispatch( 'getTranslations' );
      		this.$store.dispatch( 'getLinePage' );
		},

		mounted() {
			this.$root.$on('confirm', (e) => {
				if(e.model == 'parents_pin'){
					this.confirmParentsPin(e.data)
				}				
		    }),			
		    this.$root.$on('submit', (e) => {			
				this.submit(e)
		    })

			// setTimeout( () => {
			//   	this.$store.dispatch( 'setCharachterStatus', 1)
			//   	var msg = (this.translations.msgs.token_register)
			// 	this.$store.dispatch( 'setCharachter', {class:'show',message:msg})
			// },300);	
		},

		beforeDestroy() {
        	this.$root.$off('confirm')
        	this.$root.$off('submit')
			this.$store.dispatch('resetMesechta')
			this.$store.dispatch('resetPerek')
			this.$store.dispatch('resetMishna')
		}

	}
</script>