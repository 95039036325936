/*
    Defines the API route we are using.
*/
var api_url = '';

switch( process.env.NODE_ENV ){
  case 'development':
    api_url = '/api/v2';
  break;
  case 'production':
    api_url = '/api/v2';
  break;
}


export const APP_CONFIG = {
  API_URL: api_url,
    PARENT_URL: '/parents',
}
