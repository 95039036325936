import ProkimApi from '../api/prokim.js';

export const perek = {

    state: {
        perek: {
        	mishnayos: {}, 
        },
    	perek_loading: 0,
    },

    getters: {

    	getPerek(state) {
            return state.perek;
        },    	

        getPerekLoadingStatus(state) {
            return state.perek_loading;
        },        

    },

    actions: {   

        //get perek with mishnayos
        getPerek({commit, dispatch}, data) {

             commit( 'setPerekLoadStatus', 1 );
             
             return new Promise((resolve, reject) => {

	             ProkimApi.getPerek( data )
	             .then( function( response ){
	                 commit( 'setPerek', response.data )
	                 commit( 'setPerekLoadStatus', 2 );
	                 resolve(response.data)

	             })
	             .catch( function(error){
	                 commit( 'setPerekLoadStatus', 3 );
	                 reject(error.response.data)
	             });     
	                     	
             })


        }, 

        resetPerek({commit}) {
             commit( 'resetPerek' );
        },       

    },

    mutations: {

	    setPerekLoadStatus( state, status ){
      		state.perek_loading = status;
	    },	    

	    setPerek( state, perek ){
      		state.perek = perek;
	    },		    

	    resetPerek( state, perek ){
      		state.perek = {mishnayos: {}};
	    },	    	        


    },

}