import TanyaAPI from '../api/tanya.js';

export const tanya = {

    state: {

       tanya: {},
       tanya_perek: {lines:{}},
       tanya_loading: 0,

    },

    getters: {

    	getTanyaProkim(state) {
            return state.tanya;
        },

        getTanyaPerek(state) {
            return state.tanya_perek;
        },

        getTanyaLoadingStatus(state) {
            return state.tanya_loading;
        },

    },

    actions: {

        //get tanya prokim
        getTanyaProkim( { commit }){

            commit( 'setTanyaLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                TanyaAPI.getTanyaProkim()
                .then( function( response ){
                    commit( 'setTanya', response.data );
                    commit( 'setTanyaLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setTanyaLoadStatus', 3 );
                    reject(error.response.data)
                });

            })
        },

        //get lines in perek tanya
        getPerekTanya( { commit }, data){

            commit( 'setTanyaLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                TanyaAPI.getPerekTanya(data)
                .then( function( response ){
                    commit( 'setPerekTanya', response.data );
                    commit( 'setTanyaLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setTanyaLoadStatus', 3 );
                    reject(error.response.data)
                });

            })
        },

        resetTanya({commit}) {
             commit( 'resetTanya' );
        },

        resetTanyaPerek({commit}) {
             commit( 'resetTanyaPerek' );
        },

    },

    mutations: {

	    setTanya( state, tanya ){
            state.tanya = tanya;
	    },

	    setPerekTanya( state, perek ){
            state.tanya_perek = perek;
	    },

	    setTanyaLoadStatus( state, status ){
      		state.tanya_loading = status;
	    },

        resetTanya( state ){
            state.tanya = {};
        },

        resetTanyaPerek( state ){
            state.tanya_perek = {lines: {}};
        },


    },
}
