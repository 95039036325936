import StoriesAPI from '../api/stories.js';

export const stories = {

    state: {
    	story: {},
    	story_status: 0,
        stories: [],
        stories_loading: 0,
        mark_as_read_loading: 0
    },

    getters: {

    	  getStoryStatus(state, getters) {
            return state.story_status
        },      	

        getStory(state, getters) {
            return state.story
        },        

        getStories(state, getters) {
            return state.stories
        },          
   	       

    },
    
    actions: {           

        setStoryStatus( { commit }, data){

            commit( 'setStoryStatus', data );

        },         

        setStory( { commit }, data){

            commit( 'setStory', data );

        },                     

        getStories({commit, dispatch}, data) {

             commit( 'setStoriesLoadStatus', 1 );
             
             return new Promise((resolve, reject) => {

                 StoriesAPI.getStories( data )
                 .then( function( response ){
                     commit( 'setStories', response.data.data )
                     commit( 'setStoriesLoadStatus', 2 );
                     resolve(response.data)

                 })
                 .catch( function(error){
                     commit( 'setStoriesLoadStatus', 3 );
                     reject(error.response.data)
                 });     
                            
             })

        }, 

        //mark story as read
        markStoryAsRead( { commit }, data){

            commit( 'setMarkAsReadLoadStatus', 1 );

            StoriesAPI.markStoryAsRead(data)
            .then( function( response ){
                commit( 'setMarkAsReadLoadStatus', 2 );
            })
            .catch( function(error){
                commit( 'setMarkAsReadLoadStatus', 3 );
            });
        }, 

        resetStories( {commit}) {
            commit( 'setStoriesLoadStatus', 1 );
            commit( 'setStories', [{data:{image:null}}] )
            commit( 'setStoriesLoadStatus', 2 );
        }

    },

    mutations: {

	    setStoryStatus( state, status ){
      		state.story_status = status;
	    }, 	    

	    setStory( state, story ){
      		state.story = story;
	    },

        setStories(state, stories) {
            state.stories = stories
        },        

        setStoriesLoadStatus(state, status) {
            state.stories_loading = status
        },      

        setMarkAsReadLoadStatus(state, status) {
            state.mark_as_read_loading = status
        }         
   

    },
}
