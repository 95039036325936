<style>

</style>

<template>
<div>
		<header class="main">
        <div class="header">
          <div class="wrapper">
            <h1>
              <router-link :to="{ name: 'landing'}" >
                <img class="logo" :src="'assets/logo/logo-5.svg'">
              </router-link>
            </h1>
            <div id="left-nav"></div>
            <nav>
              <ul>
                <!-- <li><router-link :to="{ name: 'register'}" >Kids Registration</router-link></li> -->
                <li><a href="" @click.prevent="resources">Resources</a></li>
                <li><a href="/parents/register">Registration</a></li>
                <li><a href="/parents">Parents Login</a></li>
                <li v-if="loggedIn"><router-link :to="{ name: 'dashboard'}" >{{getUser.name}}'s Account</router-link></li>
                <li v-if="!loggedIn" ><router-link :to="{ name: 'login'}" >Kids Login</router-link></li>
              </ul>
            </nav>
          </div>
        </div>
      </header>      

      <header class="mobile">
        <nav class="pre">
          <h1><router-link :to="{ name: 'landing'}" >MyShliach MBP</router-link></h1>
          <a @click.prevent="toggleMenu" href=""><span class="fa fa-bars"></span></a>
        </nav>
        <transition name="slide">
        <nav v-if="toggled && !menu" class="opened">
            <ul>
              <!-- <li><router-link :to="{ name: 'register'}" >Kids Registration</router-link></li> -->
              <li><a href="#" @click.prevent="resources">Resources</a></li>
              <li><a href="/parents/register">Registration</a></li>
              <li><a href="/parents">Parents Login</a></li>
              <li v-if="loggedIn"><router-link :to="{ name: 'dashboard'}" >{{getUser.name}}'s Account</router-link></li>
              <li v-if="!loggedIn" ><router-link :to="{ name: 'login'}" >Kids Login</router-link></li>
            </ul>
        </nav>
        </transition>
        <div v-if="toggled" class="page-overlay"></div>
      </header>
</div>
</template>

<script>

  export default {
  	props: ['cart', 'menu'],
  	data() {
		return {
	    	toggled: false,
		}
	},
  	computed: {
	    currentRouteName() {
	        return this.$route.name;
	    },
	    itemsInCartSum() {
	    	return this.$store.getters.getCart.length
	    },
	    loggedIn() {
	    	return this.$store.getters.getLoggedIn;
	    },	    
	    getUser() {
	    	return this.$store.getters.getUser;
	    },	    
	    getUser() {
	    	return this.$store.getters.getUser;
	    },
      settings() {
        return this.$store.getters.getSettings
      }
	},
	methods: {
	    toggleMenu: function () {
	      this.toggled = !this.toggled;
	    },
      resources: function () {
        window.open(this.settings.resource_link, '_blank')
      }
	},
  beforeMount() {
    this.$store.dispatch('getSettings');
  },
  	mounted: function () {
    // Listen to all clicks on the document
    document.addEventListener("click", function(event) {
      // If the click inside the element, do nothing
      if (event.target.closest("li")){
         this.toggled = false;
         return
      }              

      if (event.target.closest("nav")){
        return;
      }      

      // If the clicks outside the element, hide it!
      this.toggled = false;

    }.bind(this));
  },
  }
</script>

