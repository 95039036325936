<template>
	<transition name="fade">
		<div v-if="dataLoading" class="fixed w-full h-full flex justify-center items-center bg-overlay-white z-1000">
			<div>
				<img :src="'/assets/svg/data-loader.svg'" alt="Loading..">
			</div>
		</div>
	</transition>
</template>
<script>
	export default {
		computed: {
			dataLoading() {
				return this.$store.getters.getLoadingStatus;
			},			
		}

	}
</script>