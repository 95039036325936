var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"right-0 h-20 fixed laptop:hidden text-right p-5 z-20",staticStyle:{"z-index":"2000"}},[_c('div',{staticClass:"text-scooter font-medium text-3xl md:text-5xl"},[_c('i',{staticClass:"nav-btn fas fa-bars p-3 hover:bg-white hover:p-3",class:{'mobile:bg-white active' : _vm.state},on:{"click":_vm.toggle}})])]),_vm._v(" "),_c('div',{staticClass:"page-overlay laptop:hidden",class:{'overflow-visible' : _vm.state},on:{"click":function($event){return _vm.toggleOff()}}}),_vm._v(" "),_c('div',{staticClass:"sidebar min-h-full mobile:w-11/12 tablet:w-2/4 desktop:25r left-0 flex flex-col absolute flex-1 z-40 laptop:visible",class:{visible : _vm.state, 'bg-gradient-pink' : _vm.user.gender == 'girl', 'bg-gradient-blue' : _vm.user.gender == 'boy'},staticStyle:{"z-index":"1100"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"m-3 cursor h-12 inline-block leading-10 rounded-full text-1xl text-gray-600 w-12 float-left text-center",class:{
		'bg-scooter' : _vm.user.gender == 'boy',
		'hover:bg-scooter' : _vm.user.gender == 'boy',
		'active:bg-scooter' : _vm.user.gender == 'boy',
		'bg-pink-300' : _vm.user.gender == 'girl',
		'hover:bg-pink-300' : _vm.user.gender == 'girl',
		'active:bg-pink-300' : _vm.user.gender == 'girl'
	},on:{"click":function($event){$event.preventDefault();return _vm.route('landing')}}},[_c('i',{staticClass:"fas fa-home",staticStyle:{"line-height":"2.5","color":"white"}})]),_vm._v(" "),_c('div',{staticClass:"rounded-xl pt-10 px-8 text-white w-full mb-4 text-center"},[_c('div',{staticClass:"relative cursor"},[_c('img',{staticClass:"mobile:h-24 mobile:w-24 profile-status h-32 w-32 rounded-full mr-2 ml-2 overflow-hidden inline-block",class:{'p-1' : _vm.stories > 0},attrs:{"src":_vm.url+_vm.user.image,"alt":""},on:{"error":_vm.imageLoadError,"click":_vm.toggleStories}}),_vm._v(" "),_c('span',{staticClass:"mobile:text-2xl block text-ellipsis  mx-auto text-3xl font-semibold capitalize text-white mt-3 font-Baloo overflow-elipsis w-56"},[_vm._v(_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.family))])]),_vm._v(" "),_c('div',{staticClass:"flex justify-between w-full mt-5 px-8"},[_c('div',[_c('span',{staticClass:"mobile:text-2xl block text-3xl font-semibold"},[_vm._v(_vm._s(_vm.user.lines))]),_vm._v(" "),_c('span',{staticClass:"mobile:text-xl text-1xl"},[_vm._v("Lines")])]),_vm._v(" "),_c('div',[_c('span',{staticClass:"mobile:text-2xl block text-3xl font-semibold"},[_vm._v(_vm._s(_vm.user.time))]),_vm._v(" "),_c('span',{staticClass:"mobile:text-xl text-1xl"},[_vm._v("Minutes")])])])]),_vm._v(" "),_c('div',{staticClass:"h-full flex flex-col justify-between flex-1 mt-4 max-h-screen"},[_c('ul',{staticClass:"lg:mr-10 pt-3 pr-10 sm:pr-5"},[_c('li',{staticClass:"pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center",class:{'bg-scooter' : _vm.pageName == 'dashboard' && _vm.user.gender == 'boy', 'bg-pink-300' : _vm.pageName == 'dashboard' && _vm.user.gender == 'girl',
					'hover:bg-scooter' : _vm.user.gender == 'boy',
					'active:bg-scooter' : _vm.user.gender == 'boy',
					'hover:bg-pink-300' : _vm.user.gender == 'girl',
					'active:bg-pink-300' : _vm.user.gender == 'girl',
				},on:{"click":function($event){$event.preventDefault();return _vm.route('dashboard')}}},[_c('img',{staticClass:"inline-block image ml-5",attrs:{"src":"/assets/svg/icons/dashboard.svg","alt":""}}),_vm._v(" "),_c('span',{staticClass:"inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2"},[_vm._v("\n\t\t\t\tDashboard")])]),_vm._v(" "),_c('li',{staticClass:"pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer  rounded-r-full flex items-center",class:{'bg-scooter' : (_vm.pageName == 'bank' && _vm.user.gender == 'boy' ) || (_vm.pageName == 'transfers' && _vm.user.gender == 'boy'), 'bg-pink-300' : _vm.pageName == 'bank' && _vm.user.gender == 'girl',
					'hover:bg-scooter' : _vm.user.gender == 'boy',
					'active:bg-scooter' : _vm.user.gender == 'boy',
					'hover:bg-pink-300' : _vm.user.gender == 'girl',
					'active:bg-pink-300' : _vm.user.gender == 'girl',
				},on:{"click":function($event){$event.preventDefault();return _vm.route('bank')}}},[_c('img',{staticClass:"inline-block image ml-5",attrs:{"src":"/assets/svg/icons/bank.svg","alt":""}}),_vm._v(" "),_c('span',{staticClass:"inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2"},[_vm._v("\n\t\t\t\tBank")])]),_vm._v(" "),_c('li',{staticClass:"pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center",class:{'bg-scooter' : _vm.pageName == 'store' && _vm.user.gender == 'boy', 'bg-pink-300' : _vm.pageName == 'store' && _vm.user.gender == 'girl',
					'hover:bg-scooter' : _vm.user.gender == 'boy',
					'active:bg-scooter' : _vm.user.gender == 'boy',
					'hover:bg-pink-300' : _vm.user.gender == 'girl',
					'active:bg-pink-300' : _vm.user.gender == 'girl',
				},on:{"click":function($event){$event.preventDefault();return _vm.route('store')}}},[_c('img',{staticClass:"inline-block image ml-5",attrs:{"src":"/assets/svg/icons/store.svg","alt":""}}),_vm._v(" "),_c('span',{staticClass:"inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2"},[_vm._v("\n\t\t\t\tRewards Store")])]),_vm._v(" "),_c('li',{staticClass:"pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer  rounded-r-full flex items-center",class:{'bg-scooter' : _vm.pageName == 'time' && _vm.user.gender == 'boy',
					'hover:bg-scooter' : _vm.user.gender == 'boy',
					'active:bg-scooter' : _vm.user.gender == 'boy',
					'hover:bg-pink-300' : _vm.user.gender == 'girl',
					'active:bg-pink-300' : _vm.user.gender == 'girl',
				},on:{"click":function($event){$event.preventDefault();return _vm.route('time')}}},[_c('img',{staticClass:"inline-block image ml-5",attrs:{"src":"/assets/svg/icons/time.svg","alt":""}}),_vm._v(" "),_c('span',{staticClass:"inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2"},[_vm._v("\n\t\t\t\tAdd Time")])]),_vm._v(" "),_c('li',{staticClass:"pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center",class:{'bg-scooter' : _vm.pageName == 'lines' && _vm.user.gender == 'boy',
					'hover:bg-scooter' : _vm.user.gender == 'boy',
					'active:bg-scooter' : _vm.user.gender == 'boy',
					'hover:bg-pink-300' : _vm.user.gender == 'girl',
					'active:bg-pink-300' : _vm.user.gender == 'girl',
				},on:{"click":function($event){$event.preventDefault();return _vm.route('lines')}}},[_c('img',{staticClass:"inline-block image ml-5",attrs:{"src":"/assets/svg/icons/lines.svg","alt":""}}),_vm._v(" "),_c('span',{staticClass:"inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2"},[_vm._v("\n\t\t\t\tAdd Lines")])]),_vm._v(" "),_c('li',{staticClass:"pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center",class:{'bg-scooter' : _vm.pageName == 'resources' && _vm.user.gender == 'boy',
					'hover:bg-scooter' : _vm.user.gender == 'boy',
					'active:bg-scooter' : _vm.user.gender == 'boy',
					'hover:bg-pink-300' : _vm.user.gender == 'girl',
					'active:bg-pink-300' : _vm.user.gender == 'girl',
				},on:{"click":function($event){$event.preventDefault();return _vm.resources($event)}}},[_c('img',{staticClass:"inline-block image ml-5",attrs:{"src":"/assets/svg/icons/resources.svg","alt":""}}),_vm._v(" "),_c('span',{staticClass:"inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2"},[_vm._v("\n\t\t\t\tResources")])])]),_vm._v(" "),_c('ul',{staticClass:"lg:mr-10 pt-3 mt-10 mb-5"},[_c('li',{staticClass:"pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center",class:{
					'hover:bg-scooter' : _vm.user.gender == 'boy',
					'active:bg-scooter' : _vm.user.gender == 'boy',
					'hover:bg-pink-300' : _vm.user.gender == 'girl',
					'active:bg-pink-300' : _vm.user.gender == 'girl',
				},on:{"click":function($event){return _vm.logout()}}},[_c('img',{staticClass:"inline-block image ml-5",attrs:{"src":"/assets/svg/icons/logout.svg","alt":""}}),_vm._v(" "),_c('span',{staticClass:"inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2"},[_vm._v("\n\t\t\t\tLogout")])]),_vm._v(" "),_c('li',{staticClass:"pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center",class:{
					'hover:bg-scooter' : _vm.user.gender == 'boy',
					'active:bg-scooter' : _vm.user.gender == 'boy',
					'hover:bg-pink-300' : _vm.user.gender == 'girl',
					'active:bg-pink-300' : _vm.user.gender == 'girl',
				},on:{"click":function($event){return _vm.loginAsParent()}}},[_c('img',{staticClass:"inline-block image ml-5",attrs:{"src":"/assets/svg/icons/logout.svg","alt":""}}),_vm._v(" "),_c('span',{staticClass:"inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2"},[_vm._v("\n                    Login as parent\n                ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }