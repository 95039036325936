<template>
<div class="model-pop-up-card" v-on:click.stop v-if="message.type == 'message'">

  <div class="card card-small px-5 py-2">

    <div class="card-header pb-0 relative">
    	<div class="absolute">
			<img src="/assets/svg/character/emoji-1.svg" class="h-40 w-40 -mt-20 -ml-16" alt="">
    	</div>
		<h1 class="font-bold text-xl text-r-dark-blue mb-4 ml-10 uppercase">{{message.header}}</h1>  	
  	</div>

	<div>
		<div class="my-2 mx-auto px-5">
			<h6 class="mb-0 font-bold text-r-dark-blue text-xl md:text-1xl text-center">{{message.data.message}}</h6>
		</div>
	</div>

  	<div class="card-footer flex justify-end pt-0">    	          	
    	<div class="text-right">   		
    		<button class="rounded-xl bg-r-blue hover:bg-r-lighter-blue active:bg-r-darker-blue w-32 md:w-40 h-12 hover:shadow-dense-blue-2 outline-none text-white text-lg md:text-l font-bold cursor"
    		@click.prevent="confirm">OK</button>        		
    	</div>      	
    </div>

  </div>  

</div>
</template>
<script>
	export default {
		computed: {
			message() {
				return {header:'test pin',data:{message:'test fit more then you think you cn'},type:'message'}
				return this.$store.getters.getAlert;
			},			
		}
	}
</script>