<template>
	<div class="mobile:mt-20 tablet:mt-20 laptop:pl-23r my-10 px-5 md:px-10 wrapper" @click="dropdownOff()" style="min-height: calc(100vh - 5rem);">
	
	<store-header></store-header>

	<!-- category products  -->
	
	<div class="w-full">
		<div class="flex justify-between">
			<h1 class="capitalize font-semibold mobile:text-xl md:text-3xl text-black my-3 relative">{{category}}</h1>
			
			<!-- sort by dropdown -->
			<div class="relative w-56 text-flush-orange py-4 text-1xl text-right" @click.stop="setDropdown('sortDropdown')">
				<span class="font-medium ">Sort By:</span> 
				<span v-if="sortBy" class="text-sm"> {{sortBy.tag}} </span>
				<div class="w-full bg-white rounded-xl absolute dropdown shadow-sm border overflow-hidden z-20" 
				:class="{ show: dropdown == 'sortDropdown' }">
					<ul class="max-h-25 overflow-scroll overflow-x-hidden text-left">
						<li v-for="(option, index) in sortOptions" class="py-1 px-2 hover:bg-gray-300 capitalize font-medium text-black">
							<span class="block text-base pt-2 h-10" @click.stop="selectSort(option)">{{option.tag}}</span>
						</li>						
					</ul>
				</div>
			</div>

		</div>
		<div class="w-full product-grid">
			<div v-for="(product, index) in products.data" class="h-64 md:h-75 lg:h-21r border border-flush-orange overflow-hidden rounded-xl w-1/2 md:w-64 product flex-shrink-0 cursor-pointer" @click.stop="getProduct(product.id)">
				
				<div class="h-40 md:h-48 lg:h-56 w-full bg-white p-4 text-center flex justify-center items-center">
					<img :src="url+product.image" @error="imageLoadError" class="flex-shrink-0 image h-32 w-32 md:h-40 md:w-40 lg:h-48 lg:w-48 inline-block rounded-xl" alt="" style="object-fit: cover;">
				</div>

				<div class="w-full h-20 md:h-24 lg:h-32 px-3 flex flex-col justify-center">
					<h1 class="w-full text-ellipsis text-xl text-flush-orange font-semibold">{{product.title}}</h1>
					<div class="flex justify-between">
						<h1 class="flex-shrink-0 capitalize font-semibold mobile:text-xl md:text-1xl text-yellow-400 relative pt-3 inline-block">
							<i class="fa-star" :class="{'fas' : product.stars > 0 , 'far' : product.stars < 1}"></i>
							<i class="fa-star" :class="{'fas' : product.stars > 1 , 'far' : product.stars < 2}"></i>
							<i class="fa-star" :class="{'fas' : product.stars > 2 , 'far' : product.stars < 3}"></i>
							<i class="fa-star" :class="{'fas' : product.stars > 3 , 'far' : product.stars < 4}"></i>
							<i class="fa-star" :class="{'fas' : product.stars > 4 , 'far' : product.stars < 5}"></i>
						</h1>
						<div class="w-32 text-right">
						<h1 v-if="product.sale" class="h-full text-right text-ellipsis text-2xl text-green-600 font-bold inline-block">${{product.sale}}</h1>
						<h1 :class="{'mx-2 line-through text-1xl font-medium': product.sale, 'text-2xl font-bold ' : !product.sale}" 
						class="mx-2 h-full text-right text-ellipsis text-flush-orange inline-block">${{product.price}}</h1>	
						</div>
						
					</div>
				</div>

			</div>
		</div>
	</div>
	<!-- end category products -->

	<!--  pagination -->
		<div class="w-full flex overflow-x-scroll overflow-y-hidden justify-center mt-5"> 
			<vue-pagination :pagination="products.meta" :color="'orange'"
   			@paginate="getPage()"
   			:offset="2">
			</vue-pagination>
		</div>
	<!--  end pagination -->

	<!-- shop by trending -->

	<div class="h-64 md:h-75 lg:h-21r mt-6 mb-20 rounded-xl relative">
		<h1 class="capitalize font-semibold mobile:text-xl md:text-2xl text-black my-3 relative">Trending</h1>
		<div class="w-full absolute">
			<div class="w-full product-carousel cursor">
				<div v-for="(product, index) in trending.data" class="h-64 md:h-75 lg:h-21r border border-flush-orange overflow-hidden rounded-xl mobile:w-10/12 sm:w-64 mr-2 md:mx-0 md:mr-3 inline-block product" @click.stop="getProduct(product.id)">
					<div class="h-40 md:h-48 lg:h-56 w-full bg-white p-4 text-center justify-center items-center flex">
						<img :src="url+product.image" @error="imageLoadError" class="flex-shrink-0 image h-32 w-32 md:h-40 md:w-40 lg:h-48 lg:w-48 inline-block rounded-xl" alt="" style="object-fit: cover;">
					</div>

					<div class="w-full h-20 md:h-24 lg:h-32 px-3 flex flex-col justify-center">
						<h1 class="w-full text-ellipsis text-xl text-flush-orange font-semibold">{{product.title}}</h1>
						<div class="flex justify-between">
							<h1 class="flex-shrink-0 capitalize font-semibold mobile:text-xl md:text-1xl text-yellow-400 relative pt-3 inline-block">
								<i class="fa-star" :class="{'fas' : product.stars > 0 , 'far' : product.stars < 1}"></i>
								<i class="fa-star" :class="{'fas' : product.stars > 1 , 'far' : product.stars < 2}"></i>
								<i class="fa-star" :class="{'fas' : product.stars > 2 , 'far' : product.stars < 3}"></i>
								<i class="fa-star" :class="{'fas' : product.stars > 3 , 'far' : product.stars < 4}"></i>
								<i class="fa-star" :class="{'fas' : product.stars > 4 , 'far' : product.stars < 5}"></i>
							</h1>
							<h1 class="w-32 text-right text-ellipsis text-2xl text-flush-orange font-bold inline-block">${{product.price}}</h1>
						</div>
					</div>
				</div>
			</div>				
		</div>
	</div>

	<!-- end shop by category -->

	</div>
</template>
<script>
	
	import character from '../components/Character.vue'
	import vuePagination from '../components/VuePagination.vue'
	import StoreHeader from '../components/StoreHeader.vue';

	export default {
		data() {
			return {
		
				icon: 'default.png',
				url: '/assets/images/products/',
				default_image: 'default-product.png',
				dropdown: false,
				sortBy: {tag:'title',sort:'title',order:'asc'},			
			}
		},
		computed: {
	

			logo: function () {
				return this.$store.getters.getLogos.main_logo;
			},
			
			translations() {
				return this.$store.getters.getTranslations
			},

			user() {
				return this.$store.getters.getUser
			},

			category() {
				return this.$route.params.category
			},

			sortOptions() {
				var array = [
					{tag:'price low to high',sort:'price',order:'asc'},
					{tag:'price high to low',sort:'price',order:'desc'},
					{tag:'title',sort:'title',order:'asc'},
				]

				var filtered = []

				for(var i in array){
					if (this.sortBy.tag !== array[i].tag){
						filtered.push(array[i])
					}
				}

				return filtered
			},

			query() {
				return 'category='+this.category+'&sortBy='+this.sortBy.sort+'&order='+this.sortBy.order
			},

			products() {
				return this.$store.getters.getProducts
			},	

			page() {
				return parseInt(this.$route.params.page)
			},
			
			trending() {
				return this.$store.getters.getTrending
			}

		},

		methods: {

			//check if image is broken
	  		imageLoadError: function () {
	  			event.target.src = this.url+this.default_image;
	  		},

	  		// push to route
			route: function (route) {
				this.$router.push({name:route})
			},				

			// push back route
			back: function () {
				this.$router.go(-1)
			},			

			// toggle dropdown
			setDropdown: function (dropdown) {
				if(this.dropdown == dropdown){
					return this.dropdownOff()
				}
				this.dropdown = dropdown
			},	

			// toggle off
			dropdownOff: function () {
				this.dropdown = false
			},

			// select sort 
			selectSort: function (option) {
				this.sortBy = option
				this.dropdown = false

				this.$store.dispatch( 'getProducts', {
					Page: 1,
					Query: this.query,
				}).then(response => {
					//push route with new query 
					this.$router.push({
						name:'category', 
						params:{
							page:this.products.meta.current_page,
							category:this.category
						}, 
						query: {sortBy:this.sortBy.sort, order: this.sortBy.order} 
					})
				})
			},

			//get data for page 
			getPage: function () {

				//dispatch fetch action for paginated users
				this.$store.dispatch( 'getProducts', {
					Page: this.products.meta.current_page,
					Query: this.query
				}).then(response => {
					//push route with new query 
					this.$router.push({
						name:'category', 
						params:{
							page:this.products.meta.current_page,
							category:this.category
						}, 
						query: {sortBy:this.sortBy.sort, order: this.sortBy.order} 
					})
				})

	        },

	        // select product 
	        getProduct: function (id) {
	        	this.$router.push({name:'product', params:{id:id}})
	        },

		},

		components: {
			character,
			vuePagination,
			StoreHeader,
		},	

		filters: {

		},

		beforeMount() {
      		this.$store.dispatch( 'getTranslations' );
	        this.$store.dispatch('resetProducts')
			this.$store.dispatch( 'getProducts', {
				Page: parseInt(this.$route.params.page),
				Query: this.query,
			});
      		this.$store.dispatch( 'getTrending', 'category='+this.category);

		},


	}
</script>