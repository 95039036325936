export const charachter = {

    state: {
    	charachter: {class:null, message: null, gender: null},
    	charachter_status: 0,
    },

    getters: {

    	getCharachterStatus(state, getters) {
            return state.charachter_status
        },      	

        getCharachter(state, getters) {
            return state.charachter
        },          	       

    },
    
    actions: {           

        setCharachterStatus( { commit }, data){

            commit( 'setCharachterStatus', data );

        },         

        setCharachter( { commit }, data){

            commit( 'setCharachter', data );

        },               


    },

    mutations: {

	    setCharachterStatus( state, status ){
      		state.charachter_status = status;
	    }, 	    

	    setCharachter( state, charachter ){
      		state.charachter = charachter;
	    },         
  

    },
}