/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	   

    //get translations
    getTranslations: function(){
        return axios.get(APP_CONFIG.API_URL+'/translations/translations'); 
    },     

    //get settings
    getSettings: function(){
        return axios.get(APP_CONFIG.API_URL+'/settings'); 
    },  

}