/*
|-------------------------------------------------------------------------------
| routes.js
|-------------------------------------------------------------------------------
| Contains all of the routes for the application
*/

/*
    Imports Vue and VueRouter to extend with the routes.
*/
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store.js';

/*
	Landing Page
*/
import BasicLayout from './layouts/BasicLayout.vue';
import Register from './pages/Register.vue';
import Landing from './pages/Landing.vue';

import LoginLayout from './layouts/LoginLayout.vue';
import Login from './pages/Login.vue';

import UserLayout from './layouts/UserLayout.vue';
import Dashboard from './pages/Dashboard.vue';
import Bank from './pages/Bank.vue';
import BankTransfers from './pages/Transfers.vue';
import Time from './pages/Time.vue';
import Lines from './pages/Lines.vue';

import StoreLayout from './layouts/StoreLayout.vue';
import StoreHome from './pages/Store.vue';
import StoreProducts from './pages/Products.vue';
import ProductPage from './pages/ProductPage.vue';
import Cart from './pages/Cart.vue';

/*
    Extends Vue to use Vue Router
*/
Vue.use( VueRouter )

export default new VueRouter({


mode: 'history',
scrollBehavior() {
    return { x: 0, y: 0 };
  },
	routes: [
		{
		path: '/',
		component: BasicLayout,

		children : [
				{
					name: 'register',
					path: '/register',
					component: Register,
				},
				{
					name: 'landing',
					path: '/',
					component: Landing,
				},

			],
		},
		{
		path: '/',
		component: UserLayout,
		meta: { requiresAuth : true, },

		children : [
				{
					name: 'dashboard',
					path: '/dashboard',
					component: Dashboard,
				},
				// {
				// 	name: 'bank',
				// 	path: '/bank',
				// 	component: Bank,
				// },
				{
					name: 'bank',
					path: 'bank/:page?',
					props: (route) => ({ page: route.params.page || 1 }),
					component: Bank,
				},
				{
					name: 'transfers',
					path: '/transfers',
					component: BankTransfers,
				},
				{
					name: 'time',
					path: '/time',
					component: Time,
				},
				{
					name: 'lines',
					path: '/lines',
					component: Lines,
				},

			],
		},
		{
		path: '/store',
		component: StoreLayout,
		// meta: { requiresAuth : true, },

		children : [
				{
					name: 'store',
					path: '/',
					component: StoreHome,
				},
				{
					name: 'category',
					path: 'category/:category/:page?',
					props: (route) => ({ page: route.params.page || 1 }),
					component: StoreProducts,
				},
				{
					name: 'product',
					path: 'product/:id',
					component: ProductPage,
				},
				{
					name: 'cart',
					path: 'cart',
					component: Cart,
				},
			],
		},
		{
		path: '/',
		component: LoginLayout,
		meta: { requiresGuest : true, },

		children : [
				{
					name: 'login',
					path: '/login',
					component: Login,
				},
			],
		},
	]
});
