<template>
	<div class="mobile:mt-20 laptop:pl-25r my-10 px-10" style="min-height: 917px;">
		
		<div class="flex flex-col lg:flex-row ">				

			<div class="mobile:w-full mobile:w-full laptop:w-1/3 desktop:ml-10 desktop:mr-10 laptop:ml-3 laptop:mr-3 mb-10">
				
				<div class="relative">
					<div class="rounded-2xl bg-white pt-8 pb-5 px-8 text-white w-full mb-10 inline-block">
						<div class="relative text-center">	
						</div>
						<div class="relative mt-12 mb-5 text-center">
							<h1 class=" text-3xl font-semibold text-r-dark-blue mb-5 uppercase">mbpd balance</h1> 								
							<div class="w-full mx-auto">	
								
								<div class="relative text-center">
									<img src="/assets/svg/circle-2.svg" class="h-56 w-56 inline-block" alt="">
									<div class="centered">
										<span class="block text-6xl h-16 font-semibold capitalize text-green-400">{{user.mbpd}}</span>
									</div>
								</div>
							</div>	
						</div>
					</div>									

					<div class="mobile:h-48 rounded-2xl bg-gradient-green-blue-40 p-8 text-white cursor-pointer" @click="route('transfers')">
						<div class="relative cursor">
							<div class="absolute text-center h-12 w-12 bg-white rounded-full pt-1 px-2">
								<img src="/assets/svg/icons/wallet-gray.svg" class="h-10 w-10">
							</div>
						</div>
						<div class="relative mt-20">
							<h1 class="mobile:text-3xl text-5xl font-medium uppercase text-white leading-9">Send mbpd</h1>
							<!-- <h1 class="mobile:text-xl text-3xl font-semibold uppercase text-white mt-2">to a sibiling</h1> -->
						</div>
					</div>


				</div>
			</div>			

			<div class="mobile:w-full desktop:w-1/2 laptop:ml-10 laptop:mr-0 desktop:mr-20">
				<div class="relative">

					<div class="rounded-2xl bg-white h-full p-8 md:px-10 text-white mb-10">

						<div class="relative cursor">
							<div class="absolute text-center h-12 w-12 bg-gray-200 rounded-full pt-1 px-2">
								<img src="/assets/svg/icons/transactions-gray.svg" class="h-10 w-10">
							</div>
						</div>
						
						<div class="relative mt-20 mobile:text-center">
							<h1 class=" text-3xl font-semibold uppercase text-r-dark-blue mb-5">my mbpd summary</h1>
							
							<div class="flex justify-between flex-wrap">
								<div class="flex items-center mt-4">
									<span class="mobile:h-12 mobile:w-12 mobile:text-xl  h-20 w-20  rounded-full mr-3 block bg-scooter text-white font-bold flex justify-center items-center text-3xl font-semibold pt-1">{{balanceData.earned}}</span>
									<span class="mobile:text-2xl text-scooter inline-block text-3xl font-medium uppercase pt-1">earned</span>
								</div>							
								<div class="flex items-center mt-4">
									<span class="mobile:h-12 mobile:w-12 mobile:text-xl h-20 w-20  rounded-full mr-3 block bg-coral text-white font-bold flex justify-center items-center text-3xl font-semibold pt-1">{{balanceData.spent}}</span>
									<span class="mobile:text-2xl text-coral inline-block text-3xl font-medium uppercase pt-1">spent</span>
								</div>
							</div>
						</div>

						<div class="block h-full mt-16">

							<div class="flex justify-between items-center mb-5" v-for="(transaction, index) in transactions.data">
								<div>
									<span class="overflow-ellipsis mobile:w-32 mobile:h-6 mobile:text-base text-r-dark-blue block text-3xl h-8 font-semibold">
										<span v-if="transaction.time_amount == 0 && transaction.negative"> 
										{{translations.time_removal}} </span>
										<span v-if="transaction.time_amount && !transaction.negative">{{transaction.time_amount}} </span>
										<span v-if="transaction.line_amount">{{transaction.line_amount}} </span>
										<span v-if="transaction.type == 'transfer'">{{transaction.recipient_tag}} </span>
									{{transaction.type, translations | titleFilter}}</span>								
									<span class="mobile:text-base text-r-dark-blue text-2xl">{{transaction.date | dateFormat}}</span>									
								</div>
								<span class="flex-shrink-0 mobile:font-medium mobile:text-2xl mobile:h-10 mobile:w-10 h-20 w-20  rounded-full block text-white font-bold flex justify-center items-center text-3xl font-semibold pt-1" 
								:class="[transaction.negative ? 'bg-coral' : '', !transaction.negative ? 'bg-scooter' : '']">{{transaction.amount}}</span>
							</div>

							<div class="w-full flex overflow-x-scroll"> 
				   				<vue-pagination :pagination="transactions.meta"
		               			@paginate="getPage()"
		               			:offset="2">
			            		</vue-pagination>
							</div>	
						</div>

					</div>


				</div>
			</div>			

		</div>		


	</div>
</template>
<script>

	import character from '../components/Character.vue'
    import VuePagination from '../components/VuePagination.vue';
	import Hebcal from 'hebcal';
	import moment from 'moment'

	export default {
		data() {
			return {
		
				icon: 'default.png',
				url: '/assets/images/avatars/',
				default_image: 'default.png',
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			logo: function () {
				return this.$store.getters.getLogos.main_logo;
			},
			
			translations() {
				return this.$store.getters.getTranslations
			},			

			settings() {
				return this.$store.getters.getSettings
			},			

			transactions() {
				return this.$store.getters.getTransactions
			},

			balanceData() {
				return this.$store.getters.getTransactionsData
			},

			user() {
				return this.$store.getters.getUser
			}

		},

		methods: {


			character: function () {
				return require('../../svg/character/char2.svg')
			},

			route: function (route) {
				this.$router.push({name:route})
			},

			getPage: function () {
				this.$store.dispatch( 'getTransactions', {PerPage:this.settings.per_page, Page: this.transactions.meta.current_page } );
				this.$router.push({name:'bank', params:{page:this.transactions.meta.current_page}})
			}

		},
						

		filters: {
			dateFormat: function (value) {
		       	return new Hebcal.HDate(new Date(moment(value, 'DD-MM-YYYY').format("YYYY-MM-DD")));
			},			
			titleFilter: function (value, trans) {
				switch(value){

		            case 'lines':
						return trans.line_entry_title
		            break;            
		            case 'time':
						return trans.time_entry_title
		            break;

		        }
			},
		},

		components: {
			character,
			VuePagination,
		},	

		beforeMount() {
      		this.$store.dispatch( 'getAppData' );
      		this.$store.dispatch( 'getUser' );
			this.$store.dispatch( 'getTransactions', {PerPage:this.settings.per_page, Page: parseInt(this.$route.params.page)} );
		},

		mounted() {
			// setTimeout( () => {
			//   	this.$store.dispatch( 'setCharachterStatus', 1)
			//   	var msg = (this.translations.msgs.token_register)
			// 	this.$store.dispatch( 'setCharachter', {class:'show',message:msg})
			// },300);	
		},

	}
</script>