/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {

    //submit place order
 	submitOrder: function(data,intl){
        return axios.post(APP_CONFIG.API_URL+'/order', {cart:data,intl:intl}); 
    }, 	
 	

}