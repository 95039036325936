export const popup = {

    state: {
    	popup: {data:{message:"Message"}, header: 'Header', type:'message'},
        alert: {data:{message:"Message"}, header: 'Header', type:'message'},
        store_popup: {data:{message:"Message"}, header: 'Header', type:'message'},
        cart_popup: {data:{message:"Message"}, header: 'Header', type:'message'},
    	popup_status: 0,
        alert_status: 0,
        store_popup_status: 0,
        cart_popup_status: 0,
        locked_status: 0,
    },

    getters: {

    	getPopUpStatus(state, getters) {
            return state.popup_status
        },      	

        getPopUp(state, getters) {
            return state.popup
        },          

        getPopUpLockedStatus(state, getters) {
            return state.locked_status
        },         

        getAlertStatus(state, getters) {
            return state.alert_status
        },            

        getAlert(state, getters) {
            return state.alert
        },        

        getStorePopupStatus(state, getters) {
            return state.store_popup_status
        },            

        getStorePopup(state, getters) {
            return state.store_popup
        },            

        getCartPopupStatus(state, getters) {
            return state.cart_popup_status
        },            

        getCartPopup(state, getters) {
            return state.cart_popup
        },    	       

    },
    
    actions: {           

        setPopUpStatus( { commit }, data){

            commit( 'setPopUpStatus', data );

        },         

        setPopUp( { commit }, data){

            commit( 'setPopUp', data );

        },          

        setAlertStatus( { commit }, data){

            commit( 'setAlertStatus', data );

        },         

        setAlert( { commit }, data){

            commit( 'setAlert', data );

        },          

        setStorePopupStatus( { commit }, data){

            commit( 'setStorePopupStatus', data );

        },         

        setStorePopup( { commit }, data){

            commit( 'setStorePopup', data );

        },          

        setCartPopupStatus( { commit }, data){

            commit( 'setCartPopupStatus', data );

        },         

        setCartPopup( { commit }, data){

            commit( 'setCartPopup', data );

        },  
        
        lockPopUp( { commit }, data){

            commit( 'lockPopUp', data );

        },                


    },

    mutations: {

	    setPopUpStatus( state, status ){
      		state.popup_status = status;
	    }, 	    

	    setPopUp( state, popup ){
      		state.popup = popup;
	    },         

        setAlertStatus( state, status ){
            state.alert_status = status;
        },      

        setAlert( state, popup ){
            state.alert = popup;
        },         

        setStorePopupStatus( state, status ){
            state.store_popup_status = status;
        },      

        setStorePopup( state, popup ){
            state.store_popup = popup;
        },          

        setCartPopupStatus( state, status ){
            state.cart_popup_status = status;
        },      

        setCartPopup( state, popup ){
            state.cart_popup = popup;
        },         

        lockPopUp( state, locked ){
            state.locked_status = locked;
        },    

    },
}
