<template>
<div>

<div class="right-0 h-20 fixed laptop:hidden text-right p-5 z-20" style="z-index: 2000;" >
	<div class="text-scooter font-medium text-3xl md:text-5xl">
		<i class="nav-btn fas fa-bars p-3 hover:bg-white hover:p-3" :class="{'mobile:bg-white active' : state}" @click="toggle"></i>
	</div>
</div>

<div class="page-overlay laptop:hidden" v-bind:class="{'overflow-visible' : state}" @click="toggleOff()"></div>    

<div @click.stop class="sidebar min-h-full mobile:w-11/12 tablet:w-2/4 desktop:25r left-0 flex flex-col absolute flex-1 z-40 laptop:visible" :class="{visible : state, 'bg-gradient-pink' : user.gender == 'girl', 'bg-gradient-blue' : user.gender == 'boy'}" style="z-index: 1100">

	<div class="m-3 cursor h-12 inline-block leading-10 rounded-full text-1xl text-gray-600 w-12 float-left text-center"
	@click.prevent="route('landing')"
	:class="{
		'bg-scooter' : user.gender == 'boy',
		'hover:bg-scooter' : user.gender == 'boy',
		'active:bg-scooter' : user.gender == 'boy',
		'bg-pink-300' : user.gender == 'girl',
		'hover:bg-pink-300' : user.gender == 'girl',
		'active:bg-pink-300' : user.gender == 'girl'
	}"><i class="fas fa-home" style="line-height: 2.5;color: white"></i></div>

	<div class="rounded-xl pt-10 px-8 text-white w-full mb-4 text-center">
		<div class="relative cursor">
			<img :src="url+user.image" @error="imageLoadError" alt="" class="mobile:h-24 mobile:w-24 profile-status h-32 w-32 rounded-full mr-2 ml-2 overflow-hidden inline-block" :class="{'p-1' : stories > 0}" @click="toggleStories"> 
			<span class="mobile:text-2xl block text-ellipsis  mx-auto text-3xl font-semibold capitalize text-white mt-3 font-Baloo overflow-elipsis w-56">{{user.name}} {{user.family}}</span> 								
		</div>
		<div class="flex justify-between w-full mt-5 px-8">
			<div>
				<span class="mobile:text-2xl block text-3xl font-semibold">{{user.lines}}</span>
				<span class="mobile:text-xl text-1xl">Lines</span>
			</div>							
			<div>
				<span class="mobile:text-2xl block text-3xl font-semibold">{{user.time}}</span>
				<span class="mobile:text-xl text-1xl">Minutes</span>
			</div>
		</div>
	</div>	

	<div class="h-full flex flex-col justify-between flex-1 mt-4 max-h-screen">
		<ul class="lg:mr-10 pt-3 pr-10 sm:pr-5">
			<li class="pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center" @click.prevent="route('dashboard')"
				:class="{'bg-scooter' : pageName == 'dashboard' && user.gender == 'boy', 'bg-pink-300' : pageName == 'dashboard' && user.gender == 'girl',
					'hover:bg-scooter' : user.gender == 'boy',
					'active:bg-scooter' : user.gender == 'boy',
					'hover:bg-pink-300' : user.gender == 'girl',
					'active:bg-pink-300' : user.gender == 'girl',
				}">
				<img src="/assets/svg/icons/dashboard.svg" alt="" class="inline-block image ml-5"> 
				<span class="inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2">
				Dashboard</span>
			</li>						
			<li class="pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer  rounded-r-full flex items-center" @click.prevent="route('bank')"
				:class="{'bg-scooter' : (pageName == 'bank' && user.gender == 'boy' ) || (pageName == 'transfers' && user.gender == 'boy'), 'bg-pink-300' : pageName == 'bank' && user.gender == 'girl',
					'hover:bg-scooter' : user.gender == 'boy',
					'active:bg-scooter' : user.gender == 'boy',
					'hover:bg-pink-300' : user.gender == 'girl',
					'active:bg-pink-300' : user.gender == 'girl',
				}">
				<img src="/assets/svg/icons/bank.svg" alt="" class="inline-block image ml-5"> 
				<span class="inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2">
				Bank</span>
			</li>						
			<li class="pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center" @click.prevent="route('store')"
				:class="{'bg-scooter' : pageName == 'store' && user.gender == 'boy', 'bg-pink-300' : pageName == 'store' && user.gender == 'girl',
					'hover:bg-scooter' : user.gender == 'boy',
					'active:bg-scooter' : user.gender == 'boy',
					'hover:bg-pink-300' : user.gender == 'girl',
					'active:bg-pink-300' : user.gender == 'girl',
				}">
				<img src="/assets/svg/icons/store.svg" alt="" class="inline-block image ml-5"> 
				<span class="inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2">
				Rewards Store</span>
			</li>						
			<li class="pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer  rounded-r-full flex items-center" @click.prevent="route('time')"
				:class="{'bg-scooter' : pageName == 'time' && user.gender == 'boy',
					'hover:bg-scooter' : user.gender == 'boy',
					'active:bg-scooter' : user.gender == 'boy',
					'hover:bg-pink-300' : user.gender == 'girl',
					'active:bg-pink-300' : user.gender == 'girl',
				}">
				<img src="/assets/svg/icons/time.svg" alt="" class="inline-block image ml-5"> 
				<span class="inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2">
				Add Time</span>
			</li>						
			<li class="pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center" @click.prevent="route('lines')"
				:class="{'bg-scooter' : pageName == 'lines' && user.gender == 'boy',
					'hover:bg-scooter' : user.gender == 'boy',
					'active:bg-scooter' : user.gender == 'boy',
					'hover:bg-pink-300' : user.gender == 'girl',
					'active:bg-pink-300' : user.gender == 'girl',
				}">
				<img src="/assets/svg/icons/lines.svg" alt="" class="inline-block image ml-5"> 
				<span class="inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2">
				Add Lines</span>
			</li>			
			<li class="pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center" @click.prevent="resources"
				:class="{'bg-scooter' : pageName == 'resources' && user.gender == 'boy',
					'hover:bg-scooter' : user.gender == 'boy',
					'active:bg-scooter' : user.gender == 'boy',
					'hover:bg-pink-300' : user.gender == 'girl',
					'active:bg-pink-300' : user.gender == 'girl',
				}">
				<img src="/assets/svg/icons/resources.svg" alt="" class="inline-block image ml-5"> 
				<span class="inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2">
				Resources</span>
			</li>
		</ul> 					
		<ul class="lg:mr-10 pt-3 mt-10 mb-5">					
			<li @click="logout()" class="pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center" :class="{
					'hover:bg-scooter' : user.gender == 'boy',
					'active:bg-scooter' : user.gender == 'boy',
					'hover:bg-pink-300' : user.gender == 'girl',
					'active:bg-pink-300' : user.gender == 'girl',
				}">
				<img src="/assets/svg/icons/logout.svg" alt="" class="inline-block image ml-5"> 
				<span class="inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2">
				Logout</span>
			</li>
            <li @click="loginAsParent()" class="pl-8 h-12 sm:h-16 p-3 px-5 w-full mb-4 cursor-pointer rounded-r-full flex items-center" :class="{
					'hover:bg-scooter' : user.gender == 'boy',
					'active:bg-scooter' : user.gender == 'boy',
					'hover:bg-pink-300' : user.gender == 'girl',
					'active:bg-pink-300' : user.gender == 'girl',
				}">
                <img src="/assets/svg/icons/logout.svg" alt="" class="inline-block image ml-5">
                <span class="inline-block ml-6 leading-loose font-medium text-lg sm:text-1xl lg:text-2xl uppercase mt-2">
                    Login as parent
                </span>
            </li>

		</ul>
	</div>
</div>
</div>
</template>
<script>
    import { APP_CONFIG } from '../config.js';
	export default {
		
		data() {

			return {
				url: '/assets/images/avatars/',
				default_image: 'default.png',
				state: false,
			}
		},

		computed: {

			user() {
				return this.$store.getters.getUser;
			},

			status() {
				return this.$store.getters.getSidebarStatus
			},

			pageName(){
				return this.$route.name
			},

			lines() {
				return this.$store.getters.getTotalLines
			},			

			time() {
				return this.$store.getters.getTotalTimes
			},

			stories() {
				return this.$store.getters.getStories.filter(story => story.read === null).length
			},

			settings() {
				return this.$store.getters.getSettings
			}

		},

		methods: {

	  		imageLoadError: function () {
	  			event.target.src = this.url+this.default_image;
	  		},

	  		toggle: function () {
	  			this.state = !this.state

	  			setTimeout( () => {
				  	window.scrollTo(0,0);
				},300);

	  		},

			toggleOff: function () {
	  			this.state = false
				// return this.$store.dispatch('closeSidebar')
			},

			toggleStories: function () {
				this.$store.dispatch('getStories').then(response => {
					this.$store.dispatch('setStoryStatus', 1)
				})
				
			},

			route: function (route) {
				if(route == this.pageName){return this.toggleOff()}
				this.toggleOff()
				this.$router.push({name:route})
			},			

			resources: function () {
				window.open(this.settings.resource_link,'_blank')
			},

			logout: function () {
				this.toggleOff()
				this.$store.dispatch('userLogout')
			},
            loginAsParent: function () {
                  const parentUsername = this.user.parent_username;
                  this.toggleOff();
                  this.$store.dispatch('frontAndBackendLogout');
                  window.location.href = APP_CONFIG.PARENT_URL+'/login?username='+parentUsername;
            }
		},

		beforeCreate() {
			this.$store.dispatch('getStories').then(response => {
				if(response.data.filter(story => story.read === null).length > 0){
					this.toggleStories()
				}
			})

			this.$store.dispatch( 'getTranslations' );
      		this.$store.dispatch( 'getUser' );
      		this.$store.dispatch( 'getSettings' );

		},

	}
</script>