/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	   

    //get list of mesechtos
    getMesechtos: function(){
        return axios.get(APP_CONFIG.API_URL+'/mesechtos'); 
    },    

    //get list of prokim in mesechta
    getMesechta: function(data){
        return axios.get(APP_CONFIG.API_URL+`/mesechta/${data}`); 
    },      

}