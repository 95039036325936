/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {

 	//submit contact us form 
 	submitContactUs: function(data){
        return axios.post(APP_CONFIG.API_URL+'/contact-us', {data}); 
    }, 	
 	

}