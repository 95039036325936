<style scoped="true">

	nav{
	    box-sizing: border-box;
	    display: grid;
	    grid-column-gap: 0.5em;
	    grid-template-columns: repeat(3, 1fr);
	    height: 0.2em;
	    padding: 0 1em;
	    position: absolute;
	    top: 1rem;
	    width: 100%;
	    max-width: 430px;
	    margin: 0 auto;
	}

	@media only screen and (max-width: 640px) {
        nav{
        	grid-column-gap: 0.2em;
        }
    }

	nav > div{
	  background: rgba(0,0,0,0.25);
	  height: 100%;
	}

	nav > div > div{
	  background: white;
	  height: 100%;
	  width: 0%;
	}

	.story-wrapper{
		width: 100%;
	    /*max-width: 680px;*/
	    margin: 0 auto;
	}

	.message{
		position: fixed;
	    bottom: 0;
	    width: 100%;
	    max-width: 430px;
	    margin: 0 auto;
	    background: #1d1d1d80;
	    text-align: center;
	    padding: .5rem 0;
	}
</style>
<template>
	<div class="fixed w-full h-screen" style="background:#41616A;z-index: 2300;">

		<div class="absolute right-0 mr-5 mt-4 cursor-pointer" @click.prevent.stop="close" style="z-index: 2400">
			<span class="text-white text-2xl font-sans">
				<svg id="_x31_36d61d3-7c69-4943-898a-f3edd8a1568c" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M19.8 5.8l-1.6-1.6-6.2 6.2-6.2-6.2-1.6 1.6 6.2 6.2-6.2 6.2 1.6 1.6 6.2-6.2 6.2 6.2 1.6-1.6-6.2-6.2 6.2-6.2z"></path></svg>
			</span>
		</div>

		<div class="story-wrapper h-full relative" id="story">
			<!-- <div class="bg-red-400 h-20 w-full"></div> -->
			<div class="w-full flex align-center justify-center">
				<nav :style="{ gridTemplateColumns: `repeat(${stories.length}, 1fr)` }">
				    <div v-for="(story, index) in stories" >
				      <div></div>
				    </div>
			    </nav>
			    <div class="absolute mt-8 text-center" v-for="(story, index) in stories" v-if="current == index">
			    	<h1>{{story.sender}}</h1>
			    	<h1 style="opacity: 0.6">{{story.created_at | dateFormat}}</h1>
			    </div>
			    <div class="message" v-for="(story, index) in stories" v-if="current == index">
			    	<h1>{{story.data.message}}</h1>
			    </div>
			</div>

			<section class="h-full w-full text-center flex flex-col items-center justify-center"
			  v-for="(story, index) in stories"
			  :key="index"
			  v-if="current == index"
			  >
			  <img v-if="loading" class="max-h-full inline-block" :src="url+story.data.image" alt="" @contextmenu.prevent>
			</section>
		</div>
	</div>
</template>
<script>

	import moment from 'moment'
	import anime from "animejs";
	import * as Hammer from 'hammerjs'

	export default {
		data() {
			return {
			  url: '/assets/images/stories/',
			  current: 0,
			  loading: 0
			}
		},
		computed: {
			status() {
				return this.$store.getters.getStoryStatus
			},
			stories() {
				return this.$store.getters.getStories
			},
			unreadCount(){
				return this.$store.getters.getStories.filter(story => story.read === null).length 
			},
		},
		methods: {
			close: function () {
				return this.$store.dispatch('setStoryStatus', 0)
			},
			mount () {


	    		let timeline = anime.timeline({
			      autoplay: true,
			      duration: 5000,
			      easing: 'linear',
			      loop: true
			    })

			    this.stories.forEach((story, index) => {
			      timeline.add({
			        targets: document.querySelectorAll('nav > div')[index].children[0],
			        width: '100%',

			        loopComplete: (a) => {

						if(index+1 == this.stories.length && a.reversePlayback == false && this.status == 1){
							// console.log('close')
							this.close()
							// this.$store.dispatch('resetStories')
						}
			        },
			        changeBegin: (a) => {
			        	
			        	if(this.unreadCount > 0){
			        		var stories = this.stories
							for(var i in stories){
								if(stories[i].read === null){
									var oldValue = this.current
									this.current = parseInt(i)
								    // animation.seek(animation.duration * (seekProgressEl.value / 100));

								}
							}
			        	}
			        	else{
			          		this.current = index
			        	}

			          if(this.loading){

			          	this.$store.dispatch('markStoryAsRead', story.id)

			          }
			        }
			      })
			    })


			    let hammertime = new Hammer(document.querySelector('#story'))

			    hammertime.on('press', (e) => {
			      timeline.pause()
			    })

			    hammertime.on('pressup', (e) => {
			      timeline.play()
			    })

			    hammertime.on('tap', (e) => {
			      if (e.center.x > window.innerWidth / 2) {
			        if (this.current < this.stories.length - 1) {
			          this.current += 1
			          timeline.pause()
			          timeline.seek(this.current * 5000)
			          timeline.play()
			        }
			      } else {
			        if (this.current > 0) {
			          this.current -= 1
			          timeline.pause()
			          timeline.seek(this.current * 5000)
			          timeline.play()
			        }
			      }
			    })
    		},
		},
		beforeMount() {
			// this.$store.dispatch('getStories').then(response => {
				this.loading = 1
			// }).catch(response => {
			// 		console.log(response)
			// 		this.loading = 0
			// 		this.$store.dispatch('resetStories')
			// 		this.close()
		 //        });
		},

		beforeDestroy() {

				this.loading = 0
				// this.$store.dispatch('resetStories')
				document.documentElement.style.overflow = 'auto'

		},

	    filters: {

			dateFormat: function (value) {
		       	return moment(value).calendar()
			},

		},

		  watch: {
		    status: {
		      	immediate: true,
		        handler(newValue, oldValue) {
		          if(newValue === 1){
		        	document.documentElement.style.overflow = 'hidden'
		          }else if(newValue == 0){
			      	document.documentElement.style.overflow = 'auto'
			      }
		        }
		    },
		    loading: {
		      // immediate: true,
		        deep: true,
		        handler(newValue, oldValue) {
		          if(newValue === 1){
		          	this.mount()
		          }
		        }
		    },
		  }
	}
</script>
