/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {

    //submit lines entry
    submitLineEntry: function(data){
        return axios.post(APP_CONFIG.API_URL+'/lines', {data});
    },

    //get lines data page
    getLinePage: function(data){
        return axios.get(APP_CONFIG.API_URL+'/lines');
    },

}
