<style scoped="true">
	.model-pop-up-container{
	    position: fixed;
	    top: -500px;
	    background: white;
	    z-index: 1500;
	    left: 50%;
	    -webkit-transform: translateX(-50%);
	    transform: translateX(-50%);
	    opacity: 0;
	    pointer-events: none;
	    -webkit-transition: all .3s ease-in-out;
	    transition: all .3s ease-in-out;
    }	
    .model-pop-up-container.model-pop-up-card-visible{
		-webkit-transform: translate(-50%, 38rem);
	    transform: translate(-50%, 38rem);
	    opacity: 1;
	    pointer-events: all;
    }
    .model-pop-up{
    	background: #d6c89775;
    	z-index: 1111;
    }
</style>
<template>

<div>

<div class="model-pop-up" v-bind:class="{'overflow-visible' : popupStatus}" @click="toggleOff()"></div>    

	<div class="model-pop-up-container relative rounded-xl mx-auto w-10/12 md:w-auto rounded-2xl" @click.stop v-bind:class="{'model-pop-up-card-visible' : popupStatus}">
		
		<!-- <popup-message-card></popup-message-card> -->
		<!-- message card -->
		<div class="model-pop-up-card rounded-2xl" v-on:click.stop v-if="popup.type == 'message'">

		  <div class="card card-small px-5 py-2 rounded-2xl">

		    <div class="card-header pb-0 relative">
		    	<div class="absolute">
					<img :src="'/assets/svg/character/'+emoji" class="h-40 w-40 -mt-20 -ml-16" alt="">
		    	</div>
				<h1 class="font-bold text-xl text-r-dark-blue mb-4 ml-10 uppercase">{{popup.header}}</h1>  	
		  	</div>

			<div>
				<div class="my-2 mx-auto px-5 md:px-2 max-w-35">
					<h6 class="mb-0 text-r-dark-blue font-medium text-1xl md:text-1xl text-center">{{popup.data.message}}</h6>
				</div>
			</div>

		  	<div class="card-footer flex justify-end pt-0">    	          	
		    	<div class="text-right">   		
		    		<button class="rounded-xl bg-flush-orange hover:bg-yellow-orange active:bg-flush-orange w-32 md:w-40 h-12 hover:shadow-dense-orange-2 outline-none text-white text-lg md:text-l font-bold cursor"
		    		@click.prevent="ok">OK</button>        		
		    	</div>      	
		    </div>

		  </div>  

		</div>
		<!-- end message card -->	

		<!-- prompt action card -->
		<div class="model-pop-up-card" v-on:click.stop v-if="popup.type == 'action'">

		  <div class="card card-small px-5 py-2">

		    <div class="card-header pb-0 relative">
		    	<div class="absolute">
					<img :src="'/assets/svg/character/'+emoji" class="h-40 w-40 -mt-20 -ml-16" alt="">
		    	</div>
				<h1 class="font-bold text-xl text-r-dark-blue mb-4 ml-10 uppercase">{{popup.header}}</h1>  	
		  	</div>

			<div>
				<div class="my-2 mx-auto px-5 min-w-20">
					<h6 class="mb-0 font-bold text-r-dark-blue text-xl md:text-1xl text-center">{{popup.data.message}}</h6>
				</div>			

				<!-- item added to cart -->
				<div v-if="popup.model == 'added_to_cart'" class="my-2 mb-5 mt-5 flex justify-between">  			    	
			    	
			    	<div class="w-full">
					
						<!-- item -->
						<div class="flex flex-row w-full h-24 mb-4">
							<img :src="url+popup.data.item.image" @error="imageLoadError" class="image h-16 md:h-24 w-16 md:w-24 rounded-xl" alt="">
							<div class="ml-2 md:ml-4 md:py-2 mr-5">
								<h1 class="text-x mb-2 w-20 md:w-40 text-ellipsis text-flush-orange font-semibold ">{{popup.data.item.title}}</h1>
							</div>

							<div class="text-flush-orange w-full text-right md:py-2 flex flex-col md:justify-center">
								<h1 :class="{'line-through' : popup.data.item.sale}" class="text-lg md:text-xl font-medium flex-shrink-0">{{popup.data.item.price}} mbpd</h1>
								<h1 v-if="popup.data.item.sale" class="text-lg md:text-xl font-medium flex-shrink-0">{{popup.data.item.sale}} mbpd</h1>
							</div>
						</div>
						<!-- item -->					

					</div>

				</div>
				<!-- end item added to cart -->
			</div>

		  	<div class="card-footer flex justify-end pt-0" v-if="!popup.without_buttons">    	          	
		    	<div class="text-right" >   		
		    		<button class="rounded-xl bg-flush-orange hover:bg-yellow-orange active:bg-flush-orange w-32 md:w-40 h-12 hover:shadow-dense-orange-2 outline-none text-white text-lg md:text-l font-bold cursor"
		    		@click.prevent="confirm(popup.type, popup.data, popup.model)">OK</button>        		
		    	</div>   	
		    </div>
		  	
		  	<div class="card-footer flex justify-end pt-0 mobile:flex-wrap" v-if="popup.cart_buttons">    	          	
		    	<div class="text-left mobile:mr-0 mobile:mb-4 mr-3" >   		
		    		<button class="rounded-xl border-2 border-flush-orange hover:bg-orange-200 active:bg-flush-orange w-32 md:w-48 h-12 hover:shadow-dense-orange-2 outline-none text-flush-orange hover:text-white text-lg md:text-l font-bold cursor px-2 capitalize"
		    		@click.prevent.stop="cart()">view cart</button>        		
		    	</div> 

		    	<div class="text-right">   		
		    		<button class="rounded-xl bg-flush-orange hover:bg-yellow-orange active:bg-flush-orange w-32 md:w-48 h-12 hover:shadow-dense-orange-2 outline-none text-white text-lg md:text-l font-bold cursor px-2 capitalize leading-tight py-1"
		    		@click.prevent="ok()">continue shopping</button>        		
		    	</div> 	
	    	</div>	

		  </div>  

		</div>
		<!-- end prompt action card -->	

	</div>  

</div>  

</template>
<script>
	
	import PopupMessageCard from './PopupMessageCard.vue'

	export default {

		data() {
			return {
				url: '/assets/images/products/',
				default_image: 'default-product.png',
			}
		},

		computed: {
			popupStatus() {
				return this.$store.getters.getStorePopupStatus;
			},			

			popup() {
				return this.$store.getters.getStorePopup;
			},				

	      	translations() {
	          return this.$store.getters.getTranslations
	        },   

	        uploadProgress() {
	          return this.$store.getters.getProgress
	        },

	        emoji() {
    	        switch(this.popup.emoji){
		          case 'prompt':
		            return 'emoji-2.svg'
		          break;   		          
		          case 'danger':
		            return 'emoji-3.svg'
		          break; 		          
		          case 'caution':
		            return 'emoji-6.svg'
		          break;  		          
		          case 'wonder':
		            return 'emoji-5.svg'
		          break;  		          
		          case 'wink':
		            return 'emoji-1.svg'
		          break;            
		        }
	        	return 'emoji-2.svg'
	        }
		},


		methods: {

			//check if image is broken
	  		imageLoadError: function () {
	  			event.target.src = this.url+this.default_image;
	  		},

			confirm: function (type, data, model) {
				this.$root.$emit('confirm', {type:type,data:data,model:model})
			},

			submit: function (val) {
				this.$root.$emit('submit', val)
			},			

			ok: function () {
	        	this.$store.dispatch( 'setStorePopupStatus', 0 );
			},

			update: function (e) {
				this.$root.$emit('update', e)
			},
			toggleOff: function () {
	        	this.$store.dispatch( 'setStorePopupStatus', 0 );
			},
			cart: function () {
				this.toggleOff()
	        	this.$router.push({name:'cart'})
			}

		},

		components: {
			PopupMessageCard
		},

		watch: {

		    uploadProgress: {
		      immediate: true,
		        deep: true,
		        handler(newValue, oldValue) {
		          if(newValue === 100){
		          	this.cancel()
		          }
		        }
		    },
		     
		},
	}
</script>