import SettingsAPI from '../api/settings.js';

export const settings = {

    state: {
        settings: {chazara_amount:0,per_page:6,products_per_page:25,season:{},live_banner:{},resource_link:''},
        settings_loading: 0,
        translations: {sidebar:{},actions:{},msgs:{},user_info:{genders:{}}},
        grades: [],
    },

    getters: {

        getTranslations(state) {
            return state.translations
        },

        getSettingsLoadingStatus(state) {
            return state.settings_loading
        },

        getSettings(state) {
            return state.settings
        },

        getLiveBanner(state) {
            return state.settings.live_banner
        }

    },

    actions: {


        //get translations
        getTranslations({commit}) {

            commit( 'setSettingsLoadStatus', 1 );

            return new Promise((resolve, reject) => {

             SettingsAPI.getTranslations()
             .then( function( response ){
                 commit( 'setTranslations', response.data )
                 commit( 'setSettingsLoadStatus', 2 );
                 resolve(response.data)
             })
             .catch( function(){
                 commit( 'setSettingsLoadStatus', 3 );
                 reject(error);
             });


            })

        },

        //get
        getSettings({commit}) {

            commit( 'setSettingsLoadStatus', 1 );

            return new Promise((resolve, reject) => {

             SettingsAPI.getSettings()
             .then( function( response ){
                 commit( 'setSettings', response.data )
                 commit( 'setSettingsLoadStatus', 2 );
                 resolve(response.data)
             })
             .catch( function(){
                 commit( 'setSettingsLoadStatus', 3 );
                 reject(error);
             });


            })

        },

    },

    mutations: {

        setTranslations( state, translations ){
            state.translations = translations;
        },

        setSettings( state, settings ){
            state.settings = settings;
        },

        setSettingsLoadStatus( state, status ){
            state.settings_loading = status;
        },


    },
}
