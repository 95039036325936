<template>

	<div class="h-screen-40 w-full flex flex-col px-5">

		<charachter></charachter>

		<div class="px-5 pb-3 md:p-10 md:pb-6 mx-auto">

			<div class="my-3 pb-10 md:w-22r text-center mt-8 bg-white rounded-2xl">
				<div class="w-full h-40 bg-gradient-blue-270 mb-10 rounded-t-2xl inline-block text-center">
					<img src="/assets/logo/logo-white.svg" class="w-32 h-32 my-4 py-2 inline-block" alt="" @click="route('landing')">
				</div>
				<div class="w-full mb-10 mt-5 px-10">
						<input class="w-full h-14 text-base md:text-1xl text-r-dark-blue px-4 rounded-full bg-white border-r-dark-grey focus:shadow-md border-2 md:border shadow-sm outline-none mb-5"
						:class="{'border-danger' : error && username == ''}"
						v-model="username"
						type="text" placeholder="username">

						<input class="w-full h-14 text-base md:text-1xl text-r-dark-blue px-4 rounded-full bg-white  focus:shadow-md border-2 md:border shadow-sm outline-none border-r-dark-grey"
						@keyup.enter="login()"
						:class="{'border-danger' : error && password == ''}"
						v-model="password"
						type="password" placeholder="password">

						<button class="w-full md:w-11/12 mt-10 rounded-2xl btn-bg-gradient-blue-270 hover:bg-r-lighter-blue active:bg-r-darker-blue h-14 hover:shadow-dense-blue-2 outline-none text-white text-lg md:text-1xl font-semibold cursor gradient-button btn-bg-gradient-blue-270"
						@click.prevent.stop="login()">Login</button>
				</div>

				<!-- <p class="text-sm text-r-dark-blue text-center my-8">Forgot password?</p> -->

			</div>
		</div>
    </div>

</template>

<script>

  	import charachter from '../components/CharacterMain.vue'

	export default {

		data() {
			return {
				username: '',
				password: '',
				error: null,
			}
		},

		computed: {
			userLoginStatus() {
				return this.$store.getters.getTokenLoadingStatus;
			},
			translations() {
				return this.$store.getters.getTranslations
			},
		},

		components: {
			charachter,
		},

		methods: {

			login: function (e) {

				if(this.username == '' || this.password == ''){
					var msg = this.translations.msgs.missing_info
				  	this.$store.dispatch( 'setCharachter', {class:'show',message:msg})
				  	this.$store.dispatch( 'setCharachterStatus', 1)
					return this.error = true
				}
				this.$store.dispatch('userLogin', {username:this.username,password:this.password}).catch(response => {

				  	this.$store.dispatch( 'setCharachter', {class:'show',message:response})
				  	this.$store.dispatch( 'setCharachterStatus', 1)

		        });
			},

			route: function (route) {
				this.$router.push({name:route})
			}


		},

		beforeMount() {
      		this.$store.dispatch( 'getAppData' );
		},

		mounted() {
      		this.$store.dispatch( 'getTranslations' ).then(response => {

				setTimeout( () => {
				  	this.$store.dispatch( 'setCharachterStatus', 1)
				  	var msg = (this.translations.msgs.login_welcome)
					this.$store.dispatch( 'setCharachter', {class:'show',message:msg})
				},300);

	        })

		},

		watch: {

		    userLoginStatus: {
		      // immediate: true,
		        deep: true,
		        handler(newValue, oldValue) {
		          if(newValue === 2){
		          	this.$router.push({name: 'dashboard'})
		          }
		        }
		    },

		},
	}
</script>
