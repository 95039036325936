<template>
	<div class="mobile:mt-20 tablet:mt-20 laptop:pl-23r my-10 px-5 md:px-10" @click="dropdownOff()" style="min-height: calc(100vh - 5rem);">

	<store-header></store-header>

	<div class="w-full">
		<!-- cart title + coupon -->

		<div class="flex justify-between mt-5 mb-6 md:mb-2 flex-wrap">

			<h1 v-if="step == 'review'" class="capitalize font-semibold mobile:text-xl md:text-2xl text-black mt-3 mb-2 md:mb-3 relative">My Cart - {{cartCount}} Items</h1>

			<h1 v-if="step == 'checkout'" class="capitalize font-semibold mobile:text-xl md:text-2xl text-black mt-3 mb-2 md:mb-3 relative">Checkout</h1>
			<!-- promo code -->
			<!-- <input v-if="step == 'review'" type="text" class="w-full md:w-48 h-10 rounded-xl border text-black px-2 border-yellow-orange outline-none"> -->
			<!-- end promo code -->
		</div>

		<div class="w-full flex mobile:flex-col flex-row">

			<!-- items in cart -->
			<div class="mobile:w-full sm:w-2/3 mr-5">

				<div class="full mb-10" v-if="step == 'checkout'">
					<h1 class="capitalize font-semibold mobile:text-lg md:text-1xl text-flush-orange mt-3 mb-2 md:mb-3 relative">Shipping To</h1>
					<p class="font-medium mobile:text-sm md:text-md text-black mt-3 mb-5 md:mb-3 relative capitalize" v-if="shippingAddress">{{shippingAddress.address}}<br>{{shippingAddress.city}}, {{shippingAddress.state}}, {{shippingAddress.zip}}</p>

					<span v-if="isIntl">
						<p class="capitalize font-medium md:text-md mobile:text-sm mt-5 relative text-black">PayPal Address</p>
						<input class="relative h-8 md:h-10  rounded-xl border border-flush-orange block text-flush-orange text-center text-lg md:text-xl h-full leading-loose outline-none" v-model="paypal">
					</span>


					<h1 class="block mt-10 capitalize font-semibold mobile:text-xl md:text-2xl text-black mt-3 mb-2 md:mb-3 relative">My Cart - {{cartCount}} Items</h1>

				</div>

				<div class="w-full">

					<!-- item -->
					<div v-for="(item, index) in cart" class="flex flex-row w-full h-24 mb-4 cursor-pointer">
						<img @click="getProduct(item.id)" :src="url+item.image" @error="imageLoadError" class="image h-16 md:h-24 w-16 md:w-24 rounded-xl" alt="">
						<div class="ml-2 md:ml-4 md:py-2">
							<h1 @click="getProduct(item.id)" class="mb-2 w-20 md:w-56 text-ellipsis text-flush-orange font-semibold ">{{item.title}}</h1>

							<!-- qty dropdown -->
							<div class="relative h-8 md:h-10 w-16 rounded-xl border border-flush-orange" @click.stop="setDropdown('qtyDropdown-'+item.id)">
								<span class="block text-flush-orange text-center text-lg md:text-xl h-full leading-loose">
									<span class="inline-block mr-2" style="line-height: 2.2">{{item.qty}}</span>
									<i class="-mt-1 arrow border border-flush-orange"
									:class="{'down' : dropdown != 'qtyDropdown-'+item.id,'up' : dropdown == 'qtyDropdown-'+item.id}">
									</i>
								</span>
								<div class="w-full bg-white rounded-xl absolute dropdown shadow-sm border overflow-hidden z-20"
								:class="{ show: dropdown == 'qtyDropdown-'+item.id }">
									<ul class="max-h-25 overflow-scroll overflow-x-hidden text-left">
										<li v-for="(qty, index) in qty" class="px-2 hover:bg-gray-300 capitalize font-medium text-black">
											<span class="block text-base pt-2 h-8" @click.stop="updateQty(item, qty)">{{qty}}</span>
										</li>
									</ul>
								</div>
							</div>
							<!-- end qty dropdown -->

						</div>
						<div v-if="!item.sale" class="text-flush-orange w-full text-right md:py-2 flex flex-col md:justify-center">
							<h1 class="flex-shrink-0 hidden md:block">${{item.price}} MBPD each</h1>
							<h1 class="text-lg md:text-xl font-medium flex-shrink-0">${{item.total}} <span class="uppercase">MBPD</span>
							</h1>
						</div>
						<div v-if="item.sale" class="text-flush-orange w-full text-right md:py-2 flex flex-col md:justify-center">
							<h1 class="flex-shrink-0 hidden md:block">${{item.sale}} MBPD each</h1>
							<h1 class="text-lg md:text-xl font-medium flex-shrink-0">${{item.sale_total}} <span class="uppercase">MBPD</span>
							</h1>
						</div>
					</div>
					<!-- item -->

				</div>
			</div>
			<!-- end items in cart -->

			<!-- cart details -->
			<div class="mobile:w-full sm:w-1/3 flex-shrink-0 lg:ml-5">
				<div class="w-full h-auto border-2 mb-3 rounded-xl">

					<div class="px-4 md:px-8 py-4 md:py-8">
						<div class="flex flex-wrap justify-between mb-3">
							<h1 class="flex-shrink-0 text-xl md:text-2xl font-bold text-flush-orange uppercase">cart subtotal</h1>
							<h1 class="flex-shrink-0 text-md md:text-xl font-medium text-flush-orange uppercase">${{subtotal}} <span class="uppercase">MBPD</span>
							</h1>
						</div>
						<div v-if="saleInCart" class="flex flex-wrap justify-between mb-3">
							<h1 class="flex-shrink-0 text-xl md:text-2xl font-bold text-flush-orange uppercase">discount</h1>
							<h1 class="flex-shrink-0 text-md md:text-xl font-medium text-flush-orange uppercase">-${{discount}} <span class="uppercase">MBPD</span>
							</h1>
						</div>
						<hr>
						<div class="flex flex-wrap justify-between mt-3">
							<h1 class="flex-shrink-0 text-xl md:text-2xl font-bold text-flush-orange uppercase">total</h1>
							<h1 class="flex-shrink-0 text-md md:text-xl font-medium text-flush-orange uppercase">${{total}} <span class="uppercase">MBPD</span>
							</h1>
						</div>

						<button class="w-full h-12 md:h-14 rounded-xl font-medium uppercase pt-2 outline-none text-xl md:text-2xl gradient-button btn-bg-gradient-flush-orange mt-8" @click.stop="checkout()" v-if="step == 'review'">checkout</button>

						<button class="w-full h-12 md:h-14 rounded-xl font-medium uppercase pt-2 outline-none text-xl md:text-2xl gradient-button btn-bg-gradient-flush-orange mt-8" @click.stop="placeOrder()" v-if="step == 'checkout'">Place Order</button>

					</div>


				</div>
			</div>
			<!-- end cart details -->

		</div>
	</div>



	</div>
</template>
<script>

	import character from '../components/Character.vue'
	import StoreHeader from '../components/StoreHeader.vue'

	export default {
		data() {
			return {

				icon: 'default.png',
				url: '/assets/images/products/',
				default_image: 'default-product.png',
				dropdown: false,
				qty: [0,1,2,3,4,5],
				paypal: '',
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},

			logo: function () {
				return this.$store.getters.getLogos.main_logo;
			},

			translations() {
				return this.$store.getters.getTranslations
			},

			user() {
				return this.$store.getters.getUser
			},

			cart() {
				return this.$store.getters.getCart
			},

			cartCount() {
				return this.cart.length
			},

			subtotal() {
				var cart = this.$store.getters.getCart

				var subtotal = 0

				for(var i in cart){
					subtotal += cart[i].total
				}

				return subtotal
			},

			saleInCart(){

				var cart = this.$store.getters.getCart

				for(var i in cart){
					if(cart[i].sale){
						return true
					}
				}

				return false
			},

			discount() {

				var cart = this.$store.getters.getCart

				var subtotal = 0

				for(var s in cart){
					if(cart[s].sale){
						subtotal += cart[s].sale_total
					}else{
						subtotal += cart[s].total
					}
				}

				var discount_total = Math.abs(this.subtotal - subtotal)

				var discounted_total_cart = this.subtotal-discount_total

				return this.subtotal-discounted_total_cart

			},

			// discount_total() {
			// 	var cart = this.$store.getters.getCart

			// 	var subtotal = 0

			// 	for(var i in cart){
			// 		subtotal += cart[i].sale_total
			// 	}

			// 	return subtotal - this.subtotal
			// },

			total() {
				if(this.saleInCart){
					return this.subtotal - this.discount
				}
				return this.subtotal
			},

			step() {
				if(this.$route.query.checkout == 'checkout'){
					return 'checkout'
				}
				return 'review'
			},

			shippingAddress() {
				if(this.$store.getters.getUserShippingAddress.address == null){
					return false
				}
				return this.$store.getters.getUserShippingAddress
			},

			isIntl() {

				var cart = this.$store.getters.getCart

				for(var i in cart){
					if(cart[i].intl){
						return true
					}
				}

				return false
			}

		},

		methods: {

			//check if image is broken
	  		imageLoadError: function () {
	  			event.target.src = this.url+this.default_image;
	  		},

			route: function (route) {
				this.$router.push({name:route})
			},


			getProduct: function (id) {
				this.$router.push({name:'product', params: {id:id}})
			},

			// toggle dropdown
			setDropdown: function (dropdown) {
				if(this.dropdown == dropdown){
					return this.dropdownOff()
				}
				this.dropdown = dropdown
			},

			// toggle off
			dropdownOff: function () {
				this.dropdown = false
			},

			// update item qty or remove item
			updateQty: function (item, qty) {
				var item = item
				item.qty = qty
				if(qty == 0){
					return this.$store.dispatch('removeItemFromCard', item.id)
					this.dropdownOff()
				}
				this.$store.dispatch('updateItemQty', item)
				this.dropdownOff()
			},

			cartEmpty: function () {

				this.$store.dispatch( 'getTranslations' ).then(response => {

					this.$store.dispatch( 'setStorePopup',
					{
						header:this.translations.error,
						data:{
							message:this.translations.msgs.empty_cart,
						},
						model: 'defualt',
						type:'message',
						emoji:'danger',

					});
					this.$store.dispatch('setStorePopupStatus', 1);

				})

			},

			checkout: function ()
			{


				if(this.$route.query.checkout == 'checkout' || this.cartCount == 0){return}

				this.getShipping()

				return this.$router.push({name:'cart', query:{checkout:'checkout'}})
			},

			getShipping: function () {

				this.$store.dispatch('getShippingAddress').then(response => {

					this.$store.dispatch( 'setStorePopup',
					{
						header:response.header,
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'prompt',

					});
					this.$store.dispatch('setStorePopupStatus', 1);

		        }).catch(response => {

					this.$store.dispatch( 'setStorePopup',
					{
						header:response.header,
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'danger',

					});
					this.$store.dispatch('setStorePopupStatus', 1);

		        });
			},

			// submit order
			placeOrder: function ()
			{
				this.$store.dispatch('placeOrder', {intl_order: this.isIntl, paypal: this.paypal}).then(response => {
					this.$store.dispatch('emptyCart')
      				this.$store.dispatch( 'getUser' );
					this.$router.push({name:'store'})
					this.$store.dispatch('setStorePopup', {
						header:response.header,
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'danger',
						without_buttons:true,
						cart_buttons:true,
						error:false,
						emoji:'wink',
					})
					return this.$store.dispatch('setStorePopupStatus', 1);
		        }).catch(response => {

					this.$store.dispatch( 'setStorePopup',
					{
						header:response.header,
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'danger',

					});
					this.$store.dispatch('setStorePopupStatus', 1);

		        });
			}

		},

		components: {
			character,
			StoreHeader,
		},

		filters: {

		},

		beforeMount() {
      		this.$store.dispatch( 'getAppData' );
      		this.$store.dispatch( 'getTranslations' );
		},

		created() {
			if(this.step == 'checkout'){
				this.getShipping()
      		}
      		if(this.cartCount == 0){
      			this.cartEmpty()
      		}
		},


		watch: {

		    cartCount: {
		      	// immediate: true,
		        deep: true,
		        handler(newValue, oldValue) {
		          if(newValue === 0){
					this.$store.dispatch('setStorePopup', {
						header:'Continue Shopping?',
						data:{message:this.translations.msgs.empty_cart},
						model:'empty_cart',
						type:'message',
						without_buttons:true,
						cart_buttons:true,
						error:false,
						emoji:'wonder',
					})
					return this.$store.dispatch('setStorePopupStatus', 1);
		          }
		        }
		    },

		},

	}
</script>
