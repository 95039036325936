import ContactAPI from '../api/contact.js';

export const contact = {

    state: {

       contacts: {},

       contact_loading: 0,

    },

    getters: {

    	getContactUs(state) {
            return state.contacts;
        },


        getContactLoadingStatus(state) {
            return state.contact_loading;
        },

    },

    actions: {

        //submit contact form
        submitContactUs( { commit }, data){

            commit( 'setContactLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                ContactAPI.submitContactUs(data)
                .then( function( response ){
                    commit( 'setContactLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setContactLoadStatus', 3 );
                    reject(error.response.data)
                });

            })

        },




    },

    mutations: {

	    setContactUs( state, contacts ){
            state.contacts = contacts;
	    },

	    setContactLoadStatus( state, status ){
      		state.contact_loading = status;
	    },



    },
}
