/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	   

    //get list of lines in mishna
    getMishna: function(data){
        return axios.get(APP_CONFIG.API_URL+`/mishna/${data}`); 
    },      

}