import MesechtosAPI from '../api/mesechtos.js';

export const mesechtos = {

    state: {

       mesechtos: {},
       mesechtos_loading: 0,

    },

    getters: {

    	getMesechtos(state) {
            return state.mesechtos;
        },

        getMesechtosLoadingStatus(state) {
            return state.mesechtos_loading;
        },

    },

    actions: {

        //get mesechtos paginated
        getMesechtos( { commit }){

            commit( 'setMesechtosLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                MesechtosAPI.getMesechtos()
                .then( function( response ){
                    commit( 'setMesechtos', response.data );
                    commit( 'setMesechtosLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setMesechtosLoadStatus', 3 );
                    reject(error.response.data)
                });

            })


        },


    },

    mutations: {

	    setMesechtos( state, mesechtos ){
            state.mesechtos = mesechtos;
	    },

	    setMesechtosLoadStatus( state, status ){
      		state.mesechtos_loading = status;
	    },


    },
}
