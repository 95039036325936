import CartAPI from '../api/cart.js';

export const cart = {

    state: {

        cart: JSON.parse(localStorage.getItem('cart')) || [],
    	cart_loading: 0,

    },

    getters: {

    	getCart(state) {
            return state.cart;
        },

        getCartLoadingStatus(state) {
            return state.cart_loading;
        },

    },

    actions: {

        //add item to cart
        addToCart( { commit, dispatch }, data ){

            return new Promise((resolve, reject) => {

	            commit( 'setCartLoadStatus', 1 );
	            commit( 'setCart', data )

	            setTimeout( () => {
  		        	commit( 'setCartLoadStatus', 2 );
                	resolve(data)
				},300);


            })



        },

        // update qty
        updateItemQty({commit, dispatch}, data) {

           commit( 'setCartLoadStatus', 1 );

           return new Promise((resolve, reject) => {

	            commit( 'updateItemQty', data );

	            setTimeout( () => {
  		        	commit( 'setCartLoadStatus', 2 );
                	resolve(data)
				},300);

            })

        },

        //remove item from cart
        removeItemFromCard( { commit, dispatch }, id ){

            commit( 'setCartLoadStatus', 1 );

        	return new Promise((resolve, reject) => {

            	commit( 'setRemoveItemFromCart', id )

	            setTimeout( () => {
        			commit( 'setCartLoadStatus', 2 );
                	resolve(id)
				},300);

            })

        },

        emptyCart({commit}){
           commit( 'emptyCart');
        },

        //place order
        placeOrder( { commit, dispatch, state} , data){

            commit( 'setCartLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                CartAPI.submitOrder(state.cart, data)
                .then( function( response ){
                    commit( 'setCartLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setCartLoadStatus', 3 );
                    reject(error.response.data);
                });

            })

        },


    },

    mutations: {

	    setCart( state, cart ){
	    	for(var i in state.cart) {
	    		if(state.cart[i].id == cart.id){
	    			state.cart[i].qty += cart.qty
	    			state.cart[i].total += cart.price
                    state.cart[i].sale_total += cart.sale
	    			localStorage.setItem('cart', JSON.stringify(state.cart));
	    			return
	    		}
	    	}
	    	cart.total = cart.price * cart.qty
            cart.sale_total = cart.sale * cart.qty
            state.cart.push(cart)
            localStorage.setItem('cart', JSON.stringify(state.cart));
	    },

	    updateItemQty( state, cart ){
	    	for(var i in state.cart) {
	    		if(state.cart[i].id == cart.id){
	    			state.cart[i].qty = cart.qty
	    			state.cart[i].total = cart.price * cart.qty
                    state.cart[i].sale_total = cart.sale * cart.qty
	    			localStorage.setItem('cart', JSON.stringify(state.cart));
	    			return
	    		}
	    	}
	    },

	    setRemoveItemFromCart( state, id ){
	    	for(var i in state.cart) {
	    		if(state.cart[i].id == id){
	    			state.cart.splice(i, 1)
            		localStorage.setItem('cart', JSON.stringify(state.cart));
	    			return
	    		}
	    	}
            localStorage.setItem('cart', JSON.stringify(state.cart));
	    },

	    emptyCart( state, ){
      		state.cart = [];
            localStorage.setItem('cart', JSON.stringify(state.cart));
	    },

	    setCartLoadStatus( state, status ){
      		state.cart_loading = status;
	    },


    },
}
