/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	          

    //get stories for user
    getStories: function(data){
        return axios.get(APP_CONFIG.API_URL+`/stories`); 
    },      

    //mark story as read
    markStoryAsRead: function(data){
        return axios.post(APP_CONFIG.API_URL+`/story/${data}`); 
    },      

}