import MishnayosApi from '../api/mishnayos.js';

export const mishna = {

    state: {
        mishna: {
        	lines: {}, 
        },
    	mishna_loading: 0,
    },

    getters: {

    	getMishna(state) {
            return state.mishna;
        },    	

        getMishnaLoadingStatus(state) {
            return state.mishna_loading;
        },        

    },

    actions: {   

        //get mishna with lines
        getMishna({commit, dispatch}, data) {

             commit( 'setMishnaLoadStatus', 1 );
             
             return new Promise((resolve, reject) => {
	
	             MishnayosApi.getMishna( data )
	             .then( function( response ){
	                 commit( 'setMishna', response.data )
	                 commit( 'setMishnaLoadStatus', 2 );
	                 resolve(response.data)

	             })
	             .catch( function(error){
	                 commit( 'setMishnaLoadStatus', 3 );
	                 reject(error.response.data)
	             });

             })


        }, 

        resetMishna({commit}) {
             commit( 'resetMishna' );
        },       

    },

    mutations: {

	    setMishnaLoadStatus( state, status ){
      		state.mishna_loading = status;
	    },	    

	    setMishna( state, mishna ){
      		state.mishna = mishna;
	    },		    

	    resetMishna( state, mishna ){
      		state.mishna = {lines: {}};
	    },	    	        


    },

}