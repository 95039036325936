<template>
	<footer class="main" style="cursor: pointer;">
	<div class="wrapper">
	  <div class="footer-honor">
	    <p>{{sponsors}}</p>
	  </div>
	</div>
	</footer> 
</template>
<script>
	export default {
		computed: {
			sponsors() {
				return this.$store.getters.getSponsors.names
			}
		},
		methods: {
			donate: function () {
				this.$router.push({name:'donate'})
			}
		},
		beforeMount(){
			this.$store.dispatch('getTranslations')
			this.$store.dispatch('getSponsors')
		}
	}
</script>