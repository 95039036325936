<template>
	<div class="mobile:mt-20 laptop:pl-25r my-10 px-10" style="min-height: 917px;">
		
		<div class="flex flex-col lg:flex-row">				

			<div class="laptop:ml-3 laptop:mr-3 laptop:w-1/3 mb-10 mobile:w-full">
				
				<div class="relative">

					<div class="rounded-2xl bg-white pt-8 pb-5 px-8 text-white w-full mb-10 inline-block">
						<div class="w-full">
							<div class="relative text-center w-full block h-12">
								
								<div class="bg-gray-300 cursor h-12 hover:bg-gray-400 inline-block leading-10 rounded-full text-1xl text-gray-600 w-12 float-left" @click="getTimeSelectedDate('prev')">
									<i class="fas fa-arrow-left" style="line-height: 2.5;"></i>
								</div>							

								<div class="bg-gray-300 cursor h-12 hover:bg-gray-400 inline-block leading-10 rounded-full text-1xl text-gray-600 w-12 float-right" v-show="dateSelectedNotToday" 
								@click="getTimeSelectedDate('next')">
									<i class="fas fa-arrow-right" style="line-height: 2.5;"></i>
								</div>

							</div>
						</div>
						<div class="relative mt-8 mb-5 text-center">

							<h1 class=" text-3xl font-semibold capitalize text-r-dark-blue mb-3">{{selectedDateTimes.date | dateFormat}}</h1> 							
	
							<div class="w-full mx-auto">	
								
								<div class="relative text-center">
									<img src="/assets/svg/circle.svg" class="h-56 w-56 inline-block" alt="">
									<div class="centered">
										<span class="block text-6xl h-16 font-semibold capitalize text-r-dark-blue wide:text-5xl wide:h-12">{{selectedDateTimes.amount}}</span>
										<span class="block text-3xl wide:text-2xl font-semibold capitalize text-r-dark-blue">Minutes</span>
									</div>
							
								</div>

							</div>	
						</div>

					</div>					




					<div class="rounded-2xl bg-white p-8 text-white">
						<div class="relative cursor">
							<div class="absolute text-center h-12 w-12 bg-gray-300 rounded-full pt-1 px-2">
								<img src="/assets/svg/icons/lines-gray.svg" class="h-10 w-10">
							</div>
						</div>
						<div class="relative mt-20">
							<h1 class=" text-3xl font-semibold capitalize text-r-dark-blue mb-5">lines untill chazara</h1>

							<div class="relative w-full rounded-full h-12 bg-gray-200 overflow-hidden">
								<div class="absolute h-12 w-100 rounded-l-full" 
								:class="{'bg-gradient-blue-80' : user.gender == 'boy', 'bg-gradient-pink-80' : user.gender == 'girl'}" 
								:style="{'width': chazaraPercent + '%'}"></div>
								<div class="w-full flex justify-between px-10 text-1xl font-medium leading-10 pt-2">
									<span class="z-10"> {{settings.chazara_amount - user.remaining_till_chazara}} </span>
									<span class="z-10 text-r-dark-blue">{{user.remaining_till_chazara}}</span>									
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>			

			<div class="desktop:-mr-1 desktop:w-7/12 laptop:ml-10 laptop:mr-0 mobile:w-full">
				<div class="relative">
					<div class="rounded-t-2xl bg-white h-full p-6 mobile:flex mobile:flex-wrap mobile:justify-center md:p-10 text-white flex justify-between pb-10">
						<div class="side-speech-bubble mobile:min-w-20 bg-coral relative rounded-1xl w-3/6 inline-block h-fit">
							<p class="p-4 text-center text-2xl font-medium" v-if="user.gender == 'boy'">{{user.name}}!
							{{translations.msgs.dashboard_welcome}}</p>							
							<p class="p-4 text-center text-2xl font-medium" v-if="user.gender == 'girl'">{{user.name}}!
							{{translations.msgs.dashboard_welcome_girls}}</p>
						</div>
						<div class="h-21r tablet:inline-block laptop:inline-block" v-if="user.gender == 'boy'">
							<div v-if="user.gender == 'boy'" v-html="character()" class="flip-horizontal image-svg h-full"></div>
						</div>						
						<div class="tablet:inline-block laptop:inline-block" style="25.563rem;" v-if="user.gender == 'girl'" >
							<div v-html="girlCharacter()" class="flip-horizontal image-svg h-full"></div>
						</div>
					</div>
					<div class="rounded-b-2xl bg-white p-6 md:p-10">
						<h1 class="text-ellipsis inline-block text-3xl font-bold capitalize text-r-dark-blue mb-3">
							Welcome, {{user.name}}
						</h1>
						<div class="flex justify-between flex-wrap">
							<div class="flex items-center mt-5">
								<span class="h-20 w-20 wide:w-16 wide:h-16  rounded-full mr-3 block bg-scooter text-white font-bold flex justify-center items-center text-3xl font-semibold pt-1">{{user.mbpd}}</span>
								<span class="text-scooter inline-block text-3xl font-medium uppercase pt-1">MBPD</span>
							</div>							
							<div class="flex items-center mt-5">
								<span class="h-20 w-20 wide:w-16 wide:h-16  rounded-full mr-3 block bg-coral text-white font-bold flex justify-center items-center text-3xl font-semibold pt-1">{{user.orders}}</span>
								<span class="text-coral inline-block text-3xl font-medium uppercase pt-1">Orders</span>
							</div>
						</div>
					</div>
				</div>
			</div>			

		</div>		


	</div>
</template>
<script>
	
	import Hebcal from 'hebcal';
	import character from '../components/Character.vue'
	import moment from 'moment'

	export default {
		data() {
			return {
		
				icon: 'default.png',
				url: '/assets/images/avatars/',
				default_image: 'default.png',
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			logo: function () {
				return this.$store.getters.getLogos.main_logo;
			},
			
			translations() {
				return this.$store.getters.getTranslations
			},

			selectedDateTimes() {
				return this.$store.getters.getSelectedDateTimes
			},

			user() {
				return this.$store.getters.getUser
			},			

			settings() {
				return this.$store.getters.getSettings
			},

			chazaraPercent() {
				return 100 - parseInt( Math.round( ( this.user.remaining_till_chazara / this.settings.chazara_amount ) * 100 ) )
			},

			dateSelectedNotToday() {
				if(moment(this.selectedDateTimes.date, 'YYYY, MM, DD').format("YYYY, MM, DD") !== moment().format("YYYY, MM, DD"))
				{
					return true
				}
				return false
			}

		},

		methods: {


			character: function () {
				return require('../../svg/character/char2.svg')
			},			

			girlCharacter: function () {
				return require('../../svg/character/girl_char2.svg')
			},

			route: function (route) {
				this.$router.push({name:route})
			},

			getTimeSelectedDate: function (direction) {

				switch(direction){

		            case 'prev':
						var date = moment(this.selectedDateTimes.date, 'YYYY, MM, DD').subtract(1, 'days').format('YYYY, MM, DD')
		            break;            
		            case 'next':
						var date = moment(this.selectedDateTimes.date, 'YYYY, MM, DD').add(1, 'days').format('YYYY, MM, DD')
		            break;
		          }

      			this.$store.dispatch( 'getTimePage', date);
			}

		},

		components: {
			character,
		},	

		filters: {
			dateFormat: function (value) {
				// return this.selectedDateTimes.date
				var date = moment(value, 'YYYY, MM, DD').format('MMMM DD, YYYY')

		       	return new Hebcal.HDate(new Date(date)).toString('h');
			},
		},

		beforeMount() {
      		this.$store.dispatch( 'getAppData' );
      		this.$store.dispatch( 'getTranslations' );
      		this.$store.dispatch( 'getTimePage' );
      		this.$store.dispatch( 'getUser' );
		},

		mounted() {
			// setTimeout( () => {
			//   	this.$store.dispatch( 'setCharachterStatus', 1)
			//   	var msg = (this.translations.msgs.token_register)
			// 	this.$store.dispatch( 'setCharachter', {class:'show',message:msg})
			// },300);	
		},

	}
</script>