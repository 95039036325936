<template>

  <marquee-text class="bg-dark py-3 " :duration="230" :paused="paused" :class="class_id"
    @mouseover="paused = true"
    @mouseleave="paused = false">
    <span class="mx-3 uppercase" v-for="(live_item, index) in repeat"> {{live_item | filterLive}} <span class="ml-5 mt-1 rounded-full bg-green-500 h-3 w-3 inline-block"></span></span>
  </marquee-text>

</template>
<script>
  
  import MarqueeText from 'vue-marquee-text-component'

	export default {

    data() {
      return {
    
        paused: false,
        class_id: 0
        
      }
    },

    computed: {

      default() {
        return this.$store.getters.getLiveBanner.default_text
      },

      lastTransactions() {
        return this.$store.getters.getLiveBannerTransactions.data
      },

      repeat() {

        var remaining = 20 - this.lastTransactions
        remaining = Math.max(0, remaining)

        if(remaining > 0){
          var data = this.lastTransactions

          for (var i = remaining - 1; i >= 0; i--) {
            data.push( this.default )
          }

          return data 

        }
        
        return this.lastTransactions

      }
    },

    components: {
      MarqueeText
    },

    filters: {

          filterLive: function (value) {
            switch(value.type){
              case 'time':
                return ''+value.username+' - '+value.city+', '+value.state+' '+value.country+' - learned '+value.time+' minutes of MBP today'
              break;        
              case 'lines':
                return ''+value.username+' - '+value.city+', '+value.state+' '+value.country+' - learned '+value.lines+' lines of MBP today'
              break;        
            }
      },

    },


    mounted() {
      this.$store.dispatch('getSettings')
      this.$store.dispatch('getLiveBannerTransactions')

      Echo.channel('mbp-v2')
      .listen('.new-entries', (e) => {
        this.lastTransactions.push(e.data)
        console.log(e.data)
        this.class_id ++
        if(this.lastTransactions.length > 20){
          this.lastTransactions.splice(0,1)
        }
      })
    }
	}
</script>