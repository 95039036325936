import LandingAPI from '../api/landing.js';

export const landing = {

    state: {
        landing: {time:0,lines:0},
    	landing_loading: 0,
    },

    getters: {

    	getLanding(state) {
            return state.landing;
        },         	

        getLandingLoadingStatus(state) {
            return state.landing_loading;
        },

    },
    actions: {   

        //get lanfing page data
        getLandingData({commit, dispatch}, data) {
             
             commit( 'setLandingLoadStatus', 1 );

             LandingAPI.getLandingData( data )
             .then( function( response ){
                 commit( 'setLanding', response.data )
                 commit( 'setLandingLoadStatus', 2 );

             })
             .catch( function(){
                 commit( 'setLandingLoadStatus', 3 );
             });
        },        


    },

    mutations: {

	    setLanding( state, data ){
      		state.landing = data;
	    },

	    setLandingLoadStatus( state, status ){
      		state.landing_loading = status;
	    },     
    
    },
}