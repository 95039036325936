<template>
	<div class="mobile:mt-20 laptop:pl-25r my-10 px-10" style="min-height: 917px;">

		<div class="flex flex-col lg:flex-row ">

			<div class="mobile:w-full mobile:w-full laptop:w-1/3 desktop:ml-10 desktop:mr-10 laptop:ml-3 laptop:mr-3 mb-10">

				<div class="relative">
					<div class="rounded-2xl bg-white pt-8 pb-5 px-8 text-white w-full mb-10 inline-block">
						<div class="absolute text-center h-12 w-12 bg-gray-200 hover:bg-gray-300 rounded-full pt-1 px-2 cursor-pointer"><img src="/assets/svg/icons/settings-gray.svg" class="h-10 w-10" @click.stop="toggleMpbdTagSetter"></div>

						<div class="relative mt-12 mb-3 text-center">

							<h1 v-if="user.mbpd_tag" class="mobile:text-1xl text-2xl font-semibold text-r-dark-blue mb-4 uppercase w-full" >My MBPD tag: ${{user.mbpd_tag}}
								<span class="absolute m-5 lg:m-6">$</span><input class="border border-bottom text-center rounded mobile:h-16 h-20 outline-none " :class="{'border border-red-400' : error == true}" style="max-width: 350px;" type="text" placeholder="mbpd tag" v-model="mbpd_tag">

							</h1>
							<span v-else class="block mt-20 pt-1 mobile:text-lg text-xl text-red-600 mb-4 uppercase w-full">
								{{translations.msgs.set_mbpd_tag}}

							</span>


							<div class="font-semibold capitalize text-r-dark-blue text-5xl">

								<!-- <div class="border border-bottom w-full">{{amount}}</div> -->
								<div class="text-center w-full text-ellipsis h-16">
									{{amount}}
								</div>

							</div>

							<Keypad @keyed="setAmount" ref="keypad"></Keypad>

							<button class="mt-5 w-full tablet:w-1/2 mobile:h-12 h-16 rounded-full font-medium uppercase mobile:pt-2 pt-3 outline-none mobile:text-xl text-2xl gradient-button"
							:class="{'btn-bg-gradient-blue-270' : user.gender == 'boy','btn-bg-gradient-pink-270' : user.gender == 'girl' }" @click.stop="transfer">send</button>




						</div>
					</div>



				</div>
			</div>

			<div class="mobile:w-full desktop:w-1/2 laptop:ml-10 laptop:mr-0 desktop:mr-20">
				<div class="relative">

					<div class="rounded-2xl bg-white h-full p-8 md:px-10 text-white mb-10">

						<div class="relative cursor">
							<div class="absolute text-center h-12 w-12 bg-gray-200 rounded-full pt-1 px-2">
								<img src="/assets/svg/icons/transactions-gray.svg" class="h-10 w-10">
							</div>
						</div>

						<div class="relative mt-20 mobile:text-center">
							<h1 class=" text-3xl font-semibold uppercase text-r-dark-blue mb-5">my transfers summary</h1>

							<div class="flex justify-between flex-wrap">
								<div class="flex items-center mt-4">
									<span class="mobile:h-12 mobile:w-12 mobile:text-xl  h-20 w-20  rounded-full mr-3 block bg-scooter text-white font-bold flex justify-center items-center text-3xl font-semibold pt-1">{{balanceData.received}}</span>
									<span class="mobile:text-2xl text-scooter inline-block text-3xl font-medium uppercase pt-1">received</span>
								</div>
								<div class="flex items-center mt-4">
									<span class="mobile:h-12 mobile:w-12 mobile:text-xl h-20 w-20  rounded-full mr-3 block bg-coral text-white font-bold flex justify-center items-center text-3xl font-semibold pt-1">{{balanceData.sent}}</span>
									<span class="mobile:text-2xl text-coral inline-block text-3xl font-medium uppercase pt-1">sent</span>
								</div>
							</div>
						</div>

						<div class="block h-full mt-16">

							<div class="flex justify-between items-center mb-5" v-for="(transfer, index) in transfers.data">
								<div>
									<span class="overflow-ellipsis mobile:w-32 mobile:h-6 mobile:text-base text-r-dark-blue block text-3xl h-8 font-semibold">
										<span>{{transfer.recipient_tag}}</span>
									{{transfer.type, translations | titleFilter}}</span>
									<span class="mobile:text-base text-r-dark-blue text-2xl">{{transfer.date | dateFormat}}</span>
								</div>
								<span class="flex-shrink-0 mobile:font-medium mobile:text-2xl mobile:h-10 mobile:w-10 h-20 w-20  rounded-full block text-white font-bold flex justify-center items-center text-3xl font-semibold pt-1"
								:class="[transfer.negative ? 'bg-coral' : '', !transfer.negative ? 'bg-scooter' : '']">{{transfer.amount}}</span>
							</div>

							<div class="w-full flex overflow-x-scroll">
				   				<vue-pagination :pagination="transfers.meta"
		               			@paginate="getPage()"
		               			:offset="2">
			            		</vue-pagination>
							</div>
						</div>

					</div>


				</div>
			</div>

		</div>


	</div>
</template>
<script>

	import character from '../components/Character.vue'
    import VuePagination from '../components/VuePagination.vue';
    import Keypad from '../components/Keypad.vue';
	import Hebcal from 'hebcal';
	import moment from 'moment'

	export default {
		data() {
			return {

				icon: 'default.png',
				url: '/assets/images/avatars/',
				default_image: 'default.png',
				amount: 0,
				error: false,
				mbpd_tag: '',
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},

			logo: function () {
				return this.$store.getters.getLogos.main_logo;
			},

			translations() {
				return this.$store.getters.getTranslations
			},

			settings() {
				return this.$store.getters.getSettings
			},

			transfers() {
				return this.$store.getters.getTransfers
			},

			balanceData() {
				return this.$store.getters.getTransfersData
			},

			user() {
				return this.$store.getters.getUser
			}

		},

		methods: {


			character: function () {
				return require('../../svg/character/char2.svg')
			},

			route: function (route) {
				this.$router.push({name:route})
			},

			setAmount: function (val) {

				this.amount = val

			},

			// transfer funds
			transfer: function () {
				if(this.amount == 0 || this.mbpd_tag == '' || this.mbpd_tag == null){return this.error = true}

				this.$store.dispatch('transfer', {mbpd_tag:this.mbpd_tag, amount:this.amount}).then(response => {

					this.amount = 0
					this.$refs.keypad.amount = 0
					this.mbpd_tag = ''
					this.error = false
					this.$store.dispatch( 'getTransfers', {PerPage:this.settings.per_page, Page: 1} );

					this.$store.dispatch( 'setPopUp',
					{
						header:response.header,
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'wink',
						error:false,
					});
					this.$store.dispatch('setPopUpStatus', 1);


		        }).catch(response => {

					this.$store.dispatch( 'setPopUp',
					{
						header:response.header,
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'danger',

					});
					this.$store.dispatch('setPopUpStatus', 1);

		        });
			},

			// toggle set mbpd tag popup
			toggleMpbdTagSetter: function () {
					this.$store.dispatch( 'setPopUp',
					{
						header:'set my MBPD tag',
						data:{
							message:'Please enter your desired MBPD tag',
							mbpd_tag:''
						},
						model: 'mbpd_tag',
						button_text: 'confirm',
						type:'action',
						emoji:'prompt',
						error:false,
					});
					this.$store.dispatch('setPopUpStatus', 1);
			},

			// set mbpd tag
			setMbpdTag: function (val) {
				if(val == ''){return}
				this.$store.dispatch('setMbpdTag', val.mbpd_tag).then(response => {

					this.$store.dispatch( 'setPopUp',
					{
						header:response.header,
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'wink',
						error:false,
					});
					this.$store.dispatch('setPopUpStatus', 1);


		        }).catch(response => {

					this.$store.dispatch( 'setPopUp',
					{
						header:response.header,
						data:{
							message:response.message,
						},
						model: 'defualt',
						type:'message',
						emoji:'danger',

					});
					this.$store.dispatch('setPopUpStatus', 1);

		        });
			},

			getPage: function () {
				this.$store.dispatch( 'getTransactions', {PerPage:this.settings.per_page, Page: this.transactions.meta.current_page } );
				this.$router.push({name:'bank', params:{page:this.transactions.meta.current_page}})
			}

		},


		filters: {
			dateFormat: function (value) {
		       	return new Hebcal.HDate(new Date(moment(value, 'DD-MM-YYYY').format("YYYY-MM-DD")));
			},
			titleFilter: function (value, trans) {
				switch(value){

		            case 'lines':
						return trans.line_entry_title
		            break;
		            case 'time':
						return trans.time_entry_title
		            break;

		        }
			},
		},

		components: {
			character,
			VuePagination,
			Keypad,
		},



		beforeMount() {
      		this.$store.dispatch( 'getAppData' );
      		this.$store.dispatch( 'getTranslations' );
      		this.$store.dispatch( 'getUser' );
			this.$store.dispatch( 'getTransfers', {PerPage:this.settings.per_page, Page: parseInt(this.$route.params.page)} );
		},

		mounted() {
			this.$root.$on('confirm', (e) => {
				if(e.model == 'mbpd_tag'){
					this.setMbpdTag(e.data)
				}
		    })
			// setTimeout( () => {
			//   	this.$store.dispatch( 'setCharachterStatus', 1)
			//   	var msg = (this.translations.msgs.token_register)
			// 	this.$store.dispatch( 'setCharachter', {class:'show',message:msg})
			// },300);
		},



		beforeDestroy() {
        	this.$root.$off('confirm')
		}

	}
</script>
