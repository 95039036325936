<template>
	
	<div id="store-layout" class="flex-1 relative bg-white font-Baloo" style="flex-grow: 3;" @click="toggleOff()">

		<div class="left-0 h-20 fixed laptop:hidden text-right p-5 z-20" style="z-index: 2000;" @click="route('store')">
			<div class="text-yellow-orange font-medium text-3xl md:text-5xl">
				<img src="/assets/images/logo/main_logo.png" class="image h-10 w-10 mt-3" alt="">
			</div>

		</div>

		<pop-up></pop-up>

		<loader></loader>
		
		<sidebar></sidebar>

		<router-view></router-view>

	</div>
	
</template>
<script>

	import Loader from '../components/Loader.vue';
	import Sidebar from '../components/StoreSidebar.vue';
	import PopUp from '../components/StorePopup.vue';

	export default {
		methods: {
			toggleOff: function () {
				this.$store.dispatch('setCharachterStatus', 0)
				this.$store.dispatch('setCharachter', {class:'', message: ''})
				this.$store.dispatch( 'setStorePopupStatus', 0 );
			},

			route: function (route) {
				this.$router.push({name:route})
			}
		},
		components: {
			Loader,
			Sidebar,
			PopUp,
		}
	}

</script>