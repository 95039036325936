/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {

 	//get transactions paginated
 	getTransactions: function(data){
        return axios.get(APP_CONFIG.API_URL+`/transactions?PerPage=${data.PerPage}&&page=${data.Page}`); 
    }, 	 	             	
 		             	

}