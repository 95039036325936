<template>
	<div class="mobile:mt-20 tablet:mt-20 laptop:pl-23r my-10 px-5 md:px-10" @click="dropdownOff()" style="min-height: calc(100vh - 5rem);">

	<store-header></store-header>
	
	<div class="w-full">
		<!-- product title -->
		<h1 class="capitalize font-semibold mobile:text-1xl md:text-3xl text-black my-3 relative">{{product.title}}</h1>

		<div class="w-full flex mobile:flex-col flex-row">

			<!-- product image -->
			<div class="mobile:w-full sm:w-2/3 lg:w-2/5 mr-8">
				<div class="relative w-full h-64 md:h-75 border-2 mb-3 border-yellow-orange rounded-xl inline-block text-center py-5 flex justify-center items-center">
					<span v-if="product.sale" class="absolute bg-store-light-yellow block font-bold h-10 px-10 py-2 rounded-l-full text-1xl text-orange-500 text-uppercase right-0" style="top: 20px;">sale</span>
					<img :src="url+product.image" @error="imageLoadError" class="h-56 image inline-block rounded-xl w-56 flex-shrink-0" alt="" style="object-fit: cover;">
				</div>
			</div>
			<!-- end product image -->
			
			<!-- product details div -->
			<div class="mobile:w-full sm:w-1/3 lg:w-3/5 flex-shrink-0">
				<div class="w-full md:h-75 mb-3 rounded-xl">
					<h1 class="text-black" 
					:class="{'line-through text-2xl font-medium': product.sale, 'text-4xl font-bold ' : !product.sale}"
					>${{product.price}}</h1>
					<h1 v-if="product.sale" class="text-3xl font-bold text-green-500">${{product.sale}}</h1>
					<h1 class="capitalize font-semibold mobile:text-xl md:text-1xl text-yellow-400 mb-3 relative">
						<i class="fa-star" :class="{'fas' : product.stars > 0 , 'far' : product.stars < 1}"></i>
						<i class="fa-star" :class="{'fas' : product.stars > 1 , 'far' : product.stars < 2}"></i>
						<i class="fa-star" :class="{'fas' : product.stars > 2 , 'far' : product.stars < 3}"></i>
						<i class="fa-star" :class="{'fas' : product.stars > 3 , 'far' : product.stars < 4}"></i>
						<i class="fa-star" :class="{'fas' : product.stars > 4 , 'far' : product.stars < 5}"></i>
						<!-- <a href="#reviews">
							<span class="text-flush-orange font-light text-lg underline ml-2">4 reviews</span>
						</a> -->
					</h1>

					<!-- qty dropdown -->
					<div class="relative h-10 md:h-12 w-24 rounded-xl border border-flush-orange" @click.stop="setDropdown('qtyDropdown')">
						<span class="block text-flush-orange text-center text-lg md:text-xl h-full leading-loose">
							<span class="mt-1 inline-block mr-2" style="line-height: 2.2">{{qty}}</span> 
							<i class="arrow border border-flush-orange" :class="{'down' : !dropdown, 'up' : dropdown }"></i>
						</span>
						<div class="w-full bg-white rounded-xl absolute dropdown shadow-sm border overflow-hidden z-20" 
						:class="{ show: dropdown == 'qtyDropdown' }">
							<ul class="max-h-25 overflow-scroll overflow-x-hidden text-left">
								<li v-for="(qtyOption, index) in quantities" class="py-1 px-2 hover:bg-gray-300 capitalize font-medium text-black">
									<span class="block text-base pt-2 h-10" @click.stop="selectQty(qtyOption)">{{qtyOption}}</span>
								</li>						
							</ul>
						</div>
					</div>
					<!-- end qty dropdown -->
					
					<!-- add to cart -->
					<button class="w-40 h-12 md:w-56 md:h-16 rounded-xl font-medium uppercase pt-2 outline-none text-xl md:text-2xl gradient-button btn-bg-gradient-flush-orange mt-8 mb-3 md:mb-0 md:mt-20" @click.stop="addToCart()">add to cart</button>
					<!-- end back to cart -->

				</div>
			</div>
			<!-- end product details -->

		</div>	
	</div>

	

		

	<!-- product description -->

	<div class="w-full mt-6 rounded-xl">
		<h1 class="capitalize font-semibold mobile:text-xl md:text-2xl text-black my-3">description</h1>
		<div class="w-full">
			<!-- <pre> -->
				<p class="text-flush-orange text-lg md:text-xl max-h-25 overflow-y-scroll md:leading-snug">{{product.description}}
				</p>
			<!-- </pre> -->
		</div>	

	</div>

	<!-- end product description -->	

	<!-- reviews -->
	
	<!-- <a name="reviews">
	<div class="mt-12 mb-20 rounded-xl relative">
		<h1 class="capitalize font-semibold mobile:text-xl md:text-2xl text-black mt-3 relative">Reviews</h1>
		<h1 class="capitalize font-semibold mobile:text-xl md:text-1xl text-yellow-400 mb-3 relative">
			<i class="fa-star" :class="{'fas' : product.stars > 0 , 'far' : product.stars < 1}"></i>
			<i class="fa-star" :class="{'fas' : product.stars > 1 , 'far' : product.stars < 2}"></i>
			<i class="fa-star" :class="{'fas' : product.stars > 2 , 'far' : product.stars < 3}"></i>
			<i class="fa-star" :class="{'fas' : product.stars > 3 , 'far' : product.stars < 4}"></i>
			<i class="fa-star" :class="{'fas' : product.stars > 4 , 'far' : product.stars < 5}"></i>
			<span class="text-flush-orange font-light text-lg underline ml-2">4 reviews</span>
		</h1> -->
		
		<!-- review -->
		<!-- <div class="w-full mt-5"> -->
			<!-- <div class="rounded-xl border border-yellow-orange h-75 p-5"> -->
				<!-- review header -->
		<!-- 		<div class="h-20 flex justify-between">
					<div class="items-center flex">
						<div class="h-20 w-20 rounded-full border border-flush-orange inline-block"></div>
						<div class="text-black ml-4 leading-snug">
							<span class="block text-1xl font-semibold capitalize">chaim</span>
							<span class="block text-lg -mt-1 capitalize">brooklyn, NY</span>
						</div>
					</div>
					<h1 class="capitalize font-semibold mobile:text-xl md:text-1xl text-yellow-400 relative pt-3">
						<i class="fas fa-star"></i>
						<i class="fas fa-star"></i>
						<i class="fas fa-star"></i>
						<i class="fas fa-star"></i>
						<i class="far fa-star"></i>
					</h1>
				</div> -->
				<!-- end review header-->
				<!-- <div class="h-48 py-4 w-full">
					<p class="text-black text-md md:text-lg max-h-full overflow-y-scroll md:leading-snug">
					</p>
				</div>
			</div>				
		</div> -->
		<!-- end review -->
<!-- 	</div>
	</a> -->

	<!-- end reviews -->

	</div>
</template>
<script>
	
	import character from '../components/Character.vue'
	import StoreHeader from '../components/StoreHeader.vue'

	export default {
		data() {
			return {
				icon: 'default.png',
				url: '/assets/images/products/',
				default_image: 'default-product.png',
				dropdown: false,
				qty: 1,
			}
		},
		computed: {

			appName: function () {
				return this.$store.getters.getApp.name;
			},	

			logo: function () {
				return this.$store.getters.getLogos.main_logo;
			},
			
			translations() {
				return this.$store.getters.getTranslations
			},

			user() {
				return this.$store.getters.getUser
			},

			product() {
				return this.$store.getters.getProduct
			},

			quantities() {
				var qty = [1,2,3,4,5];

				return qty.filter(function(qty){
					if(qty != this.qty){
						return qty
					}
				},{qty: this.qty})

			}		

		},

		methods: {

			//check if image is broken
	  		imageLoadError: function () {
	  			event.target.src = this.url+this.default_image;
	  		},

			route: function (route) {
				this.$router.push({name:route})
			},			

			category: function (category) {
				this.$router.push({name:'category', params: {category:category}})
			},

			// toggle dropdown
			setDropdown: function (dropdown) {
				if(this.dropdown == dropdown){
					return this.dropdownOff()
				}
				this.dropdown = dropdown
			},

			// toggle off
			dropdownOff: function () {
				this.dropdown = false
			},

			selectQty: function (qty) {
				this.qty = qty
				this.dropdownOff()
			},

			// add to cart 
			addToCart: function () {
				var item = this.product
				item.qty = this.qty
				this.$store.dispatch('addToCart', item).then(response => {
					this.$store.dispatch('setStorePopup', {
						header:'Added to cart',
						data:{item:item},
						model:'added_to_cart',
						type:'action',
						without_buttons:true,
						cart_buttons:true,
						error:false,
						emoji:'wink',
					})
					return this.$store.dispatch('setStorePopupStatus', 1);
		        })		
			}

		},

		components: {
			character,
			StoreHeader,
		},	

		filters: {

		},

		beforeMount() {
      		this.$store.dispatch( 'getAppData' );
      		this.$store.dispatch( 'getTranslations' );
      		this.$store.dispatch( 'getProduct', parseInt(this.$route.params.id) );
		},

		mounted() {
			// setTimeout( () => {
			//   	this.$store.dispatch( 'setCharachterStatus', 1)
			//   	var msg = (this.translations.msgs.token_register)
			// 	this.$store.dispatch( 'setCharachter', {class:'show',message:msg})
			// },300);	
		},

	}
</script>