import LinesAPI from '../api/lines.js';

export const lines = {

    state: {

       lines: {},
       lines_page: {till_end_of_perek:null,chazered:[],not_chazered:[]},
       selected_date_lines: {date:'',amount:0},
       total_lines: 0,
       lines_loading: 0,

    },

    getters: {

    	getLines(state) {
            return state.lines;
        },

        getLinesPage(state) {
            return state.lines_page;
        },

        getSelectedDateLines(state) {
            return state.selected_date_lines;
        },

        getTotalLines(state) {
            return state.total_lines;
        },

        getLinesLoadingStatus(state) {
            return state.lines_loading;
        },

    },

    actions: {

        //submit line entry
        submitLineEntry( { commit }, data){

            commit( 'setLinesLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                LinesAPI.submitLineEntry(data)
                .then( function( response ){
                    commit( 'setLines', response.data );
                    commit( 'setLinesLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setLinesLoadStatus', 3 );
                    reject(error.response.data)
                });

            })


        },


        //get line data
        getLinePage( { commit }, data){

            commit( 'setLinesLoadStatus', 1 );

            return new Promise((resolve, reject) => {

                LinesAPI.getLinePage(data)
                .then( function( response ){
                    commit( 'setTotalLines', response.data.total_lines );
                    commit( 'updateLinesTotal', response.data.total_lines );
                    commit( 'setSelectedDateLines', response.data.selected_date_lines );
                    commit( 'setLinePage', response.data.page );
                    commit( 'setLinesLoadStatus', 2 );
                    resolve(response.data)
                })
                .catch( function(error){
                    commit( 'setLinesLoadStatus', 3 );
                    reject(error.response)
                });

            })


        },


    },

    mutations: {

	    setLines( state, lines ){
            state.lines = lines;
	    },

        setTotalLines( state, total ){
            state.total_lines = total;
        },

        setSelectedDateLines( state, data ){
            state.selected_date_lines = data;
        },

        setLinePage( state, page ){
            state.lines_page = page;
        },

	    setLinesLoadStatus( state, status ){
      		state.lines_loading = status;
	    },


    },
}
