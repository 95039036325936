import MesechtosApi from '../api/mesechtos.js';

export const mesechta = {

    state: {
        mesechta: {
        	prokim: {}, 
        },
    	mesechta_loading: 0,
    },

    getters: {

    	getMesechta(state) {
            return state.mesechta;
        },    	

        getMesechtaLoadingStatus(state) {
            return state.mesechta_loading;
        },        

    },

    actions: {   

        //get mesechta with prokim
        getMesechta({commit, dispatch}, data) {

             commit( 'setMesechtaLoadStatus', 1 );
             
             return new Promise((resolve, reject) => {

	             MesechtosApi.getMesechta( data )
	             .then( function( response ){
	                 commit( 'setMesechta', response.data )
	                 commit( 'setMesechtaLoadStatus', 2 );
	                 resolve(response.data)

	             })
	             .catch( function(error){
	                 commit( 'setMesechtaLoadStatus', 3 );
	                 reject(error.response.data)
	             }); 
	                         	
             })


        }, 

        resetMesechta({commit}) {
             commit( 'resetMesechta' );
        },       

    },

    mutations: {

	    setMesechtaLoadStatus( state, status ){
      		state.mesechta_loading = status;
	    },	    

	    setMesechta( state, mesechta ){
      		state.mesechta = mesechta;
	    },		    

	    resetMesechta( state, mesechta ){
      		state.mesechta = {prokim: {}};
	    },	    	        


    },

}