<template>
  <div id="app-layout">
        <router-view></router-view>
  </div>
</template>

<script>
  export default {

  }
</script>