/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	   

    //get list of mishnayos in perek
    getPerek: function(data){
        return axios.get(APP_CONFIG.API_URL+`/perek/${data}`); 
    },      

}