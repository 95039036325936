<template>
	<div class="mobile:mt-20 tablet:mt-20 laptop:pl-23r my-10 px-5 md:px-10" style="min-height: calc(100vh - 5rem);">

	<store-header></store-header>
			
	<!-- store banner -->

	<div class="w-full mt-10 md:mt-2 text-center mb-3 rounded-xl overflow-hidden">
          <img src="/assets/images/banners/store-banner.png" class="w-full image inline-block">
	</div>

	<!-- end store banner -->	

	<!-- shop by category -->

	<div class="w-full md:h-75 mt-6 rounded-xl">
		<h1 class="capitalize font-semibold mobile:text-xl md:text-2xl text-black my-3">Categories</h1>
		<div class="w-full flex flex-col md:flex-row">
			<div class="h-64 bg-gray-100 rounded-xl w-full md:w-1/2 mb-3 md:mb-0 md:mr-3 text-center"
			v-for="(category, index) in categories">
				<img @click="routeCategory(category.name)" :src="'/assets/images/thumbs/'+category.name+'.png'"  class="image inline-block" alt="">
			</div>
			<!-- <div class="h-64 bg-gray-100 rounded-xl w-full md:w-1/2 mt-3 md:mt-0 md:ml-3 text-center"
			@click="category('toys')">
				<img src="/assets/images/thumbs/toys.png"  class="image inline-block" alt="">
			</div> -->
		</div>	

	</div>

	<!-- end shop by category -->	

	<!-- shop by trending -->

	<div class="h-64 md:h-75 lg:h-21r mt-6 mb-20 rounded-xl relative">
		<h1 class="capitalize font-semibold mobile:text-xl md:text-2xl text-black my-3 relative">Trending</h1>
		<div class="w-full absolute">
			<div class="w-full product-carousel cursor">
				<div v-for="(product, index) in trending.data" class="h-64 md:h-75 lg:h-21r border border-flush-orange overflow-hidden rounded-xl mobile:w-10/12 sm:w-64 mr-2 md:mx-0 md:mr-3 inline-block product" @click.stop="getProduct(product.id)">
					<div class="h-40 md:h-48 lg:h-56 w-full bg-white p-4 text-center justify-center items-center flex">
						<img :src="url+product.image" @error="imageLoadError" class="flex-shrink-0 image h-32 w-32 md:h-40 md:w-40 lg:h-48 lg:w-48 inline-block rounded-xl" alt="" style="object-fit: cover;">
					</div>

					<div class="w-full h-20 md:h-24 lg:h-32 px-3 flex flex-col justify-center">
						<h1 class="w-full text-ellipsis text-xl text-flush-orange font-semibold">{{product.title}}</h1>
						<div class="flex justify-between">
							<h1 class="flex-shrink-0 capitalize font-semibold mobile:text-xl md:text-1xl text-yellow-400 relative pt-3 inline-block">
								<i class="fa-star" :class="{'fas' : product.stars > 0 , 'far' : product.stars < 1}"></i>
								<i class="fa-star" :class="{'fas' : product.stars > 1 , 'far' : product.stars < 2}"></i>
								<i class="fa-star" :class="{'fas' : product.stars > 2 , 'far' : product.stars < 3}"></i>
								<i class="fa-star" :class="{'fas' : product.stars > 3 , 'far' : product.stars < 4}"></i>
								<i class="fa-star" :class="{'fas' : product.stars > 4 , 'far' : product.stars < 5}"></i>
							</h1>
							<div class="w-32 text-right">
							<h1 v-if="product.sale" class="h-full text-right text-ellipsis text-2xl text-green-600 font-bold inline-block">${{product.sale}}</h1>
							<h1 :class="{'mx-2 line-through text-1xl font-medium': product.sale, 'text-2xl font-bold ' : !product.sale}" 
							class="mx-2 h-full text-right text-ellipsis text-flush-orange inline-block">${{product.price}}</h1>	
							</div>
						</div>
					</div>
				</div>
			</div>				
		</div>
	</div>

	<!-- end shop by category -->

	</div>
</template>
<script>
	
	import character from '../components/Character.vue'
	import StoreHeader from '../components/StoreHeader.vue';

	export default {
		data() {
			return {
		
				icon: 'default.png',
				url: '/assets/images/products/',
				default_image: 'default-product.png',
			}
		},
		computed: {

			logo: function () {
				return this.$store.getters.getLogos.main_logo;
			},
			
			translations() {
				return this.$store.getters.getTranslations
			},

			user() {
				return this.$store.getters.getUser
			},

			trending() {
				return this.$store.getters.getTrending
			},			

			categories() {
				return this.$store.getters.getStoreCategories
			}			

		},

		methods: {
			
			//check if image is broken
	  		imageLoadError: function () {
	  			event.target.src = this.url+this.default_image;
	  		},

			route: function (route) {
				this.$router.push({name:route})
			},			

			routeCategory: function (category) {
				this.$router.push({name:'category', params: {category:category}})
			},
	        
	        // select product 
	        getProduct: function (id) {
	        	this.$router.push({name:'product', params:{id:id}})
	        },

		},

		components: {
			character,
			StoreHeader,
		},	

		filters: {

		},

		beforeMount() {
			this.$store.dispatch('resetProducts')
      		this.$store.dispatch( 'getTranslations' );
      		this.$store.dispatch( 'getStoreCategories' );
      		this.$store.dispatch( 'getTrending' );
		},

		mounted() {
			// setTimeout( () => {
			//   	this.$store.dispatch( 'setCharachterStatus', 1)
			//   	var msg = (this.translations.msgs.token_register)
			// 	this.$store.dispatch( 'setCharachter', {class:'show',message:msg})
			// },300);	
		},

	}
</script>