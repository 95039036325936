<style>
  .call-to-action{
    font-family: "Montserrat", arial, sans-serif;
    font-size: 22px;
    color: #fff;
    max-width: 350px;
    width: 80%;
    background: #2acbbe;
    height: 70px;
    border-radius: 7px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    display: inline-block;
    padding: 15px;
  }
  .section-sub-title{
    text-align: center;
    display: block;
    margin: 0;
    
  }
  .heading-text .section-sub-title{
    width: 100%;
    padding: 0 20%;
    margin-bottom: 60px;
  }
</style>
<template>
  <div class="">
    <loader></loader>
		<landing-navbar></landing-navbar>
    <live-updates></live-updates>
      <section class="landing">
      
      <div class="landing-hero">
        <div class="hero-image">
          <img src="assets/images/banners/hero-2.png">
        </div>
      </div>

      <div class="landing-cards">

        <div class="wrapper" style="justify-content: center;">

          <div class="heading-text" style="text-align: center;display: block;">
          <h1 class="section-sub-title">{{translations.msgs.landing_page_text}}</h1>      
            <!-- <div class="call-to-action" style="display: inline-block;"> -->
              <button class="form-submit-button call-to-action" @click.prevent="link_to_register">
                {{translations.msgs.landing_page_call_to_action}}
              </button> 
            <!-- </div>    -->
          </div>
          <div class="landing-card">
            <figure class="icon-image">
              <img :src="'assets/images/svg/learn-landing.svg'">
            </figure>
            <h2>Learn</h2>
          </div>          
          <div class="landing-card">
            <figure class="icon-image">
              <img :src="'assets/images/svg/earn-landing.svg'">
            </figure>
            <h2>Earn</h2>
          </div>
          <div class="landing-card">
            <figure class="icon-image">
              <img :src="'assets/images/svg/rewards-landing.svg'">
            </figure>
            <h2>Redeem</h2>
          </div>
        </div>
      </div>

      <div class="wrapper">
        <h1 class="section-title counter-title">ילדי השלוחים Global Counter
          <span class="line-top"></span>
        </h1>
      </div>
      <div class="counter-banner">
        <div class="wrapper">
          <div class="count left-count">
            <h3>Minutes Learned בעל פה</h3>
            <p>{{formatedMinutes}}</p>
          </div>          
          <div class="count right-count">
            <h3>Lines Learned בעל פה</h3>
            <p>{{formatedLines}}</p>
          </div>
        </div>
      </div>      

      <div class="landing-contact">
        <div class="wrapper">
          <h1 class="section-title">Contact Us<span class="line-bottom"></span></h1>
          
          <form>
            <transition name="fade">
              <span v-if="success" class="success-message">{{successMessage}}</span>
              <span v-if="error" class="error-message">{{errorMessage}}</span>
            </transition>
            <div class="form-input" :class="{error : errorInput && !name}">
              <input type="text" class="form-input-field" v-model="name" placeholder="Name *">
            </div>   
            <div class="form-input" :class="{error : errorInput && !email}">
              <input type="text" class="form-input-field" v-model="email" placeholder="Email *">
            </div>   
            <div class="form-input">
              <input type="text" class="form-input-field" v-model="phone" placeholder="Phone *">
            </div>              
            <div class="form-input" :class="{error : errorInput && !message}">
              <textarea class="form-input-field textarea" v-model="message" placeholder="Type message here.. *"></textarea>
            </div>
            <div class="form-input">
              <button class="form-submit-button" @click.prevent="submit" :disabled="loading">
                {{loading ? '' : 'Submit'}}
                <img v-if="loading" :src="'assets/images/svg/loader.svg'" style="width: 40px">
              </button> 
            </div>            
          </form>
        </div>
      </div>      

      </section>


      <landing-footer></landing-footer>

  </div>
</template>

<script>
  
  import LandingFooter from '../components/LandingFooter.vue'
  import LandingNavbar from '../components/LandingNavbar.vue'
  import LiveUpdates from '../components/LiveUpdates.vue'
  import Loader from '../components/Loader.vue';

  export default {

  	data() {
  		return {
	        lines: 0,
	        minutes: 0,
	        name: '',
	        email: '',
	        phone: '',
	        message: '',
	        loading: false,
	        success: false,
	        error: false,
	        errorInput: false,
	        successMessage: '',
  		}
  	},
  	computed: {

  		translations() {
  			return this.$store.getters.getTranslations
  		},      

      settings() {
        return this.$store.getters.getSettings
      },

      formatedLines: function () {
        var n = this.$store.getters.getLanding.lines
        return n.toLocaleString()
      },      

      formatedMinutes: function () {
        var n = this.$store.getters.getLanding.time
        return n.toLocaleString()
      },

  	},
	methods: {
		stats: function () {
		  
		},

		register: function () {
			this.$router.push({ name : 'pre-register'});
		},

		submit: function () {
      if(!this.email || this.email == '' || !this.phone || this.phone == '' || !this.name || this.name == '' || !this.message || this.message == ''){return this.errorInput = true}
      this.$store.dispatch('submitContactUs', {name:this.name,email:this.email,phone:this.phone,message:this.message})
      .then(response => {
        this.success = true
        this.successMessage = response.message
        this.resetForm()
      }).catch(error => {
        this.error = true
        this.errorMessage = error.message
        this.resetForm()
      })
		},

    resetForm: function () {
      this.name = ''
      this.email = ''
      this.phone = ''
      this.message = ''
    },

    link_to_register: function () {
      // return this.$router.push({name:})
      window.open('/parents/register', '_blank')
    }
	},
  beforeMount() {
    this.$store.dispatch('getLandingData');
    this.$store.dispatch('getSettings');
  },
	components: {
	    LandingFooter,
	    LandingNavbar,
      LiveUpdates,
      Loader,
    },

}
</script>