<template>
	<div class="char-container pointer w-4/5 sm:w-3/5 md:w-1/5  mt-10"
	:class="{'show' : charachter.class && charachter.message, 'focus' : hover}">
		<div class="speech-bubble bg-coral relative">
			<p class="p-4">{{charachter.message}}</p>
		</div> 
		<div class="image-svg h-full bottom-0 flip-horizontal" style="right: 0px; height: calc(100% + 50px);">
		<div v-if="charachter.gender != 'girl'" v-html="character()" class="mt-5 flex image-svg h-full bottom-0" style="right: 0; height: calc(100% + 50px);"></div>
		</div>		
		<div v-if="charachter.gender == 'girl'" v-html="girl_character()" class="mt-5 flex image-svg h-full bottom-0" style="right: 0; height: calc(100% + 50px);"></div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				hover: false,
			}
		},
		computed: {
			charachter() {
				return this.$store.getters.getCharachter
			},			
			charachterStatus() {
				return this.$store.getters.getCharachterStatus
			},
		},
		methods: {
			character: function () {
				return require('../../svg/character/char2.svg')
			},			
			girl_character: function () {
				return require('../../svg/character/girl_char_2.svg')
			},
		},
	}
</script>