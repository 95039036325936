/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	   

    //get time page data
    getTimePage: function(data){
        return axios.get(APP_CONFIG.API_URL+'/time?date='+data); 
    },         

    //submit times entry
    submitTimeEntry: function(data){
        return axios.post(APP_CONFIG.API_URL+'/increment/time', data); 
    },         

    //deduct times entry
    deductTimeEntry: function(data){
        return axios.post(APP_CONFIG.API_URL+'/decrement/time', data); 
    },        

}
