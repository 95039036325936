/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {


 	//lookup registeration user
 	registrationLookup: function(data){
        return axios.get(APP_CONFIG.API_URL+`/register?token=${data.token}&family=${data.family}`); 
    },      

    //register user
    completeRegistration: function(data){

        const user = new FormData();
        user.append('username', data.username)
        user.append('password', data.password)
        user.append('file', data.file)
        user.append('token', data.token)
        user.append('family', data.family)

        return axios.post(APP_CONFIG.API_URL+'/register', user); 
    }, 	    

    //user login api call
    userLogin: function(data){
        return axios.post(APP_CONFIG.API_URL+'/login', data); 
    },  

    //user logout api call
    userLogout: function(){
        return axios.post(APP_CONFIG.API_URL+'/logout'); 
    },    

    //user logout api call
    getUserData: function(){
        return axios.get(APP_CONFIG.API_URL+'/user'); 
    },
    
    //get shipping address
    getShippingAddress: function(){
        return axios.get(APP_CONFIG.API_URL+'/shipping'); 
    },     

    //set mbpd tag
    setMbpdTag: function(data){
        return axios.post(APP_CONFIG.API_URL+'/mbpd_tag', {_method:'patch',mbpd_tag:data}); 
    },      
}