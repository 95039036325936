/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {

 	//submit transfer
 	transfer: function(data){
        return axios.post(APP_CONFIG.API_URL+`/transfer`, data); 
    }, 	 	

    //get transfers paginated
 	getTransfers: function(data){
        return axios.get(APP_CONFIG.API_URL+`/transfers?PerPage=${data.PerPage}&&page=${data.Page}`); 
    }, 	 	             	
 		             	

}