import LiveApi from '../api/live.js';

export const live_banner = {

    state: {
        live_banner: {},
    	live_banner_loading: 0,
    },

    getters: {

    	getLiveBannerTransactions(state) {
            return state.live_banner;
        },    	

        getLiveBannerTransactionsLoadingStatus(state) {
            return state.live_banner_loading;
        },        

    },

    actions: {   

        //get live_banner with prokim
        getLiveBannerTransactions({commit, dispatch}, data) {

             commit( 'setLiveBannerLoadStatus', 1 );
             
             return new Promise((resolve, reject) => {

	             LiveApi.getLiveBanner( data )
	             .then( function( response ){
	                 commit( 'setLiveBanner', response.data )
	                 commit( 'setLiveBannerLoadStatus', 2 );
	                 resolve(response.data)

	             })
	             .catch( function(error){
	                 commit( 'setLiveBannerLoadStatus', 3 );
	                 reject(error.response.data)
	             }); 
	                         	
             })

        }, 


    },

    mutations: {

	    setLiveBannerLoadStatus( state, status ){
      		state.live_banner_loading = status;
	    },	    

	    setLiveBanner( state, live_banner ){
      		state.live_banner = live_banner;
	    },		        	        


    },

}