/*
    Imports the API URL from the config.
*/
import { APP_CONFIG } from '../config.js';
import axios from 'axios'
import store from '../store'

export default {	   

    //get list of prokim in tanya
    getTanyaProkim: function(){
        return axios.get(APP_CONFIG.API_URL+'/tanya'); 
    },    

    //get list of lines in perek
    getPerekTanya: function(data){
        return axios.get(APP_CONFIG.API_URL+`/tanya/${data}`); 
    },      

}
